import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import {
  deleteLecture,
  getLectureList,
  patchLecture,
  reDurLecture,
  reThumLecture,
} from 'api/admin/lecture';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import { RootState, useAppSelector } from '../../../module/Module';

type LectureList = {
  id: number;
  major: number;
  title: string;
  username: string;
  createdAt: Array<number>;
  studentCount: number;
  commentCount: number;
  permissionStatus: string;
  isPublic: boolean;
  videoId: number;
};

type ClassCategory = {
  id: number;
  name: string;
  category: string;
};

const AdminLectureListPage = () => {
  const getParams = new URLSearchParams(window.location.search);
  const [lists, setList] = useState<Array<LectureList>>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [status, setStatus] = useState<string>(String(getParams.get('status')));
  const [keyword, setKeyword] = useState<string>('');
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [detectChange, setDetectChange] = useState<boolean>(true);
  const category: Array<ClassCategory> = useAppSelector(
    (state: RootState) => state.classCategory
  );

  const getList = (page: any) => {
    const params = {
      categoryId: categoryId,
      status: status,
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
    };
    getLectureList(params).then((res: any) => {
      setList(res.data.content);
      setTotalPage(res.data.totalPages);
      setTotalElements(res.data.totalElements);
      setCurrentPage(page.selected);
    });
  };

  useEffect(() => {
    getList({ selected: 0 });
  }, [detectChange, categoryId, status]);

  const updatePublic = (id: number) => {
    patchLecture(id).then((res: any) => {
      alert('수정되었습니다.');
      getList({ selected: currentPage });
    });
  };

  const reInfoCourse = (id: number) => {
    reThumLecture(id).then((res: any) => {});
    reDurLecture(id).then((res: any) => {});
    alert('새로고침 되었습니다.');
  };

  const deleteCourse = (id: number) => {
    deleteLecture(id).then((res: any) => {
      alert('삭제되었습니다.');
      getList({ selected: currentPage });
    });
  };

  const handleOnKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange);
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="lecture_list">
          <CCardHeader>
            <h1 className="mb-0 pt-2 pb-2 fs-5">강의 관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-between align-items-end">
                <div>
                  <span>
                    총 <em>{totalElements}</em>개
                  </span>
                </div>
                <div className="d-flex justify-content-end w-75">
                  <CInputGroup className="w-50 me-3">
                    <CInputGroupText id="basic-addon1">
                      현황 요청
                    </CInputGroupText>
                    <CFormSelect
                      placeholder="과/교실을 선택"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>선택</option>
                      <option value={'WAIT'}>대기중</option>
                      <option value={'ACCEPTED'}>승인</option>
                      <option value={'DENIED'}>거절</option>
                      <option value={'RETRY'}>재요청</option>
                    </CFormSelect>
                  </CInputGroup>
                  <CInputGroup className="w-50 me-3">
                    <CInputGroupText id="basic-addon1">과/교실</CInputGroupText>
                    <CFormSelect
                      placeholder="과/교실을 선택"
                      onChange={(e) => setCategoryId(e.target.value)}
                    >
                      <option value="">선택</option>
                      {category.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CFormSelect>
                  </CInputGroup>

                  <CInputGroup className="w-100">
                    <CFormInput
                      type="text"
                      placeholder="제목 검색"
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyPress={handleOnKeyPress}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      onClick={() => setDetectChange(!detectChange)}
                    >
                      검색
                    </CButton>
                  </CInputGroup>
                </div>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{ width: '5%' }} scope="col">
                    번호
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '7%' }} scope="col">
                    과/교실
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '18%' }} scope="col">
                    강의제목
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '8%' }} scope="col">
                    교수
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '12%' }} scope="col">
                    강의 업로드 일자
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '12%' }} scope="col">
                    수강현황
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '5%' }} scope="col">
                    댓글 수
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '10%' }} scope="col">
                    현황요청
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '23%' }} scope="col">
                    관리
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? (
                  lists.map((item) => (
                    <CTableRow
                      key={item.id}
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                    >
                      <CTableDataCell>{item.id}</CTableDataCell>
                      <CTableDataCell>{item.major}</CTableDataCell>
                      <CTableDataCell>
                        <a
                          href={`/lecture/watch/${item.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      </CTableDataCell>
                      <CTableDataCell>{item.username}</CTableDataCell>
                      <CTableDataCell>
                        {item.createdAt[0]}.{item.createdAt[1]}.
                        {item.createdAt[2]}
                      </CTableDataCell>
                      <CTableDataCell>
                        {item.studentCount}
                        <Link
                          to={`/admin/lecture/status/${item.id}?title=${item.title}&category=${item.major}&permission=${item.permissionStatus}`}
                          className="text-white ms-3 btn btn-info"
                        >
                          보기
                        </Link>
                      </CTableDataCell>
                      <CTableDataCell>{item.commentCount}</CTableDataCell>
                      <CTableDataCell>{item.permissionStatus}</CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          className="me-3"
                          color="light"
                          onClick={() => reInfoCourse(item.videoId)}
                        >
                          새로고침
                        </CButton>
                        <Link
                          to={`/lecture/management/edit/${item.id}`}
                          className="btn btn-outline-info me-3"
                        >
                          수정
                        </Link>
                        <CButton
                          color={item.isPublic ? 'success' : 'warning'}
                          className="text-white me-3"
                          onClick={() => updatePublic(item.id)}
                        >
                          {item.isPublic ? '공개' : '비공개'}
                        </CButton>
                        <CButton
                          color="danger"
                          className="text-white"
                          onClick={() => deleteCourse(item.id)}
                        >
                          삭제
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow style={{ borderBottom: '1px solid #d8d8d8' }}>
                    <CTableDataCell colSpan={9}>
                      등록된 강의 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>

            <ReactPaginate
              breakLabel="..."
              containerClassName="user-paginate mt-4"
              nextLabel=">"
              onPageChange={getList}
              pageCount={totalPage}
              pageClassName="user-paginate-item"
              previousLabel="<"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminLectureListPage;
