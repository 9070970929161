import 'views/admin/admin.sass';

import { CContainer } from '@coreui/react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from 'views/admin/layout/AdminHeader';
import AdminNavigation from 'views/admin/layout/AdminNavigation';

const AdminPage = () => {
  const [show, setShow] = useState<boolean>(true);

  const setShowParent = (x: any) => {
    setShow(x);
  };

  return (
    <div className="admin">
      <AdminNavigation show={show} />
      <div className="wrapper d-flex flex-column min-vh-100">
        <AdminHeader setShowParent={setShowParent} />
        <div className="body flex-grow-1 px-3">
          <CContainer fluid className="container-fluid">
            <Outlet></Outlet>
          </CContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
