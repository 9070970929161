import 'views/mobile/lecture/style/m-subscribe-playlist.sass';

import { getSubscribePlayListData } from 'api/playlist/playlist';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { getSubPlaylist } from 'module/sub-playlist';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { CommonPlaylistProps } from 'utils/type';
import MCommonPlaylist from 'views/mobile/common/playlist/MCommonPlaylist';
import MPlayListDetailPopup from 'views/mobile/common/popup/MPlayListDetailPopup';

const MLectureSubscribePlayListPage = () => {
  const dispatch = useAppDispatch();

  const [keyword, setKeyword] = useState<string>('');

  const [totalPage, setTotalPage] = useState<number>(1);

  const [isButtonClick, setIsButtonClick] = useState<boolean>(false);

  const subPlaylist: Array<CommonPlaylistProps> = useAppSelector(
    (state: RootState) => state.subPlaylist.subPlaylist
  );
  const showDetailPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddDetailPopup.showDetailPopup
  );
  const subChange: boolean = useAppSelector(
    (state: RootState) => state.subPlaylist.subChange
  );

  const getList = (page: any) => {
    const params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
      sort: 'date',
    };
    getSubscribePlayListData(params).then((res: any) => {
      dispatch(getSubPlaylist(res.data.content));
      setTotalPage(res.data.totalPages);
    });
  };

  useEffect(() => {
    dispatch(showDetailPopupF({ showDetailPopup: false, playlistId: null }));
    getList({ selected: 0 });
  }, [isButtonClick, subChange]);

  return (
    <div className="m-lecture-subscribe-playlist">
      <div className="search-wrap">
        <h2>구독 플레이리스트 검색</h2>
        <div className="input-wrap">
          <input
            type="text"
            placeholder="검색어를 입력해 주세요."
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button
            className="ir_pm"
            onClick={() => {
              setIsButtonClick(!isButtonClick);
            }}
          >
            검색
          </button>
        </div>
      </div>
      <div className="m-container">
        <div className="content-wrap">
          <div className="handler-wrap">
            <h2>구독 플레이리스트</h2>
            <div className="button-wrap">
              <Link to="/playlist/public" className="btn-add">
                추가
              </Link>
            </div>
          </div>
          <MCommonPlaylist playlists={subPlaylist} />
          {totalPage >= 1 && (
            <ReactPaginate
              breakLabel="..."
              containerClassName="m-user-paginate"
              nextLabel="다음"
              onPageChange={getList}
              pageCount={totalPage}
              pageClassName="user-paginate-item"
              previousLabel="이전"
            />
          )}
        </div>
      </div>
      {showDetailPopup && <MPlayListDetailPopup />}
    </div>
  );
};

export default MLectureSubscribePlayListPage;
