import 'views/mobile/lecture/style/m-watch-information.scoped.sass';

import images from 'assets/images/global/icon_file.svg';
import { useRef } from 'react';

type files = {
  id: number;
  name: string;
  url: string;
};

type WatchInformationDetail = {
  description: string;
  courseFiles: Array<files>;
};

interface ListProps {
  data: WatchInformationDetail | undefined;
}
const MWatchInformation = ({ data }: ListProps) => {
  const downloadRef = useRef<Array<HTMLAnchorElement | null>>([]);
  const downloadAll = () => {
    const zip = require('jszip')();
    const FileSaver = require('file-saver');
    const files = data?.courseFiles ?? [];

    files.forEach((x) => {
      zip.file(x.name, x.url);
    });

    zip.generateAsync({ type: 'blob' }).then((content: any) => {
      FileSaver.saveAs(content, 'example.zip');
    });
  };

  return (
    <>
      <article className="m-watch-description-wrap">
        <div className="title-wrap">
          <h2>강의소개</h2>
        </div>
        <div className="content-wrap">
          <pre>{data?.description}</pre>
        </div>
      </article>
      <article className="m-watch-attachment-wrap">
        <div className="title-wrap">
          <h2>
            첨부파일 <span>({data?.courseFiles?.length})</span>
          </h2>
          {Number(data?.courseFiles?.length) > 0 && (
            <a
              href="/"
              className="btn-download-all"
              onClick={(e) => {
                e.preventDefault();
                downloadAll();
              }}
              target="_blank"
            >
              <img src={images} alt="다운로드" />
              전체 다운로드
            </a>
          )}
        </div>
        <div className="content-wrap">
          {data?.courseFiles &&
            data?.courseFiles.map((file, index) => (
              <a
                href={`${file.url}`}
                download
                key={file.id}
                ref={(itself) => (downloadRef.current[index] = itself)}
                className="btn-download"
                target="_blank"
                rel="noreferrer"
              >
                <img src={images} alt="다운로드" />
                {file.name}
              </a>
            ))}
        </div>
      </article>
    </>
  );
};

export default MWatchInformation;
