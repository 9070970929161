import 'views/mobile/common/lecture/m-lecture-card.scoped.sass';

import { addLike, addWish, delLike, delWish } from 'api/lecture/lecture';
import { setCourseId, showPopupF } from 'module/lecture-add-popup';
import { ChangeLecture } from 'module/lecturelist';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import React from 'react';
import { Link } from 'react-router-dom';
import { LectureProps } from 'utils/type';

type LectureCardProps = {
  data: LectureProps;
};

const MLectureCard = ({ data }: LectureCardProps) => {
  const dispatch = useAppDispatch();
  const getParams = new URLSearchParams(window.location.search);
  const lectureChange: boolean = useAppSelector(
    (state: RootState) => state.getLectureList.lectureChange
  );
  const lectureSearchKeyword = getParams.get('keyword');

  // 찜
  const AddWish = (e: any, courseId: number) => {
    e.preventDefault();
    addWish(courseId).then((res: any) => {
      dispatch(ChangeLecture(!lectureChange));
    });
  };

  const DelWish = (e: any, courseId: number) => {
    e.preventDefault();
    delWish(courseId).then((res: any) => {
      dispatch(ChangeLecture(!lectureChange));
    });
  };

  // 좋아요
  const AddLike = (e: any, courseId: number) => {
    e.preventDefault();
    addLike(courseId).then((res: any) => {
      dispatch(ChangeLecture(!lectureChange));
    });
  };

  const DelLike = (e: any, courseId: number) => {
    e.preventDefault();
    delLike(courseId).then((res: any) => {
      dispatch(ChangeLecture(!lectureChange));
    });
  };

  const highlightedText = (text: string, keyword: string | null) => {
    if (keyword !== null) {
      if (keyword !== '' && text.includes(keyword)) {
        const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

        return (
          <>
            {parts.map((part, index) =>
              part.toLowerCase() === keyword.toLowerCase() ? (
                <span style={{ backgroundColor: '#fffeb3' }} key={index}>
                  {part}
                </span>
              ) : (
                <span key={index}>{part}</span>
              )
            )}
          </>
        );
      }
    }
    return text;
  };

  const highlightedTag = (text: string, keyword: string | null) => {
    if (keyword !== null) {
      if (keyword !== '' && text.includes(keyword)) {
        const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

        return (
          <>
            {parts.map((part, index) =>
              part.toLowerCase() === keyword.toLowerCase() ? (
                <b style={{ backgroundColor: '#fffeb3' }} key={index}>
                  #{part}
                </b>
              ) : (
                part
              )
            )}
          </>
        );
      }
    }
    return '#' + text;
  };

  return (
    <Link to={`/lecture/watch/${data.id}`}>
      <div className="m-lecture-card">
        <div className="card-main-wrap">
          <div className="img-wrap">
            {data.videoType === 'VIMEO' ? (
              <div className="img-wrap">
                <img
                  src={data.courseThumbnail.url}
                  className="lecture-image"
                  alt="강의 썸네일"
                />
              </div>
            ) : (
              <div className="img-wrap">
                <img
                  src={
                    data.courseThumbnail?.url
                      ? data.courseThumbnail.url
                      : `https://img.youtube.com/vi/${data.videoKey}/mqdefault.jpg`
                  }
                  className="lecture-image"
                  alt="강의 썸네일"
                />
              </div>
            )}
          </div>
          <div className="text-wrap">
            <span
              className="lecture-badge"
              style={{
                backgroundColor:
                  data?.category === '기초의학교실'
                    ? '#f8cbad'
                    : data?.category === '임상의학교실'
                      ? '#c6e0b4'
                      : data?.category === '인문사회의학교실'
                        ? '#d8c5ff'
                        : data?.category === '연세동곡의학교육원'
                          ? '#00B0F0'
                          : '',
              }}
            >
              {data.major}
            </span>
            <p className="lecture-title">
              {highlightedText(data.title, lectureSearchKeyword)}
            </p>
          </div>
        </div>
        <div className="tag-wrap">
          {data.tags &&
            data.tags.map(
              (tag, index) =>
                tag.name !== '' && (
                  <span className="tag" key={index}>
                    {highlightedTag(tag.name, lectureSearchKeyword)}
                  </span>
                )
            )}
          index
        </div>
        <div className="information-wrap">
          <span className="lecture-writer">{data.name} 교수</span>
          <span className="lecture-date">
            {data.createdAt[0]}.{data.createdAt[1].toString().padStart(2, '0')}.
            {data.createdAt[2].toString().padStart(2, '0')}
          </span>
        </div>
        <div className="handle-wrap">
          <div className="button-wrap">
            <button
              className={`btn-add ir_pm${data.playlistMe ? ' active' : ''}`}
              onClick={(e) => [
                e.preventDefault(),
                dispatch(showPopupF(true)),
                dispatch(setCourseId(data.id)),
              ]}
            >
              추가
            </button>
            <span className="count">추가</span>
          </div>
          <div className="button-wrap">
            <button
              className={`btn-wish ir_pm${data.wishMe ? ' active' : ''}`}
              onClick={(e) =>
                data.wishMe ? DelWish(e, data.id) : AddWish(e, data.id)
              }
            >
              찜
            </button>
            <span className="count">찜</span>
          </div>
          <div className="button-wrap">
            <button
              className={`btn-like ir_pm${data.likeMe ? ' active' : ''}`}
              onClick={(e) =>
                data.likeMe ? DelLike(e, data.id) : AddLike(e, data.id)
              }
            >
              좋아요
            </button>
            <span className="count">{data?.likeCount}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default MLectureCard;
