import { RootState, useAppSelector } from 'module/Module';
import { LectureProps } from 'utils/type';
import LectureCard from 'views/desktop/common/lecture/LectureCard';

const LectureList = () => {
  const lectureList: Array<LectureProps> = useAppSelector(
    (state: RootState) => state.getLectureList.lectureList
  );

  return (
    <>
      {lectureList && (
        <ul className="lecture-list">
          {lectureList.map((data) => (
            <li key={data.id}>
              <LectureCard data={data} />
            </li>
          ))}
        </ul>
      )}
      {lectureList.length === 0 && (
        <p className="list-none">
          검색 결과가 없습니다.
          <br />
          입력하신 단어가 정확한지 다시 확인해 주세요.
        </p>
      )}
    </>
  );
};
export default LectureList;
