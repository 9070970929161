import 'views/desktop/lecture/style/my-playlist.sass';

import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { ChangePlaylist } from 'module/my-playlist';
import { useEffect, useState } from 'react';
import PlayListDetailPopup from 'views/desktop/common/popup/PlayListDetailPopup';
import PlayListPopup from 'views/desktop/common/popup/PlayListPopup';
import MyPlaylist from 'views/desktop/lecture/components/MyPlaylist';

const LectureMyPlayListPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(showDetailPopupF({ showDetailPopup: false, playlistId: null }));
  }, []);

  const [keyword, setKeyword] = useState<string>('');

  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );
  const showDetailPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddDetailPopup.showDetailPopup
  );
  const myChange: boolean = useAppSelector(
    (state: RootState) => state.myPlaylist.myChange
  );

  return (
    <div className="lecture-my-playlist">
      <div className="search-wrap">
        <h2>내 플레이리스트 검색</h2>
        <div className="input-wrap">
          <input
            type="text"
            placeholder="검색어를 입력해 주세요."
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key == 'Enter') dispatch(ChangePlaylist(!myChange));
            }}
          />
          <button
            className="ir_pm"
            onClick={() => {
              dispatch(ChangePlaylist(!myChange));
            }}
          >
            검색
          </button>
        </div>
      </div>
      <div className="content-wrap">
        <MyPlaylist word={keyword} />
      </div>
      {showPopup && <PlayListPopup type={'add'} />}
      {showDetailPopup && <PlayListDetailPopup />}
    </div>
  );
};

export default LectureMyPlayListPage;
