import 'views/desktop/main/style/main-myplaylist.scoped.sass';

import { getMyPlayListData } from 'api/playlist/playlist';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { getMyPlaylist } from 'module/my-playlist';
import { setWatchMode } from 'module/watch-mode';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlayList } from 'utils/type';

const MainMyPlayList = () => {
  const dispatch = useAppDispatch();

  const playlist: Array<PlayList> = useAppSelector(
    (state: RootState) => state.myPlaylist.myPlaylist
  );
  const myChange: boolean = useAppSelector(
    (state: RootState) => state.myPlaylist.myChange
  );

  useEffect(() => {
    const params = {
      keyword: null,
    };
    getMyPlayListData(params).then((res: any) => {
      dispatch(getMyPlaylist(res.data));
    });
  }, [myChange]);

  const noneComponent = () => {
    const playListNone = [];

    for (let i = 0; i < 5 - playlist.length; i++) {
      playListNone.push(
        <li
          className={`list-none ${playlist.length === 0 ? 'list-empty' : ''}`}
          key={`none_${i}`}
        >
          <button
            className="btn-add"
            onClick={() => dispatch(showPopupF(true))}
          >
            +
          </button>
          <p>
            자주 시청하는 강의를
            <br />
            플레이리스트로 만들어 보세요.
          </p>
        </li>
      );
    }

    return playListNone;
  };

  return (
    <div className="my-content-wrap">
      <ul className="playlist-card-list">
        {playlist.map(
          (data, index) =>
            index < 5 && (
              <li className="playlist-card" key={index}>
                <div className="card-title-wrap">
                  {data?.courses[0] ? (
                    <Link
                      className="playlist-name"
                      to={`/lecture/watch/${data?.courses[0].id}`}
                      onClick={() =>
                        dispatch(
                          setWatchMode({
                            playlistMode: true,
                            playlistId: data?.id,
                          })
                        )
                      }
                    >
                      {data.title}
                    </Link>
                  ) : (
                    <p className="playlist-name">{data.title}</p>
                  )}
                  <button
                    className="btn-edit"
                    onClick={() =>
                      dispatch(
                        showDetailPopupF({
                          showDetailPopup: true,
                          playlistId: data.id,
                        })
                      )
                    }
                  >
                    편집
                  </button>
                </div>
                <div className="card-content-wrap">
                  <ul className="lecture-list">
                    {data.courses.length > 0 ? (
                      data.courses.map((element, index) => (
                        <li key={index}>
                          <Link to={`/lecture/watch/${element.id}`}>
                            {index + 1}. {element.title} / {element.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li>등록한 강의가 없습니다.</li>
                    )}
                  </ul>
                </div>
              </li>
            )
        )}
        {noneComponent()}
      </ul>
    </div>
  );
};

export default MainMyPlayList;
