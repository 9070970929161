import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormLabel,
  CFormTextarea,
  CInputGroup,
  CRow,
} from '@coreui/react';
import { inquiryDetail, inquiryPost } from 'api/admin/inquiry';
import DeleteIcon from 'assets/images/global/icon_delete.svg';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

type FormData = {
  title: string;
  email: string;
  createdAt: Array<number>;
  inquiry: string;
  inquiryFile: any | null;

  content: string;
  file: number | null;
};

type FileInformation = {
  name: string;
  url: string;
};

const AdminInquiryDetailPage = () => {
  const navigate = useNavigate();
  const inquiryId = Number(useParams().id);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const [fileInformation, setFileInformation] =
    useState<FileInformation | null>(null);

  const deleteFile = () => {
    setFileInformation(null);
    setValue('file', null);
  };

  useEffect(() => {
    inquiryDetail(inquiryId).then((res: any) => {
      setValue('title', res.data.title);
      setValue('email', res.data.email);
      setValue('inquiry', res.data.content);
      setValue('createdAt', res.data.createdAt);
      setValue('inquiryFile', res.data.file);
    });
  }, []);

  const onChangeFile = (evt: any) => {
    if (evt.target.files.length) {
      const fileTarget = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(fileTarget);

      const fileName = fileTarget.name;
      fileReader.onload = function (e: any) {
        setFileInformation({
          name: fileName,
          url: e.target.result,
        });
      };
    }
  };

  const updateInquiry = () => {
    // @ts-ignore
    const formData = new FormData(
      document.getElementById('inquiry_create_form') as HTMLFormElement
    );
    inquiryPost(inquiryId, formData).then((res: any) => {
      alert('답변이 등록되었습니다.');
      navigate(-1);
    });
  };

  const exitInquiry = () => {
    if (window.confirm('해당 페이지에서 나가시겠습니까??')) {
      navigate(-1);
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="inquiry_detail">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">문의 답변하기</h1>
          </CCardHeader>
          <form id="inquiry_create_form" onSubmit={handleSubmit(updateInquiry)}>
            <CCardBody className="card-body">
              <ul className="user-table">
                <li>
                  <div className="text-center table-header">제목</div>
                  <div className="table-content">{watch().title}</div>
                </li>
                <li>
                  <div className="text-center table-header">이메일</div>
                  <div className="table-content">{watch().email}</div>
                </li>
                <li>
                  <div className="text-center table-header">문의날짜</div>
                  {watch().createdAt && (
                    <div className="table-content">
                      {watch().createdAt[0]}.{watch().createdAt[1]}.
                      {watch().createdAt[2]}
                    </div>
                  )}
                </li>
                <li>
                  <div className="text-center table-header">문의내용</div>
                  <div className="table-content">{watch().inquiry}</div>
                </li>
                <li>
                  <div className="text-center table-header">첨부파일</div>
                  <div className="table-content">
                    {watch().inquiryFile && (
                      <a
                        href={watch().inquiryFile.url}
                        target="_blank"
                        className="ms-3"
                        rel="noreferrer"
                      >
                        {watch().inquiryFile.name}
                      </a>
                    )}
                  </div>
                </li>
              </ul>
              <div className="mb-3">
                <CFormLabel htmlFor="content">내용입력</CFormLabel>
                <CInputGroup className="mb-3" size="lg">
                  <CFormTextarea
                    id="content"
                    rows={11}
                    style={{ resize: 'none' }}
                    {...register('content', {
                      required: '내용을 입력해 주세요.',
                      minLength: {
                        value: 2,
                        message: '2자 이상 입력해 주세요.',
                      },
                    })}
                  ></CFormTextarea>
                </CInputGroup>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.content && (
                      <p className="error-message" role="alert">
                        {errors.content.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <p className="form-label">파일 선택</p>
                <CFormLabel htmlFor="file" className="btn btn-secondary">
                  파일선택
                </CFormLabel>
                <input
                  type="file"
                  id="file"
                  {...register('file', {
                    required: false,
                    onChange: onChangeFile,
                  })}
                  className="d-none"
                />
                {fileInformation != null && (
                  <>
                    <a
                      href={fileInformation.url}
                      target="_blank"
                      className="ms-3"
                      rel="noreferrer"
                    >
                      {fileInformation.name}
                    </a>
                    <button
                      className="btn ms-2"
                      style={{
                        background: `url(${DeleteIcon}) no-repeat center center`,
                        width: 20,
                        height: 20,
                      }}
                      type="button"
                      onClick={deleteFile}
                    ></button>
                  </>
                )}
              </div>
            </CCardBody>

            <CCardFooter className="d-flex justify-content-end">
              <div>
                <CButton type="submit" color="primary" className="me-3">
                  등록
                </CButton>
                <CButton
                  type="button"
                  color="dark"
                  variant="outline"
                  onClick={() => exitInquiry()}
                >
                  목록
                </CButton>
              </div>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminInquiryDetailPage;
