import { Outlet } from 'react-router-dom';
import Footer from 'views/desktop/common/layout/Footer';
import SimpleHeader from 'views/desktop/common/layout/SimpleHeader';

const AppFreeLayout = () => (
  <>
    <SimpleHeader />
    <main id="app_free_main">
      <Outlet />
    </main>
    <Footer />
  </>
);

export default AppFreeLayout;
