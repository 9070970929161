export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 3,
    borderColor: state.isFocused ? '#d8d8d8' : '#d8d8d8',
    fontSize: 14,
    '&:hover': {
      borderColor: '#d8d8d8',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: 0,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#999',
  }),
  menu: (provided: any) => ({
    ...provided,
    top: 34,
    border: '1px solid #d8d8d8',
    color: '#999',
    zIndex: 1000,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#003876' : '#fff',
    color: state.isFocused ? '#fff' : '#333',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#003876',
      color: 'white',
    },
  }),
};

export const mobileSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0.8333333333333334vw',
    borderColor: state.isFocused ? '#d8d8d8' : '#d8d8d8',
    fontSize: '3.8888888888888889vw',
    '&:hover': {
      borderColor: '#d8d8d8',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: 0,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#999',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    lineHeight: '3.888889vw',
  }),
  menu: (provided: any) => ({
    ...provided,
    top: '11.54444444444445vw',
    border: '0.277778vw solid #d8d8d8',
    color: '#999',
    zIndex: 1000,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#003876' : '#fff',
    color: state.isFocused ? '#fff' : '#333',
    lineHeight: '3.888889vw',
    fontSize: '3.8888888888888889vw',
    '&:hover': {
      backgroundColor: '#003876',
      color: 'white',
    },
  }),
};

export const loginEmailOptions = [
  { value: 'yuhs.ac', label: 'yuhs.ac (연세의료원 교원)' },
  { value: 'yonsei.ac.kr', label: 'yonsei.ac.kr (연세대학교 의과대학 학생)' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'daum.net', label: 'daum.net' },
  { value: '', label: '직접입력' },
];

export const insiderEmailOptions = [
  { value: 'yuhs.ac', label: 'yuhs.ac (연세의료원 교원)' },
  { value: 'yonsei.ac.kr', label: 'yonsei.ac.kr (연세대학교 의과대학 학생)' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'daum.net', label: 'daum.net' },
  { value: '', label: '직접입력' },
];

export const OutsiderEmailOptions = [
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'daum.net', label: 'daum.net' },
  { value: '', label: '직접입력' },
];

export const studentEmailOptions = [
  { value: 'yonsei.ac.kr', label: 'yonsei.ac.kr (연세대학교 의과대학 학생)' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'daum.net', label: 'daum.net' },
  { value: '', label: '직접입력' },
];

export const professorEmailOptions = [
  { value: 'yuhs.ac', label: 'yuhs.ac (연세의료원 교원)' },
  { value: 'gmail.com', label: 'gmail.com' },
  { value: 'naver.com', label: 'naver.com' },
  { value: 'daum.net', label: 'daum.net' },
  { value: '', label: '직접입력' },
];

export const gradeOptions = [
  { value: 1, label: '예과 1학년' },
  { value: 2, label: '예과 2학년' },
  { value: 3, label: '본과 1학년' },
  { value: 4, label: '본과 2학년' },
  { value: 5, label: '본과 3학년' },
  { value: 6, label: '본과 4학년' },
  { value: 7, label: '휴학' },
];

const yearObject = () => {
  const yOb = [];
  for (let i = 1950; i < 2022; i++) {
    const newObj = {
      value: i,
      label: i,
    };
    yOb.push(newObj);
  }
  return yOb;
};

export const yearOptions = yearObject();

const monthObject = () => {
  const options = [];
  for (let i = 1; i < 13; i++) {
    const newObj = {
      value: i,
      label: i,
    };
    options.push(newObj);
  }
  return options;
};

export const monthOptions = monthObject();

const dayObject = () => {
  const options = [];
  for (let i = 1; i < 32; i++) {
    const newObj = {
      value: i,
      label: i,
    };
    options.push(newObj);
  }
  return options;
};

export const dayOptions = dayObject();
