import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CNav,
  CNavItem,
  CRow,
  CTable,
  CTableBody,
} from '@coreui/react';
import { adminSecession, getUserDetail, postUser } from 'api/admin/user';
import Default_thumbnail from 'assets/images/global/profile_default.svg';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';

import { findPassword } from '../../../../api/user/find';

const YEAR = [...Array(73)].map((_, i) => i + 1950);
const MONTH = [...Array(12)].map((_, index) => index + 1);
const DAY = [...Array(31)].map((_, index) => index + 1);

type File = {
  id: number;
  url: string;
  name: string;
};

interface Tags {
  name: string;
}

type FormData = {
  profileImage: Array<File>;
  id: number;
  name: string;
  email: string;
  password: string;
  passwordCheck: string;
  division: string;
  employeeNumber: number;
  year: number;
  month: number;
  day: number;
  birthday: string;
  tags: Array<Tags>;
  subEmail: string;
  phone: number;
};

const AdminInsiderEditPage = () => {
  const userId = Number(useParams().id);
  const navigate = useNavigate();

  const [isMasking, setIsMasking] = useState<boolean>(true);
  const [imageChanged, setImageChanged] = useState<boolean>(false);
  const [createdAt, setCreatedAt] = useState<Array<number>>([]);
  const [lastLogin, setLastLogin] = useState<Array<number>>([]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormData>();

  const {
    fields: tagField,
    append: tagAppend,
    replace: tagReplace,
  } = useFieldArray({
    control,
    name: 'tags',
  });

  const getData = () => {
    const params = {
      masking: isMasking,
    };
    getUserDetail(userId, params).then((res: any) => {
      setValue('name', res.data.name);
      setValue('id', res.data.id);
      setValue('email', res.data.email);
      setValue('employeeNumber', res.data.employeeNumber);
      setValue('division', res.data.division);
      setValue('subEmail', res.data.subEmail);
      setValue('phone', res.data.phone);
      setValue('year', res.data.birthday[0]);
      setValue('month', res.data.birthday[1]);
      setValue('day', res.data.birthday[2]);
      setValue(
        'birthday',
        `${res.data.birthday[0]}-${res.data.birthday[1]}-${res.data.birthday[2]}`
      );
      setCreatedAt(res.data.createdAt);
      setLastLogin(res.data.lastLoginAt);
      const newObj: Array<Tags> = [];
      res.data.tags.forEach((x: any, i: number) => {
        newObj.push({ name: x });
      });
      if (res.data.tags.length < 5) {
        for (let i = 0; i < 5 - res.data.tags.length; i++) {
          newObj.push({ name: '' });
        }
      }
      tagReplace(newObj);
      setImgSrc(res.data.file.url);
    });
  };

  const [imgSrc, setImgSrc] = useState<string>(Default_thumbnail);

  const onChangeThumbnail = (evt: any) => {
    if (evt.target.files.length) {
      const imgTarget = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e: any) {
        setImgSrc(e.target.result);
      };
    } else {
      setImgSrc(Default_thumbnail);
    }
    setImageChanged(true);
  };

  const deleteThumbnail = () => {
    setImgSrc(Default_thumbnail);
    setValue('profileImage', []);
    setImageChanged(true);
  };

  useEffect(() => {
    getData();
  }, [isMasking]);

  const postEdit = () => {
    const tagsArray: Array<string> = [];
    watch().tags?.forEach((tag) => {
      tagsArray.push(tag.name);
    });
    // @ts-ignore
    const formData = new FormData(document.getElementById('insider_edit_form'));
    formData.append('imageChanged', String(imageChanged));
    for (const a of tagsArray) {
      formData.append('tags[]', a);
    }
    postUser(userId, formData).then((res: any) => {
      alert('회원정보 수정이 완료되었습니다.');
    });
  };

  const postFindPassword = () => {
    const data = {
      email: watch().email,
      sendSubEmail: false,
    };
    findPassword(data)
      .then((res: any) => {
        alert('새로운 비밀번호를 보냈습니다.');
      })
      .catch((err: any) => {
        alert('마스킹 해제 조회 이후 사용해주세요');
      });
  };

  const secession = () => {
    adminSecession(userId)
      .then((res: any) => {
        alert('탈퇴 되었습니다.');
        navigate(-1);
      })
      .catch(() => {
        alert('오류가 발생하였습니다');
      });
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="insider_edit">
          <form id="insider_edit_form" onSubmit={handleSubmit(postEdit)}>
            <CCardHeader className="d-flex justify-content-between">
              <h1 className="mb-0 pt-2 pb-2 fs-5">내부인 회원관리</h1>
            </CCardHeader>

            <CCardBody>
              <CNav variant="tabs" className="mb-3">
                <CNavItem active>
                  <Link
                    to={`/admin/user/insider/edit/${userId}`}
                    className="nav-link active"
                  >
                    회원정보
                  </Link>
                </CNavItem>
                <CNavItem>
                  <Link
                    to={`/admin/user/insider/playlist/${userId}`}
                    className="nav-link"
                  >
                    내 플레이리스트
                  </Link>
                </CNavItem>
                <CNavItem>
                  <Link
                    to={`/admin/user/insider/subscribe/${userId}`}
                    className="nav-link"
                  >
                    구독 플레이리스트
                  </Link>
                </CNavItem>
              </CNav>

              <CTable className="user-table">
                <CTableBody>
                  <li>
                    <div className="text-center table-header">회원번호</div>
                    <div className="text-center table-content">
                      {watch().id}
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">구분</div>
                    <div className="text-center table-content">내부인</div>
                  </li>

                  <li>
                    <div className="text-center table-header">이름</div>
                    <div className="text-center table-content">
                      <CFormInput
                        type="text"
                        className="w-25"
                        placeholder="이름"
                        {...register('name')}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">이메일</div>
                    <div className="text-center table-content">
                      <span className="me-3">{watch().email}</span>
                      <CButton
                        type="button"
                        color="dark"
                        variant="outline"
                        onClick={() => setIsMasking(!isMasking)}
                      >
                        마스킹(*) 해제 조회
                      </CButton>
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">비밀번호</div>
                    <div className="text-center table-content">
                      <CButton
                        color="info"
                        className="text-white"
                        onClick={postFindPassword}
                      >
                        이메일 발송
                      </CButton>
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">직번</div>
                    <div className="text-center table-content">
                      <CFormInput
                        type="text"
                        className="w-25"
                        placeholder="직번"
                        {...register('employeeNumber')}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">소속</div>
                    <div className="text-center table-content">
                      <CFormInput
                        type="text"
                        className="w-25"
                        placeholder="소속"
                        {...register('division')}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">생년월일</div>
                    <div className="text-center table-content">
                      <CInputGroup className="w-75">
                        <CFormSelect
                          className="w-25"
                          {...register('year', {
                            onChange: () =>
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              ),
                          })}
                        >
                          <option>년도</option>
                          {YEAR.map((year, index) => (
                            <option key={index} value={year}>
                              {year}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormSelect
                          className="w-25"
                          {...register('month', {
                            onChange: () =>
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              ),
                          })}
                        >
                          <option>월</option>
                          {MONTH.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormSelect
                          className="w-25"
                          {...register('day', {
                            onChange: () =>
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              ),
                          })}
                        >
                          <option>일</option>
                          {DAY.map((day, index) => (
                            <option key={index} value={day}>
                              {day}
                            </option>
                          ))}
                        </CFormSelect>
                        <CFormInput type="hidden" {...register('birthday')} />
                      </CInputGroup>
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">태그</div>
                    <div className="table-content d-block">
                      {tagField &&
                        tagField.map((tag, index) => (
                          <CFormInput
                            key={index}
                            type="text"
                            className="w-25 mt-3"
                            placeholder="태그 입력"
                            {...register(`tags.${index}.name`, {
                              required: false,
                            })}
                          />
                        ))}
                      <CButton color="info" className="text-white mt-3">
                        추가
                      </CButton>
                    </div>
                  </li>

                  <li className="table-double">
                    <div className="text-center table-header">대체 이메일</div>
                    <div className="text-center table-content">
                      <CFormInput
                        type="text"
                        className="w-50"
                        placeholder="복구용 이메일 입력"
                        {...register('subEmail', { required: false })}
                      />
                    </div>
                    <div className="text-center table-header">전화번호</div>
                    <div className="text-center table-content">
                      <CFormInput
                        type="text"
                        className="w-50"
                        placeholder="숫자만 입력"
                        {...register('phone', { required: false })}
                      />
                    </div>
                  </li>

                  <li>
                    <div className="text-center table-header">프로필 사진</div>
                    <div className="text-center table-content">
                      <div className="profile-box">
                        <input
                          type="file"
                          id="profileImage"
                          {...register('profileImage', {
                            required: false,
                            onChange: onChangeThumbnail,
                          })}
                        />
                        <div className="btn-wrap">
                          {watch().profileImage?.length > 0 && (
                            <button
                              type="button"
                              className="btn-delete-profile"
                              onClick={deleteThumbnail}
                            >
                              삭제하기
                            </button>
                          )}
                        </div>
                        <img
                          src={imgSrc}
                          className="profile-image"
                          alt="프로필사진"
                        />
                      </div>
                    </div>
                  </li>

                  <li className="table-double">
                    <div className="text-center table-header">가입일</div>
                    <div className="text-center table-content">
                      {createdAt[0]}.{createdAt[1]}.{createdAt[2]}{' '}
                      {createdAt[3]}:{createdAt[4]}:{createdAt[5]}
                    </div>
                    <div className="text-center table-header">최근 접속일</div>
                    <div className="text-center table-content">
                      {lastLogin ? lastLogin[0] + '.' : ''}
                      {lastLogin ? lastLogin[1] + '.' : ''}
                      {lastLogin ? lastLogin[2] : ''} &nbsp;
                      {lastLogin ? lastLogin[3] + ':' : ''}
                      {lastLogin ? lastLogin[4] + ':' : ''}
                      {lastLogin ? lastLogin[5] : ''}
                    </div>
                  </li>
                </CTableBody>
              </CTable>
            </CCardBody>

            <CCardFooter className="d-flex justify-content-between">
              <CButton
                type="button"
                color="danger"
                className="text-white"
                onClick={secession}
              >
                탈퇴
              </CButton>
              <div>
                <CButton type="submit" color="primary" className="me-3">
                  저장
                </CButton>
                <CButton
                  type="button"
                  color="dark"
                  variant="outline"
                  onClick={() => navigate(-1)}
                >
                  목록
                </CButton>
              </div>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminInsiderEditPage;
