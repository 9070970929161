import Send from 'utils/send';

export const getMyPlayListData = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/playlists/my',
    params: params,
  });
};

export const getSubscribePlayListData = (params: any | null) => {
  return Send({
    method: 'get',
    url: '/api/playlists/subscribes',
    params: params,
  });
};

export const Subscribe = (playlistId: number | null) => {
  return Send({
    method: 'post',
    url: `/api/playlists/${playlistId}/subscribes`,
  });
};

export const delSubscribe = (playlistId: number | null) => {
  return Send({
    method: 'delete',
    url: `/api/playlists/${playlistId}/subscribes`,
  });
};

export const changeShare = (playlistId: number) => {
  return Send({
    method: 'patch',
    url: `/api/playlists/${playlistId}`,
  });
};

export const sortPlayList = (data: any) => {
  return Send({
    method: 'patch',
    url: '/api/playlists/change-sequence',
    data: data,
  });
};

export const sortCourses = (playlistId: number | null, data: any) => {
  return Send({
    method: 'patch',
    url: `/api/playlists/change-sequence/${playlistId}`,
    data: data,
  });
};

export const delCourse = (playlistId: number | null, courseId: number) => {
  return Send({
    method: 'delete',
    url: `/api/playlists/${playlistId}/courses/${courseId}`,
  });
};

export const getDetailData = (id: number | null) => {
  return Send({
    method: 'get',
    url: `/api/playlists/${id}`,
  });
};

export const delPlayList = (id: number | null) => {
  return Send({
    method: 'delete',
    url: `/api/playlists/${id}`,
  });
};

export const newPlayList = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/playlists',
    data: data,
  });
};

export const addPlayList = (playlistId: number, courseId: number) => {
  return Send({
    method: 'post',
    url: `/api/playlists/${playlistId}/courses/${courseId}`,
  });
};
