import Send from 'utils/send';

export const getAlarm = () => {
  return Send({
    method: 'get',
    url: '/api/notifications',
  });
};

export const deleteAlarm = (id: number) => {
  return Send({
    method: 'delete',
    url: `/api/notifications/${id}`,
  });
};

export const deleteAllAlarm = () => {
  return Send({
    method: 'delete',
    url: '/api/notifications',
  });
};
