import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isLogin } from 'utils/auth';

const PrivateRoute = ({ redirectPath = '/main', restrict, children }: any) => {
  const isLogins: boolean = isLogin();

  if (isLogins && restrict) {
    return <Navigate to={redirectPath} replace />;
  } else return children ? children : <Outlet />;
};

export default PrivateRoute;
