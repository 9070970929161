// style
import 'views/mobile/lecture-management/style/m-lecture-management.sass';

import { getManagementList } from 'api/lecture/management';
import { setList, setManagementCount } from 'module/lecture-management';
import { useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect } from 'react';
import { LectureManagementProps } from 'utils/type';
// component
import MLectureManagementList from 'views/mobile/lecture-management/components/MLectureManagementList';

const MLectureManagementPage = () => {
  const dispatch = useAppDispatch();
  const managementList: Array<LectureManagementProps> = useAppSelector(
    (state) => state.lectureManagement.list
  );
  const change: boolean = useAppSelector(
    (state) => state.lectureManagement.change
  );

  const getList = () => {
    getManagementList().then((res: any) => {
      dispatch(setManagementCount(res.data.courseCount));
      dispatch(setList(res.data));
    });
  };

  useEffect(() => {
    getList();
  }, [change]);

  return (
    <div className="m-lecture-management">
      <div className="m-container">
        <div className="title-wrap">
          <h1>
            총 <b>{managementList.length}</b>개
          </h1>
          <button
            className="btn-create"
            onClick={() => {
              alert('강의등록은 PC에서만 가능합니다');
            }}
          >
            강의 등록
          </button>
        </div>
        <div className="content-wrap">
          <MLectureManagementList />
        </div>
      </div>
    </div>
  );
};

export default MLectureManagementPage;
