import { CRow } from '@coreui/react';
import AdminDashboardNotice from 'views/admin/dashboard/AdminDashboardNotice';
import AdminDashboardRegister from 'views/admin/dashboard/AdminDashboardRegister';
import AdminDashboardStatus from 'views/admin/dashboard/AdminDashboardStatus';

const AdminDashboardPage = () => {
  return (
    <CRow>
      <AdminDashboardRegister />
      <AdminDashboardStatus />
      <AdminDashboardNotice />
    </CRow>
  );
};

export default AdminDashboardPage;
