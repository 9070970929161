import 'views/mobile/terms/style/m-terms.sass';

import { setTitle } from 'module/mobile/title';
import { useAppDispatch } from 'module/Module';
import { useEffect } from 'react';

const MEmailTerms = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('이메일무단수집거부'));
  }, []);

  return (
    <section className="m-email-terms-container m-container">
      <div className="terms-wrap">
        <p>
          본 웹사이트는 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의
          기술적 장치를 이용하여 무단 수집되는 것을 거부합니다. 이를 위반시
          『정보통신망 이용촉진 및 정보보호등에 관한 법률』등 에 의해 처벌 받을
          수 있습니다.
        </p>
        <h2>&lt; 정보통신망 이용촉진 및 정보보호 등에 관한 법률 &gt;</h2>
        <h3>제50조의2 (전자우편주소의 무단 수집행위 등 금지)</h3>
        <ul>
          <li>
            ・누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이
            인터넷 홈페이지에서 자동으로 전자우편주소를 수집 하는 프로그램 그
            밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.
          </li>
          <li>
            ・누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 <br />
            판매ㆍ유통하여서는 아니된다.
          </li>
          <li>
            ・누구든지 제1항 및 제2항의 규정에 의하여 수집ㆍ판매 및 유통이
            금지된 전자우편주소임을 알고 이를 정보 전송에 이용하여서는 아니된다.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MEmailTerms;
