import { configureStore } from '@reduxjs/toolkit';
import authentication from 'module/auth';
import classCategory from 'module/class-category';
import lectureAddDetailPopup from 'module/lecture-add-detail-popup';
import lectureAddPopup from 'module/lecture-add-popup';
import lectureManagement from 'module/lecture-management';
import lectureWatch from 'module/lecture-watch';
import getLectureList from 'module/lecturelist';
import asideModule from 'module/mobile/aside';
import titleModule from 'module/mobile/title';
import myPlaylist from 'module/my-playlist';
import playlistMode from 'module/playlist-mode';
import publicPlaylist from 'module/public-playlist';
import registerShowPopup from 'module/register-popup';
import setCategory from 'module/search-category';
import subPlaylist from 'module/sub-playlist';
import watchMode from 'module/watch-mode';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // localStorage를 사용하려면 필요합니다.

const initialState = {};

const rootReducer = combineReducers({
  authentication,
  lectureAddPopup,
  lectureAddDetailPopup,
  registerShowPopup,
  classCategory,
  titleModule,
  getLectureList,
  setCategory,
  asideModule,
  playlistMode,
  myPlaylist,
  subPlaylist,
  publicPlaylist,
  lectureWatch,
  lectureManagement,
  watchMode,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
  enhancers: (defaultEnhancers) => [...defaultEnhancers],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
