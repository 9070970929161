import { useAppSelector } from 'module/Module';
import { LectureManagementProps } from 'utils/type';
import MLectureManagementCard from 'views/mobile/lecture-management/components/MLectureManagementCard';

const MLectureManagementList = () => {
  const managementList: Array<LectureManagementProps> = useAppSelector(
    (state) => state.lectureManagement.list
  );

  return (
    <ul className="lecture-management-list">
      {managementList &&
        managementList.map((data) => (
          <li key={data?.id}>
            <MLectureManagementCard data={data} />
          </li>
        ))}
    </ul>
  );
};

export default MLectureManagementList;
