import { deleteLecture } from 'api/lecture/lecture';
import { getCourseStatus, postCourseStatus } from 'api/lecture/management';
import iconMenu from 'assets/images/global/icon_list_menu.svg';
import { detectChange } from 'module/lecture-management';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatBytes } from 'utils/helper';
import { LectureManagementProps } from 'utils/type';

type LectureManagementCardProps = {
  data: LectureManagementProps;
};

const MLectureManagementCard = ({ data }: LectureManagementCardProps) => {
  const [status, setStatus] = useState<string | null>(null);
  const change: boolean = useAppSelector(
    (state: RootState) => state.lectureManagement.change
  );
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const getStatus = () => {
    getCourseStatus(data.id).then((res: any) => {
      setStatus(res.data.status);
    });
  };

  useEffect(() => {
    getStatus();
  }, [change]);

  const dispatch = useAppDispatch();

  const applyStatus = () => {
    if (window.confirm('관리자에게 현황요청 하시겠습니까?')) {
      postCourseStatus(data.id).then((res: any) => {
        alert('관리자에게 현황요청이 완료되었습니다.');
        dispatch(detectChange(!change));
      });
    }
  };

  const deleteItem = (courseId: number) => {
    if (window.confirm('강의를 삭제하시겠습니까?')) {
      deleteLecture(courseId).then((res: any) => {
        alert('강의가 삭제 되었습니다.');
        dispatch(detectChange(!change));
      });
    }
  };

  const showStatus = () => {
    switch (status) {
      default:
        return (
          <button className="btn-handler" onClick={applyStatus}>
            현황요청
          </button>
        );
      case 'WAIT':
        return <p className="btn-handler wait">현황요청(대기중)</p>;
      case 'RETRY':
        return <p className="btn-handler wait">현황요청(대기중)</p>;
      case 'ACCEPTED':
        return (
          <Link
            to={`/lecture/management/status/${data.id}?title=${data.title}&category=${data.category}`}
            className="btn-handler apply"
          >
            현황요청(승인)
          </Link>
        );
      case 'DENIED':
        return <button className="btn-handler fail">현황요청(재요청)</button>;
    }
  };

  return (
    <div className="lecture-management-card">
      <img
        src={iconMenu}
        alt="메뉴"
        className="icon-menu"
        onClick={() => setShowMenu(!showMenu)}
      />
      {showMenu && (
        <div className="card-header">
          <button className="btn-handler" onClick={() => deleteItem(data.id)}>
            삭제하기
          </button>
          <Link
            to={`/lecture/watch/${data.id}#comment`}
            className="btn-handler"
          >
            댓글관리
          </Link>
          {showStatus()}
        </div>
      )}
      <div className="card-main">
        <Link to={`/lecture/watch/${data.id}`}>
          {data.videoType === 'VIMEO' ? (
            <div className="img-wrap">
              <img
                src={data.courseThumbnail.url}
                className="lecture-image"
                alt="강의 썸네일"
              />
            </div>
          ) : (
            <div className="img-wrap">
              <img
                src={
                  data.courseThumbnail?.url
                    ? data.courseThumbnail.url
                    : `https://img.youtube.com/vi/${data.videoKey}/mqdefault.jpg`
                }
                className="lecture-image"
                alt="강의 썸네일"
              />
            </div>
          )}
        </Link>
        <div className="text-wrapper">
          <span
            className="lecture-category"
            style={{
              backgroundColor:
                data?.category === '기초의학교실'
                  ? '#f8cbad'
                  : data?.category === '임상의학교실'
                    ? '#c6e0b4'
                    : data?.category === '인문사회의학교실'
                      ? '#d8c5ff'
                      : data?.category === '연세동곡의학교육원'
                        ? '#00B0F0'
                        : '',
            }}
          >
            {data.major} / {data.category}
          </span>
          <p className="lecture-title">
            <Link to={`/lecture/watch/${data.id}`}>{data.title}</Link>
          </p>
          <div className="tag-wrap">
            {data.tags &&
              data.tags.map((tag) => (
                <span key={tag.id} className="tag">
                  #{tag.name}
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="count-wrap">
          <span>
            조회수 <b>{data.viewCount ?? 0}</b>
          </span>
          <span>찜수 {data.wishCount ?? 0}</span>
          <span>플레이리스트에 등록수 {data.playlistCount ?? 0}</span>
          <span>좋아요 수 {data.likeCount ?? 0}</span>
          <span>댓글수 {data.commentCount ?? 0}</span>
        </div>
        <div className="size-wrap">
          <span>영상용량 {formatBytes(data.videoTotal) ?? 0}</span>
          <span>
            첨부파일용량({data.fileCount ?? 0}개){' '}
            {data.fileTotal ? formatBytes(data.fileTotal) : '0KB'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MLectureManagementCard;
