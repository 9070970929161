import 'views/mobile/lecture/style/m-lecture-all-list.sass';

import { getLectureList } from 'api/lecture/lecture';
import { showPopupF } from 'module/lecture-add-popup';
import { getLecture } from 'module/lecturelist';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { mobileSelectStyles } from 'utils/select-option';
import MLectureList from 'views/mobile/common/lecture/MLectureList';
import MPlayListPopup from 'views/mobile/common/popup/MPlayListPopup';

type ClassCategory = {
  id: number;
  name: string;
  category: string;
};

const MLectureAllListPage = () => {
  const getParams = new URLSearchParams(window.location.search);
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );
  const lectureChange: boolean = useAppSelector(
    (state: RootState) => state.getLectureList.lectureChange
  );
  const [keyword, setKeyword] = useState<string | null>(
    getParams.get('keyword') ?? ''
  );
  const [keywordText, setKeywordText] = useState<string | null>(
    getParams.get('keyword') ?? ''
  );
  const [isButtonClick, setIsButtonClick] = useState<boolean>(false);
  const [sort, setSort] = useState<string>('date');
  const [tagSearch, setTagSearch] = useState<string | null>(
    getParams.get('tagSearch') ?? 'false'
  );
  const [category, setCategory] = useState<number>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [nowPage, setNowPage] = useState<number>(0);

  const categories: Array<ClassCategory> = useAppSelector(
    (state: RootState) => state.classCategory
  );

  const categoryObj = () => {
    const options: { value: number; label: string }[] = [];
    options.push({ value: 0, label: '전체' });
    categories.forEach((x) => {
      const newObj = {
        value: x.id,
        label: x.name,
      };
      options.push(newObj);
    });
    return options;
  };

  const categoryOptions = categoryObj();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const getList = (page: any) => {
    const params = {
      keyword: keyword,
      categoryId: category,
      tagSearch: getParams.get('tagSearch') ?? false,
      categoryNameSearch: false,
      page: page.selected + 1,
      size: 20,
      sort: sort,
    };
    getLectureList(params).then((res) => {
      setNowPage(res.data.number);
      dispatch(getLecture(res.data.content));
      setTotalElements(res.data.totalElements);
      setTotalPage(res.data.totalPages);
    });
  };

  useEffect(() => {
    dispatch(showPopupF(false));
    getList({ selected: nowPage });
    getParams.get('tagSearch') === 'true'
      ? setIsCheck(true)
      : setIsCheck(false);
  }, [isButtonClick, tagSearch, category, sort, lectureChange]);

  return (
    <div className="m-lecture-all-list">
      <div className="search-wrap">
        <div className="checkbox-container">
          <h2>강의 검색</h2>
          <div className="checkbox-wrap">
            <input
              type="checkbox"
              id="search_tag"
              onChange={(e) => {
                navigate(
                  `/lecture/list?keyword=${keyword}&tagSearch=${e.target.checked}`
                );
                setIsButtonClick(!isButtonClick);
              }}
              checked={isCheck}
            />
            <label htmlFor="search_tag">
              {getParams.get('tagSearch') === 'true' ? (
                <b>#태그만 검색</b>
              ) : (
                '#태그만 검색'
              )}
            </label>
          </div>
        </div>
        <div className="input-container">
          <div className="select-wrap">
            <Select
              options={categoryOptions}
              styles={mobileSelectStyles}
              inputId="major"
              placeholder="구분"
              className="input-major-select"
              classNamePrefix="input-select"
              onChange={(option: any) => {
                setCategory(option.value);
              }}
            />
          </div>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="검색어를 입력해 주세요."
              onChange={(e) => setKeyword(e.target.value)}
              defaultValue={getParams.get('keyword') ?? ''}
            />
            <button
              className="ir_pm"
              onClick={() => {
                setIsButtonClick(!isButtonClick);
                navigate(
                  `/lecture/list?keyword=${keyword}${getParams.get('tagSearch') ? `&tagSearch=${getParams.get('tagSearch')}` : ''}`
                );
                setKeywordText(keyword);
              }}
            >
              검색
            </button>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        <div className="handler-wrap">
          <h2>
            총 <b>{totalElements}</b>개
          </h2>
          <ul className="order-list">
            <li className={sort === 'date' ? 'active' : ''}>
              <button type="button" onClick={() => setSort('date')}>
                최신순
              </button>
            </li>
            <li className={sort === 'views' ? 'active' : ''}>
              <button type="button" onClick={() => setSort('views')}>
                조회순
              </button>
            </li>
            <li className={sort === 'wish' ? 'active' : ''}>
              <button type="button" onClick={() => setSort('wish')}>
                찜 순
              </button>
            </li>
            <li className={sort === 'playlist' ? 'active' : ''}>
              <button type="button" onClick={() => setSort('playlist')}>
                플레이리스트 등록순
              </button>
            </li>
          </ul>
        </div>
        <MLectureList />
        {totalPage >= 1 && (
          <ReactPaginate
            breakLabel="..."
            containerClassName="m-user-paginate"
            nextLabel="다음"
            onPageChange={getList}
            pageCount={totalPage}
            pageClassName="user-paginate-item"
            previousLabel="이전"
          />
        )}
      </div>
      {showPopup && <MPlayListPopup />}
    </div>
  );
};

export default MLectureAllListPage;
