// style
import 'views/desktop/lecture-management/style/lecture-management.sass';

import { getManagementList } from 'api/lecture/management';
import { setList } from 'module/lecture-management';
import { useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LectureManagementProps } from 'utils/type';
// component
import LectureManagementList from 'views/desktop/lecture-management/components/LectureManagementList';

const LectureManagementPage = () => {
  const dispatch = useAppDispatch();
  const managementList: Array<LectureManagementProps> = useAppSelector(
    (state) => state.lectureManagement.list
  );
  const change: boolean = useAppSelector(
    (state) => state.lectureManagement.change
  );

  const getList = () => {
    getManagementList().then((res: any) => {
      dispatch(setList(res.data));
    });
  };

  useEffect(() => {
    getList();
  }, [change]);

  return (
    <div className="lecture-management">
      <div className="u-container">
        <div className="title-wrap">
          <h1>
            총 <b>{managementList.length}</b>개 강의
          </h1>
          <Link to="/lecture/management/create" className="btn-create">
            강의 등록
          </Link>
        </div>
        <div className="content-wrap">
          <LectureManagementList />
        </div>
      </div>
    </div>
  );
};

export default LectureManagementPage;
