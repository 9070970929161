import 'views/mobile/terms/style/m-terms.sass';

import { setTitle } from 'module/mobile/title';
import { useAppDispatch } from 'module/Module';
import { useEffect } from 'react';

const MPrivacyTerms = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('개인정보처리방침'));
  }, []);

  return (
    <section className="m-terms-container m-container">
      <div className="terms-wrap">
        <h3>개인정보의 수집 및 이용목적</h3>
        <p>
          연세동곡의학교육원 교육플랫폼 SEViT (세빛) (이하 “세빛”)은 모든
          정보주체의 개인정보를 매우 소중하게 생각하며 개인정보를 가장
          효과적이고 안전하게 보호하기 위하여 최선의 노력을 다하고 있습니다.
          세빛은 의료법, 개인정보 보호법 등 준수하여야 할 관련 법령상의
          개인정보보호 규정을 준수하고 있습니다.
        </p>
        <p>
          의료원 및 산하기관(연세동곡의학교육원, 의과대학 등)의 홈페이지는
          통합회원제로 운영되고 있으며, 한 번의 로그인으로 사이트 이용이
          가능합니다(2023 상반기 예정).
        </p>
        <h3>제1조 (처리하는 개인정보의 항목)</h3>
        <p>
          세빛은 교육 및 연구를 위해 필요한 최소한의 개인정보만을 수집합니다.
        </p>
        <h4>&lt;홈페이지 관련 수집항목&gt;</h4>
        <h5>① 회원가입 처리항목</h5>
        <ul>
          <li>
            <h5>1) 교수</h5>
            <ul>
              <li>
                - 필수항목 : 이름, 이메일, 비밀번호, 과/교실, 생년월일, 태그
              </li>
              <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
            </ul>
          </li>
          <li>
            <h5>2) 학생</h5>
            <ul>
              <li>
                - 필수항목 : 이름, 이메일, 비밀번호, 학번, 학년, 생년월일, 태그
              </li>
              <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
            </ul>
          </li>
          <li>
            <h5>3) 원내 직원</h5>
            <ul>
              <li>
                - 필수항목 : 이름, 이메일, 비밀번호, 소속, 직번, 생년월일, 태그
              </li>
              <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
            </ul>
          </li>
          <li>
            <h5>4) 파견 직원</h5>
            <ul>
              <li>- 필수항목 : 이름, 이메일, 비밀번호, 소속, 생년월일, 태그</li>
              <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
            </ul>
          </li>
          <li>
            <h5>5) 외부소속 가입자</h5>
            <ul>
              <li>- 필수항목 : 이름, 이메일, 비밀번호, 소속, 생년월일, 태그</li>
              <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
            </ul>
          </li>
        </ul>
        <br />
        <h5>② 강의등록 처리항목</h5>
        <ul>
          <li>
            <ul>
              <li>- 필수항목 : 제목, 강의 소개, 영상, 태그</li>
              <li>- 선택항목 : 썸네일, 공개상태, 파일첨부</li>
            </ul>
          </li>
        </ul>
        <br />
        <h3>제2조 (개인정보의 처리 목적)</h3>
        <p>
          사용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지
          않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.
        </p>
        <ul>
          <li>- 강의 관리 : 강의 제공자의 기본 정보 확인 등</li>
          <li>
            - 강의 수강관리 : 강의 수강 관리를 위한 본인 확인 및 수강 명단 열람
            등
          </li>
        </ul>
        <br />
        <h3>제3조 (개인정보의 처리 및 보유기간에 관한 사항)</h3>
        <p>
          세빛은 위 개인정보의 처리목적이 달성되거나 사용자가 개인정보 처리 동의
          철회의사를 표시한 후 5일 이내까지 사용자의 개인정보를 처리 및
          보유합니다. 다만, 다음을 포함하여 관련 법령에서 보유기간을 정한 경우
          해당 법령에 따라 보유합니다.
        </p>
        <ul>
          <li>
            - 신용정보의 수집/처리 및 이용 등에 관한 정보: 3년 (신용정보의 이용
            및 보호에 관한 법률)
          </li>
          <li>
            - 홈페이지 회원정보의 경우 회원탈퇴 시까지 보유하고 해지 후에는
            DB에서 삭제하고 보유하지 않습니다. 홈페이지 회원정보의 경우 회원탈퇴
            시 탈퇴여부를 확인하기 위해 ID만을 탈퇴사유와 탈퇴일과 함께 1년을
            보유하고 1년 후 지체없이 파기를 진행하며, 그 이외의 정보는 DB에서
            삭제하고 보유하지 않습니다. 또한, 1년 이상 사용 이력이 없는 회원은
            암호화하여 기존 회원과 별도로 1년을 보유하며 그 후 지체없이 파기를
            진행합니다. 개인정보가 제3자에게 제공된 경우에는 제3자에게
            파기하도록 지시합니다. 단, 다음 각 호의 경우에는 각 호에 명시한 기간
            동안 개인정보를 보유합니다.
          </li>
          <li>
            ① 보유기간을 회원에게 미리 고지하고 그 보유기간이 경과하지 아니한
            경우와 개별적으로 회원의 동의를 받을 경우에 약속한 보유 기간 동안
            개인정보를 보유합니다.
          </li>
          <li>
            ② 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의
            규정에 의해 보존할 필요성이 있는 경우에 개인정보를 보유합니다.
          </li>
        </ul>
        <br />
        <h3>제4조 (개인정보의 제3자 제공에 관한 사항)</h3>
        <br />
        ① 세빛은 개인정보를 제2조(개인정보의 처리 목적)에서 고지한 범위 내에서
        사용하며, 동 범위를 넘어서 이용하거나 타인 또는 타기업, 기관에 제공하지
        않습니다. 다만, 정보주체의 동의가 있거나 다음과 같은 사례에 해당하는
        경우에는 예외로 합니다.
        <br />
        1) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
        알아볼 수 없는 형태로 가공하여 연구단체, 설문조사, 리서치기관 등 제
        3자에게 제공하는 경우
        <br />
        2) 개인정보 보호법, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의
        이용 및 보호에 관한 법률, 의료법, 의료급여법, 국민건강보험법, 통계법,
        소비자보호법, 형사소송법, 민사소송법 등 법률에 특별한 규정이 있는 경우
        <br />
        3) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한
        경우(&apos;법률에 특별한 규정이 있는 경우&apos;로 행정목적이나
        수사목적으로 행정관청이나 수사기관이 요구할 경우라도 법률에 규정된 바에
        따라 영장 또는 수사 기관장의 직인이 날인된 서면에 의한 경우 등 적법한
        절차에 따라 제공합니다)
        <br />
        4) 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나
        주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
        제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
        <br />
        ② 개인정보를 동의 받은 목적 외의 용도로 제공할 경우 사전에 전화 및 서면
        등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 정보주체가 동의하지
        않는 경우에는 제공단체 및 기관에 제공하지 않습니다. ③ 정보주체는
        개인정보의 제3자 제공에 대하여 동의를 하지 않거나 언제든지 철회할 수
        있습니다.
        <br />
        <br />
        <h3>제5조 (개인정보처리의 위탁에 관한 사항)</h3>
        <br />
        세빛은 개인정보 처리 등 특정 업무를 [별첨1]과 같이 위탁하고 있으며, 관련
        법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한
        사항을 공개하고 있습니다. 위탁기관의 개인정보 보유 및 이용기간은
        위탁계약 종료 시까지 이며, 다만 관련 법령에서 보유기간을 정한 경우 해당
        법령에 따릅니다. 위탁하는 업무의 내용과 수탁자는 [별첨1]과 같습니다.
        <br />
        <br />
        <h3>
          제6조 (정보주체 및 법정대리인의 권리⋅의무 및 그 행사방법에 관한 사항)
        </h3>
        <br />
        ① 사용자의 개인정보에 대한 열람, 정정 및 삭제 요구 시 성실하게 응대하며,
        해당 개인정보에 오류가 있다고 판명되는 등 정정/삭제의 필요가 있다고
        인정되는 경우에는 지체 없이 처리합니다.
        <br />
        [개인정보의 열람]
        <br />
        홈페이지(https://yumc.ac/)에 로그인하여 회원정보의 열람이 가능합니다.
        <br />
        [개인정보의 정정/삭제]
        <br />
        홈페이지 (https://yumc.ac/)에 로그인 하신 후 「회원정보수정」에서
        개인정보 수정이 가능합니다. 삭제를 원하실 경우 「회원탈퇴」를 하시면
        회원정보가 삭제됩니다.
        <br />
        ② 사용자의 개인정보에 대한 열람, 정정 및 삭제를 요구하는 경우, 사용자의
        신분을 나타내는 신분증(주민등록증, 여권, 운전면허증 그 밖에 공공기관에서
        발행한 본인임을 확인할 수 있는 신분증)을 제시받아 본인 여부를
        확인합니다.
        <br />
        ③ 대리인이 방문하여 열람 · 증명을 요구하는 경우에는 대리관계를 나타내는
        위임장 및 동의서와 본인의 신분증 사본, 대리인의 신분증 등을 확인하여
        진정한 대리인인지 여부를 확인합니다.
        <br />
        ④ 세빛은 개인정보의 전부 또는 일부에 대하여 열람 · 증명 또는 정정을
        거절할 정당한 이유가 있는 경우에는 사용자에게 이를 통지하고 그 이유를
        설명합니다.
        <br />
        <br />
        <h3>
          제7조 (개인정보 자동 수집장치의 설치, 운영 및 그 거부에 관한 사항)
        </h3>
        <br />
        1. 세빛은 보다 적절하고 유용한 서비스를 제공하기 위하여 사용자의 정보를
        수시로 저장하고 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키란 세빛
        웹사이트를 운영하는데 이용되는 서버가 사용자의 컴퓨터로 전송하는 아주
        작은 텍스트 파일로서 사용자의 컴퓨터 하드디스크에 저장됩니다. 사용자는
        쿠키의 사용여부에 대하여 선택하실 수 있습니다. 단, 쿠키를 사용하지 않아
        생기는 서비스 사용의 문제 및 제한은 세빛이 책임지지 않습니다. <br />
        <br />
        2. 쿠키 설정 거부 방법
        <br />
        사용자는 사용하시는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를
        허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을
        거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부
        서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다. <br />
        - 쿠키의 사용목적
        <br />
        이용자들이 방문한 의료원의 웹 사이트들에 대한 방문 및 이용형태, 보안접속
        여부 등을 파악하여 최적화된 맞춤형 서비스를 제공하기 위해 사용하며, 이
        외의 목적으로 이용하지 않습니다.
        <br />
        - 쿠키 설정 거부 방법
        <br />
        사용자는 쿠키 설정에 대한 선택권을 가지고 있으며 아래와 같은 방법으로
        모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든
        쿠키의 저장을 거부할 수 있습니다.
        <br />
        설정방법의 예 (Chrome 기준): <br />
        웹 브라우저 우측의 “설정&gt;화면하단의 고급 설정&gt;개인정보의 콘텐츠
        설정 버튼&gt;쿠키”
        <br />
        단, 사용자가 쿠키 설정을 거부하였을 경우 일부 서비스 이용에 어려움이
        있을 수 있습니다.
        <br />
        <br />
        <h3>제8조 (동의철회 및 개인정보 파기에 관한 사항)</h3>
        <br />
        ① 사용자는 개인정보의 수집⋅이용 및 제공에 대해 동의하신 내용을 언제든지
        철회하실 수 있습니다. 철회방법은 개인정보보호책임자 또는 관리부서에
        서면, 전화 등으로 연락하시면 본인확인을 거쳐 개인정보 동의철회 등 필요한
        조치를 합니다.
        <br />
        ② 세빛은 수집된 사용자의 개인정보를 제3조의 “개인정보의 처리 및
        보유기간”동안 보유합니다. 세빛은 보유기간의 경과, 처리 목적 달성 등 그
        개인정보가 불필요하게 되었을 때에는 정당한 사유가 없는 한 개인정보가
        불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기하고,
        개인정보가 제3자에게 제공된 경우에는 제3자에게 파기하도록 지시합니다.
        <br />
        ③ 세빛은 개인정보를 파기할 때에는, 전자적 파일 형태인 경우 복원이
        불가능한 방법으로 영구삭제하고, 기타 서면 등 기록매체인 경우 파쇄 또는
        소각합니다.
        <br />
        <br />
        <h3>제9조 (개인정보보호책임자에 관한 사항)</h3>
        <br />
        세빛은 개인정보를 보호하기 위해 최선을 다해 노력하기 위하여
        개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이
        있으시면 아래의 개인정보보호책임자 또는 관리부서에 통지해 주십시오.
        지적하신 문제를 확인하여 즉시 시정할 수 있도록 최선을 다하겠습니다.
        <br />
        <br />
        개인정보보호책임자
        <br />
        • 이름 : 임준석
        <br />
        • 소속 : 연세대학교 의료원 디지털헬스실
        <br />
        • 직위 : 디지털헬스실장
        <br />
        • 전화 : 02-2228-1100
        <br />
        • 이메일 : privacy@yuhs.ac
        <br />
        <br />
        <h3>제10조 (개인정보의 안전성 확보조치에 관한 사항)</h3>
        <br />
        세빛은 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성
        확보를 위하여 다음과 같은 기술적⋅관리적 대책을 강구하고 있습니다.
        <br />
        ① 기술적⋅물리적 대책
        <br />
        1) 개인정보는 비밀번호에 의해 보호되며, 개인정보 파일을 안전하게 저장 및
        전송할 수 있도록 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여
        중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
        <br />
        2) 백신 프로그램 등 보안프로그램을 설치하여 컴퓨터 바이러스에 의한
        피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 자동으로
        업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를
        제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
        <br />
        3) 해킹 등에 의해 개인정보가 유출되는 것을 방지하기 위하여, 외부로부터
        접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를 이용하고
        있으며, 아울러 침입탐지시스템을 설치하여 24시간 침입을 감시하고
        있습니다.
        <br />
        4) 개인정보처리자가 정보통신망을 통해 외부에서 개인정보처리시스템에
        접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단을
        적용하고 있습니다.
        <br />
        ② 관리적 대책
        <br />
        1) 세빛은 개인정보의 안전한 처리를 위한 내부 관리계획 및 개인정보에 대한
        관리와 접근에 필요한 절차를 마련하여 교직원이 이를 숙지하고 준수하도록
        하고 있으며 준수 여부를 주기적으로 점검하고 있습니다. 2) 세빛은 정보를
        처리할 수 있는 자를 최소한으로 제한하고 접근 권한을 관리하며, 교육을
        통하여 법규 및 정책을 준수할 수 있도록 합니다. 개인정보를 처리하는 자는
        다음과 같습니다.
        <br />
        - 정보주체를 직⋅간접적으로 상대하여 업무를 처리하는 자<br />
        - 개인정보보호책임자 및 개인정보처리담당자 등 개인정보 관리 및
        개인정보보호 업무를 담당하는 자<br />
        - 기타 업무상 개인정보의 접근이 불가피한 자<br />
        3) 세빛은 개인정보 침해사고 발생에 대응하기 위한 접속기록의 보관 및
        위조⋅변조 방지를 위한 조치를 시행합니다.
        <br />
        4) 세빛에서 관리하는 개인정보의 처리를 다른 공공기관 또는 다른
        전문기관에 위탁하는 경우, 그 업무에 종사하거나 종사하였던 자에 대해
        직무상 알게 된 개인정보를 누설 또는 권한 없이 처리하거나 타인의 이용에
        제공하는 등 부당한 목적을 위하여 사용하지 않도록 위탁부서에서 아래에
        대한 사항에 관하여 필요한 제한이나 절차를 서면으로 정하고 수탁기관으로
        하여금 준수하도록 하고 있으며, 실태점검도 실시하고 있습니다.
        <br />
        - 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항
        <br />
        - 개인정보의 기술적⋅관리적 보호조치에 관한 사항
        <br />
        - 위탁업무의 목적 및 범위
        <br />
        - 재위탁 제한에 관한 사항
        <br />
        - 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항
        <br />
        - 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에
        관한 사항
        <br />
        - 수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등 책임에 관한
        사항
        <br />
        <br />
        <h3>제11조 (정보주체의 권익침해에 대한 구제방법)</h3>
        <br />
        세빛의 개인정보보호책임자 또는 관리부서에 대한 신고, 상담 외에,
        개인정보침해에 대한 신고, 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        한국인터넷진흥원(개인정보침해신고센터)
        <br />
        - 홈페이지 : http://privacy.kisa.or.kr
        <br />
        - 전화번호 : (국번없이) 118
        <br />
        경찰청 사이버안전국
        <br />
        - 홈페이지 : http://ecrm.police.go.kr
        <br />
        - 전화번호 : (국번없이) 182
        <br />
        대검찰청 사이버 수사과
        <br />
        - 홈페이지 : http://www.spo.go.kr
        <br />
        - 전화번호 : (국번없이) 1301
        <br />
        개인정보 분쟁조정위원회(개인정보보호위원회 운영)
        <br />
        - 홈페이지 : http://www.kopico.go.kr
        <br />
        - 전화번호 : 1833-6972
        <br />
        <h3>제12조 (개인정보처리방침의 변경에 관한 사항)</h3>
        <br />
        현 개인정보처리방침은 정부의 정책 또는 보안기술의 변경 등에 따라 수시로
        변경될 수 있으며, 내용의 추가, 삭제 및 수정이 있을 경우 개정 최소 10일
        전부터 홈페이지 &apos;공지&apos; 란 및 기타 정보주체가 쉽게 확인 가능한
        장소에 공지를 통해 고지할 것입니다.
        <br />
        - 공고일자 : 2023년 3월 17일
        <br />
        - 시행일자 : 2023년 3월 17일
        <br />
        <br />
        [별첨1]개인정보의 처리 위탁에 관한 사항
        <br />
        <br />
        <table>
          <tr>
            <td>
              <b>수탁자</b>
            </td>
            <td>
              <b>위탁하는 업무의 내용</b>
            </td>
            <td>
              <b>개인정보 보유기간</b>
            </td>
          </tr>
          <tr>
            <td>㈜온오프믹스</td>
            <td>교육 플랫폼 홈페이지 개발 및 유지보수</td>
            <td>위탁계약 종료시까지</td>
          </tr>
        </table>
      </div>
    </section>
  );
};

export default MPrivacyTerms;
