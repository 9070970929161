import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CInputGroup,
  CNav,
  CNavItem,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import {
  setPopupMode,
  showDetailPopupF,
} from 'module/lecture-add-detail-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import PlayListDetailPopup from 'views/desktop/common/popup/PlayListDetailPopup';

import { isPublicPlaylist } from '../../../../api/admin/playlist';
import { getUserPlaylist } from '../../../../api/admin/user';

type StudentPlaylist = {
  id: number;
  title: string;
  courseCount: number;
  sharedCount: number;
  createdAt: Array<number>;
  shareable: boolean;
};

const AdminOutsiderPlaylistPage = () => {
  const userId = Number(useParams().id);
  const showDetailPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddDetailPopup.showDetailPopup
  );
  const [keyword, setKeyword] = useState<string>('');
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [detectChange, setDetectChange] = useState<boolean>(true);
  const [lists, setLists] = useState<Array<StudentPlaylist>>([]);
  const dispatch = useAppDispatch();

  const getList = (page: any) => {
    const params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 10,
    };
    getUserPlaylist(userId, params).then((res: any) => {
      setLists(res.data.content);
      setTotalPage(res.data.totalPages);
      setCurrentPage(page.selected);
    });
  };

  useEffect(() => {
    getList({ selected: 0 });
  }, [detectChange]);

  const changePublic = (id: number, isShareable: boolean) => {
    isPublicPlaylist(id).then((res: any) => {
      if (isShareable) {
        alert('플레이리스트가 공유해제 되었습니다.');
      } else {
        alert('플레이리스트가 공유 되었습니다.');
      }
      getList({ selected: currentPage });
    });
  };

  const handleOnKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange);
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="student_playlist">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">외부인 회원관리</h1>
          </CCardHeader>

          <CCardBody>
            <CNav variant="tabs" className="mb-3">
              <CNavItem active>
                <Link
                  to={`/admin/user/outsider/edit/${userId}`}
                  className="nav-link"
                >
                  회원정보
                </Link>
              </CNavItem>
              <CNavItem>
                <Link
                  to={`/admin/user/outsider/playlist/${userId}`}
                  className="nav-link active"
                >
                  내 플레이리스트
                </Link>
              </CNavItem>
              <CNavItem>
                <Link
                  to={`/admin/user/outsider/subscribe/${userId}`}
                  className="nav-link"
                >
                  구독 플레이리스트
                </Link>
              </CNavItem>
            </CNav>

            <div className="card-search mb-3">
              <div className="d-flex justify-content-end">
                <CInputGroup className="w-25">
                  <CFormInput
                    type="text"
                    placeholder="플레이리스트명 검색"
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={handleOnKeyPress}
                  />
                  <CButton
                    type="button"
                    color="primary"
                    onClick={() => setDetectChange(!detectChange)}
                  >
                    검색
                  </CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{ width: '10%' }} scope="col">
                    번호
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '25%' }} scope="col">
                    플레이리스트 명
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '10%' }} scope="col">
                    강의 개수
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '10%' }} scope="col">
                    공유된 수
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '20%' }} scope="col">
                    생성일
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '25%' }} scope="col">
                    관리
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? (
                  lists.map((item) => (
                    <CTableRow
                      key={item.id}
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                    >
                      <CTableDataCell>{item.id}</CTableDataCell>
                      <CTableDataCell
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          dispatch(
                            showDetailPopupF({
                              showDetailPopup: true,
                              playlistId: item.id,
                            })
                          )
                        }
                      >
                        {item.title}
                      </CTableDataCell>
                      <CTableDataCell>{item.courseCount}</CTableDataCell>
                      <CTableDataCell>{item.sharedCount}</CTableDataCell>
                      <CTableDataCell>
                        {item.createdAt[0]}
                        {item.createdAt[1]}
                        {item.createdAt[2]}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          className="btn btn-info text-white me-3"
                          onClick={() => {
                            dispatch(
                              showDetailPopupF({
                                showDetailPopup: true,
                                playlistId: item.id,
                              })
                            );
                            dispatch(setPopupMode('edit'));
                          }}
                        >
                          수정
                        </CButton>
                        <CButton
                          color={item.shareable ? 'success' : 'warning'}
                          className="text-white me-3"
                          onClick={() => changePublic(item.id, item.shareable)}
                        >
                          {item.shareable ? '공유' : '공유해제'}
                        </CButton>
                        <CButton color="danger" className="text-white">
                          삭제
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow style={{ borderBottom: '1px solid #d8d8d8' }}>
                    <CTableDataCell colSpan={6}>
                      등록된 플레이리스트가 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 && (
              <ReactPaginate
                breakLabel="..."
                containerClassName="user-paginate mb-3"
                nextLabel="다음"
                onPageChange={getList}
                pageCount={totalPage}
                pageClassName="user-paginate-item"
                previousLabel="이전"
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      {showDetailPopup && <PlayListDetailPopup />}
    </CRow>
  );
};

export default AdminOutsiderPlaylistPage;
