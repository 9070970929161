import { onSilentRefresh } from 'api/user/login';
import { getCookie } from 'utils/cookie';

import Send from './send';

const JWT_EXPIRY_TIME = 3600 * 3000;

export const isLogin = () => {
  return !!getCookie('login_token');
};

export const onLoginSuccess = (response: any) => {
  const accessToken = response.data.yonsei_access_token;

  Send.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  setTimeout(onSilentRefresh, JWT_EXPIRY_TIME - 60000);
};
