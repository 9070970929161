import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  category: 0,
};

type searchCategoryType = {
  category: number;
};

const searchCategory = createSlice({
  name: 'searchCategory',
  initialState: initialState,
  reducers: {
    setCategory(state: searchCategoryType, action: PayloadAction<number>) {
      state.category = action.payload;
    },
  },
});

export const { setCategory } = searchCategory.actions;
export default searchCategory.reducer;
