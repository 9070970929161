import 'views/mobile/common/layout/m-aside.scoped.sass';

import { logout } from 'api/user/login';
import { deleteAuth } from 'module/auth';
import { setIsOpen } from 'module/mobile/aside';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { isLogin } from 'utils/auth';
import { removeCookie } from 'utils/cookie';
import { User } from 'utils/type';

type Notice = {
  id: number;
  title: string;
  createdAt: Array<number>;
};

const MAside = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickMenu = () => {
    dispatch(setIsOpen(false));
  };

  const userData: User = useAppSelector(
    (state: RootState) => state.authentication.user
  );
  const isLoginCheck: boolean = isLogin();

  const clickLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logout().then((res: any) => {
        alert('로그아웃 되었습니다.');
        dispatch(deleteAuth());
        removeCookie('login_token', { path: '/' });
        navigate('/');
      });
    }
  };

  const getUserRoleInKorea = () => {
    switch (userData.role) {
      case 'ROLE_ADMIN':
        return '관리자';
      case 'ROLE_PROFESSOR':
        return '교수';
      case 'ROLE_STUDENT':
        return '학생';
      case 'ROLE_INSIDER':
        return '내부인';
      case 'ROLE_DISPATCH_OUTSIDER':
        return '파견외부인';
      case 'ROLE_OUTSIDER':
        return '외부인';
    }
  };

  return (
    <>
      <aside className="m-aside">
        <div className="aside-header">
          <button
            type="button"
            className="btn-close-aside"
            onClick={onClickMenu}
          ></button>
        </div>
        <div className="aside-all-menu">
          <ul>
            <li>
              <Link to="/lecture/list" onClick={onClickMenu}>
                <span className="label">
                  <b>전체 강의</b>
                </span>
                <span className="value">
                  <em>{userData.wholeCourseCount}</em>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/playlist/public" onClick={onClickMenu}>
                <span className="label">
                  <b>전체 공유 플레이리스트</b>
                </span>
                <span className="value">
                  <em>{userData.wholePlaylistCount}</em>
                </span>
              </Link>
            </li>
          </ul>
        </div>
        {(userData.role === 'ROLE_PROFESSOR' ||
          userData.role === 'ROLE_ADMIN') && (
          <div className="aside-my-lecture">
            <h3>
              <b>내 강의 관리</b>
            </h3>
            <ul>
              <li>
                <Link to="/lecture/management/list" onClick={onClickMenu}>
                  <span>등록한 강의</span>
                  <span>{userData.myCourseCount}</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className="aside-playlist">
          <h3>
            <b>플레이 리스트 관리</b>
          </h3>
          <ul>
            <li>
              <Link to="/playlist/my" onClick={onClickMenu}>
                <span>내 플레이리스트</span>
                <span>{userData.myPlaylistCount}</span>
              </Link>
            </li>
            <li>
              <Link to="/playlist/subscribe" onClick={onClickMenu}>
                <span>구독 플레이리스트</span>
                <span>{userData.subPlaylistCount}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="aside-handler">
          <h2>
            <b>
              {getUserRoleInKorea()} {userData.name}
            </b>{' '}
            님
          </h2>
          <ul>
            <li>
              <Link to="/user/edit" onClick={onClickMenu}>
                회원정보
              </Link>
            </li>
            <li>
              <Link to="/notice" onClick={onClickMenu}>
                공지사항
              </Link>
            </li>
            <li>
              {isLoginCheck ? (
                <button className="btn-logout" onClick={clickLogout}>
                  로그아웃
                </button>
              ) : (
                <Link to="/" onClick={onClickMenu}>
                  로그인
                </Link>
              )}
            </li>
          </ul>
        </div>
      </aside>
      <div className="dim"></div>
    </>
  );
};

export default MAside;
