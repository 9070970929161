import React from 'react';
import { Outlet } from 'react-router-dom';
import MSimpleHeader from 'views/mobile/common/layout/MSimpleHeader';

const MAppFreeLayout = () => (
  <>
    <MSimpleHeader />
    <main id="app_free_main">
      <Outlet />
    </main>
  </>
);

export default MAppFreeLayout;
