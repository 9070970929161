import { getClassCategory } from 'api/common/category';
import { onSilentRefresh } from 'api/user/login';
import { deleteAuth } from 'module/auth';
import { setCategory } from 'module/class-category';
import { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes from 'routes/Routes';
import { onLoginSuccess } from 'utils/auth';
import { removeCookie } from 'utils/cookie';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    onSilentRefresh()
      .then((res: any) => {
        onLoginSuccess(res);
        // getUserData().then((res: any) => {
        //   dispatch(setAuth(res.data));
        // });
      })
      .catch((err: any) => {
        if (err.response.status === 401) {
          alert('로그인이 만료되었습니다.');
          dispatch(deleteAuth());
          removeCookie('login_token', { path: '/' });
          navigate('/');
        }
      });

    getClassCategory()
      .then((res: any) => {
        dispatch(setCategory(res.data));
      })
      .catch(() => {
        dispatch(setCategory([]));
      });
  }, [dispatch, pathname]);

  return (
    <>
      <Routes />
    </>
  );
}

export default withCookies(App);
