import { RootState, useAppSelector } from 'module/Module';
import React from 'react';
import { Outlet } from 'react-router-dom';
import MAside from 'views/mobile/common/layout/MAside';
import MFooter from 'views/mobile/common/layout/MFooter';
import MHeader from 'views/mobile/common/layout/MHeader';

const MAppLayout = () => {
  const isOpen: boolean = useAppSelector(
    (state: RootState) => state.asideModule.isOpen
  );

  return (
    <>
      <MHeader />
      {isOpen && <MAside />}
      <main id="app_main">
        <Outlet />
      </main>
      <MFooter />
    </>
  );
};

export default MAppLayout;
