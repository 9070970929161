import 'views/desktop/main/style/main-notice.scoped.sass';

import { noticeList } from 'api/notice/notice';
import noticeImage from 'assets/images/global/icon_notice.svg';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

type Notice = {
  id: number;
  title: string;
  createdAt: Array<number>;
};

const MainNotice = () => {
  const [lists, setList] = useState<Array<Notice>>([]);

  useEffect(() => {
    const params = {
      page: 0,
      size: 3,
      isRecent: true,
    };
    noticeList(params).then((res: any) => {
      const newItems = res.data.content;
      setList(newItems);
    });
  }, []);

  return (
    <div className="simple-notice">
      <div className="u-container">
        <div className="notice-wrap">
          <div className="title">
            <img src={noticeImage} alt="공지사항" />
            <b>공지사항</b>
          </div>
          <Swiper
            centeredSlides={true}
            modules={[Autoplay]}
            loop={true}
            direction={'vertical'}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
          >
            {lists &&
              lists.map((list) => (
                <SwiperSlide key={list.id}>
                  <Link to={`/notice/${list.id}`}>{list.title}</Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        {lists && (
          <Link to="/notice" className="btn-more">
            더보기 <b>+</b>
          </Link>
        )}
      </div>
    </div>
  );
};

export default MainNotice;
