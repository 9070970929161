import 'views/mobile/common/layout/m-header.scoped.sass';
import 'swiper/css';

import { getAlarm } from 'api/common/alarm';
import menu from 'assets/images/global/icon_menu.svg';
import logo from 'assets/images/global/logo.svg';
import { ChangeLecture, setLectureSearchKeyword } from 'module/lecturelist';
import { setIsOpen } from 'module/mobile/aside';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { User } from 'utils/type';

const MHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState<string | null>('');
  const lectureChange: boolean = useAppSelector(
    (state: RootState) => state.getLectureList.lectureChange
  );
  const userData: User = useAppSelector(
    (state: RootState) => state.authentication.user
  );
  const [alarmList, setAlarmList] = useState<boolean>(false);

  const onClickMenu = () => {
    dispatch(setIsOpen(true));
  };

  const getUserRoleInKorea = () => {
    switch (userData.role) {
      case 'ROLE_ADMIN':
        return '관리자';
      case 'ROLE_PROFESSOR':
        return '교수';
      case 'ROLE_STUDENT':
        return '학생';
      case 'ROLE_INSIDER':
        return '내부인';
      case 'ROLE_DISPATCH_OUTSIDER':
        return '파견외부인';
      case 'ROLE_OUTSIDER':
        return '외부인';
    }
  };

  const getAlarmList = () => {
    getAlarm().then((res: any) => {
      if (res.data.length > 0) {
        setAlarmList(true);
      } else {
        setAlarmList(false);
      }
    });
  };

  useEffect(() => {
    getAlarmList();
  }, []);

  const clickSearch = () => {
    dispatch(setLectureSearchKeyword(keyword));
    dispatch(ChangeLecture(!lectureChange));
    navigate(`/search?keyword=${keyword}`);
  };
  const getParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setKeyword(getParams.get('keyword') ? '' : '');
  }, []);

  return (
    <header id="m_header">
      <div className="header-middle">
        <div className="m-container">
          <div className="menu-wrap">
            <img
              src={menu}
              className="btn-dropdown"
              alt="dropdown"
              onClick={onClickMenu}
            />
          </div>
          <div className="logo-wrap">
            <Link to="/main">
              <img src={logo} alt="연세대학교 의과대학" />
            </Link>
          </div>
          <div className="user-wrap">
            <p onClick={onClickMenu}>
              <b>
                {getUserRoleInKorea() === '관리자'
                  ? '관리자'
                  : getUserRoleInKorea()}{' '}
                {userData.name}
              </b>{' '}
              님
            </p>
            {(userData.role === 'ROLE_PROFESSOR' ||
              userData.role === 'ROLE_ADMIN') &&
              alarmList && (
                <Link to="/alarm" className="btn-alarm">
                  N
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="m-container">
          <div className="search-wrap">
            <div className="select-wrap">
              <input
                type="text"
                className="search"
                placeholder="검색어를 입력해 주세요."
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') clickSearch();
                }}
              />
              <button className="btn-search" onClick={clickSearch}></button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MHeader;
