// @ts-ignore
import Send from 'utils/send.ts';

export const getNewUserData = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/users/new',
    params: params,
  });
};

export const acceptUser = (userId: number) => {
  return Send({
    method: 'post',
    url: `/api/admin/users/new/${userId}`,
  });
};

export const refuseUser = (userId: number, data: any) => {
  return Send({
    method: 'delete',
    url: `/api/admin/users/new/${userId}`,
    data: data,
  });
};

export const getUsersList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/users',
    params: params,
  });
};

export const getUserDetail = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/users/${id}`,
    params: params,
  });
};

export const postUser = (id: number, data: any) => {
  return Send({
    method: 'post',
    url: `/api/admin/users/${id}`,
    data: data,
  });
};

export const getProfessorCourse = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/professors/${id}/courses`,
    params: params,
  });
};

export const getProfessorPlaylist = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/professors/${id}/playlists`,
    params: params,
  });
};

export const getUserPlaylist = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/users/${id}/playlists`,
    params: params,
  });
};

export const getUserSubPlaylist = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/users/${id}/subscribed-playlists`,
    params: params,
  });
};

export const adminSecession = (id: number) => {
  return Send({
    method: 'delete',
    url: `/api/admin/users/${id}`,
  });
};
