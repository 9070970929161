import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'utils/type';

type Auth = {
  user: User;
  isLogin: boolean;
  isCheck: boolean;
  isAdminCheck: boolean;
  loading: boolean;
  adminLoading: boolean;
};

const initialState: Auth = {
  user: {
    id: 0,
    name: '',
    email: '',
    role: '',
    myPlaylistCount: 0,
    subPlaylistCount: 0,
    myCourseCount: 0,
    wholePlaylistCount: 0,
    wholeCourseCount: 0,
  },
  isCheck: false,
  isAdminCheck: false,
  isLogin: false,
  loading: true,
  adminLoading: true,
};

const authentication = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setAuth(state: Auth, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isLogin = true;
      state.loading = false;
    },
    deleteAuth(state: Auth) {
      state.user = {
        name: '',
        id: 0,
        email: '',
        role: '',
        myCourseCount: 0,
        myPlaylistCount: 0,
        subPlaylistCount: 0,
        wholePlaylistCount: 0,
        wholeCourseCount: 0,
      };
      state.isLogin = false;
      state.loading = false;
    },
    setCheck(state: Auth, action: PayloadAction<boolean>) {
      state.isCheck = action.payload;
    },
    setAdminCheck(state: Auth, action: PayloadAction<boolean>) {
      state.isAdminCheck = action.payload;
      state.adminLoading = false;
    },
  },
});

export const { setAuth, deleteAuth, setCheck, setAdminCheck } =
  authentication.actions;
export default authentication.reducer;
