import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ClassCategory = {
  id: number;
  name: string;
  category: string;
};

const classCategory = createSlice({
  name: 'classCategory',
  initialState: [] as Array<ClassCategory>,
  reducers: {
    setCategory(
      state: Array<ClassCategory>,
      action: PayloadAction<Array<ClassCategory>>
    ) {
      return action.payload;
    },
  },
});

export const { setCategory } = classCategory.actions;
export default classCategory.reducer;
