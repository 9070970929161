import 'views/desktop/user/style/register.sass';

// api
import { editUser, getUserData } from 'api/user/edit';
// style
import Default_thumbnail from 'assets/images/global/profile_default.svg';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
// module
import {
  dayOptions,
  monthOptions,
  selectStyles,
  yearOptions,
} from 'utils/select-option';

interface Tags {
  name: string;
}

type FormData = {
  profileImage: Array<object>;
  name: string;
  email: string;
  password: string;
  passwordCheck: string;
  division: string;
  year: number;
  month: number;
  day: number;
  birthday: string;
  tags: Array<Tags>;
  tagCheck: string;
  subEmail: string;
  phone: number;
};

const EditStudentPage = () => {
  const navigate = useNavigate();

  const [imageChanged, setImageChanged] = useState<boolean>(false);

  const getData = () => {
    getUserData().then((res: any) => {
      setValue('name', res.data.name);
      setValue('email', res.data.email);
      setValue('division', res.data.division);
      setValue('subEmail', res.data.subEmail);
      setValue('phone', res.data.phone);
      setValue('year', res.data.birthday[0]);
      setValue('month', res.data.birthday[1]);
      setValue('day', res.data.birthday[2]);
      setValue(
        'birthday',
        `${res.data.birthday[0]}-${res.data.birthday[1]}-${res.data.birthday[2]}`
      );
      const newObj: Array<Tags> = [];
      res.data.tags.forEach((x: any, i: number) => {
        newObj.push({ name: x });
      });
      if (res.data.tags.length < 5) {
        for (let i = 0; i < 5 - res.data.tags.length; i++) {
          newObj.push({ name: '' });
        }
      }
      tagReplace(newObj);
      validateTags();
      setImgSrc(res.data.file.url);
    });
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    getData();
  }, []);

  const postEdit = async () => {
    const tagsArray: Array<string> = [];
    watch().tags?.forEach((tag) => {
      tagsArray.push(tag.name);
    });
    // @ts-ignore
    const formData = new FormData(document.getElementById('edit_student'));
    formData.append('imageChanged', String(imageChanged));
    for (const a of tagsArray) {
      formData.append('tags[]', a);
    }
    editUser(formData).then((res: any) => {
      alert('회원정보 수정이 완료되었습니다.');
      window.location.reload();
    });
  };

  const [imgSrc, setImgSrc] = useState<string>(Default_thumbnail);

  const onChangeThumbnail = (evt: any) => {
    if (evt.target.files.length) {
      const imgTarget = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e: any) {
        setImgSrc(e.target.result);
      };
    } else {
      setImgSrc(Default_thumbnail);
    }
    setImageChanged(true);
  };

  const deleteThumbnail = () => {
    setImgSrc(Default_thumbnail);
    setValue('profileImage', []);
    setImageChanged(true);
  };

  const {
    fields: tagField,
    append: tagAppend,
    replace: tagReplace,
  } = useFieldArray({
    control,
    name: 'tags',
  });

  const validateTags = () => {
    let flag: boolean = false;
    watch().tags.forEach((tag) => {
      if (tag.name !== '') flag = true;
    });

    if (flag) setValue('tagCheck', 'Y');
    else setValue('tagCheck', 'N');
    trigger('tagCheck');
  };

  return (
    <div className="edit-outsider">
      <form id="edit_student" onSubmit={handleSubmit(postEdit)}>
        <div className="box-container">
          <Link to="/secession" className="link-secession">
            회원 탈퇴
          </Link>
          <div className="box-wrap">
            <div className="title-wrap">
              <h1>회원정보 수정</h1>
              <span>
                <span className="require">*</span> 필수 입력 사항
              </span>
            </div>
            <div className="content-wrap">
              <article className="form-wrap">
                <div className="input-wrap profile-wrap">
                  <p className="label">
                    프로필 사진
                    <br />
                    size(80 x 80)
                  </p>
                  <div className="profile-box">
                    <input
                      type="file"
                      id="profileImage"
                      {...register('profileImage', {
                        required: false,
                        onChange: onChangeThumbnail,
                      })}
                    />
                    <div className="btn-wrap">
                      <label htmlFor="profileImage">사진 선택</label>
                      {watch().profileImage?.length > 0 && (
                        <button
                          type="button"
                          className="btn-delete-profile"
                          onClick={deleteThumbnail}
                        >
                          삭제하기
                        </button>
                      )}
                    </div>
                    <img
                      src={imgSrc}
                      className="profile-image"
                      alt="프로필사진"
                    />
                  </div>
                </div>
                <div className="input-wrap name-wrap">
                  <label htmlFor="name">
                    <span className="require">*</span> 이름
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="input-name"
                    aria-invalid={errors.name ? 'true' : 'false'}
                    {...register('name', {
                      required: '이름을 입력해 주세요.',
                      minLength: {
                        value: 2,
                        message: '2자 이상 입력해 주세요.',
                      },
                    })}
                  />
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.name && (
                      <p className="error-message" role="alert">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap email-wrap">
                  <label htmlFor="email">
                    <span className="require">*</span> 이메일
                  </label>
                  <p>{watch().email}</p>
                </div>
                <div className="input-wrap password-wrap">
                  <label htmlFor="password">
                    <span className="require">*</span>새로운 <br />
                    비밀번호
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="input-password"
                    placeholder="새로운 비밀번호 (숫자, 소/대문자, 특수문자 조합 최소 8자)"
                    aria-invalid={errors.password ? 'true' : 'false'}
                    {...register('password', {
                      minLength: {
                        value: 8,
                        message: '8자 이상 입력해 주세요.',
                      },
                      maxLength: {
                        value: 20,
                        message: '20자 이하 입력해 주세요.',
                      },
                      pattern: {
                        value: /[A-Za-z`~!@#$%^&*()-_=+\\|{};:'",.<>?/]/,
                        message:
                          '영어 소문자/대문자, 특수문자 포함 8자 이상 입력해 주세요.',
                      },
                    })}
                  />
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.password && (
                      <p className="error-message" role="alert">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap password-check-wrap">
                  <label htmlFor="password_check">
                    <span className="require">*</span>새로운 <br />
                    비밀번호 확인
                  </label>
                  <input
                    type="password"
                    id="password_check"
                    className="input-password-check"
                    placeholder="새로운 비밀번호 확인"
                    aria-invalid={errors.passwordCheck ? 'true' : 'false'}
                    {...register('passwordCheck', {
                      validate: (value) =>
                        value === getValues('password') || '일치하지 않습니다.',
                    })}
                  />
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.passwordCheck && (
                      <p className="error-message" role="alert">
                        {errors.passwordCheck.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap division-wrap">
                  <label htmlFor="division">
                    <span className="require">*</span> 소속
                  </label>
                  <input
                    type="text"
                    className="input-division"
                    {...register('division', {
                      required: '소속을 입력해 주세요.',
                    })}
                  />
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.division && (
                      <p className="error-message" role="alert">
                        {errors.division.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap date-wrap">
                  <label htmlFor="birthday">
                    <span className="require">*</span> 생년월일
                  </label>
                  <Controller
                    control={control}
                    name="year"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        options={yearOptions}
                        styles={selectStyles}
                        placeholder="년도"
                        inputId="year"
                        aria-invalid={errors.year ? 'true' : 'false'}
                        ref={ref}
                        value={yearOptions.filter(
                          (option) => option.value === watch().year
                        )}
                        className="input-year-select"
                        classNamePrefix="input-select"
                        onChange={(option: any) => {
                          onChange(option.value);
                          setValue(
                            'birthday',
                            `${watch().year}-${watch().month}-${watch().day}`
                          );
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="month"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        options={monthOptions}
                        styles={selectStyles}
                        aria-invalid={errors.month ? 'true' : 'false'}
                        placeholder="월"
                        inputId="month"
                        value={monthOptions.filter(
                          (option) => option.value === watch().month
                        )}
                        ref={ref}
                        className="input-month-select"
                        classNamePrefix="input-select"
                        onChange={(option: any) => {
                          onChange(option.value);
                          setValue(
                            'birthday',
                            `${watch().year}-${watch().month}-${watch().day}`
                          );
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="day"
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        options={dayOptions}
                        styles={selectStyles}
                        aria-invalid={errors.day ? 'true' : 'false'}
                        placeholder="일"
                        inputId="day"
                        value={dayOptions.filter(
                          (option) => option.value === watch().day
                        )}
                        ref={ref}
                        className="input-day-select"
                        classNamePrefix="input-select"
                        onChange={(option: any) => {
                          onChange(option.value);
                          setValue(
                            'birthday',
                            `${watch().year}-${watch().month}-${watch().day}`
                          );
                        }}
                      />
                    )}
                  />
                  <input
                    type="hidden"
                    {...register('birthday', {
                      required: true,
                    })}
                  />
                </div>
                <p className="input-tip">
                  비밀번호 복구 등에서 본인확인 용도로만 사용됩니다.
                </p>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {(errors.year || errors.month || errors.day) && (
                      <p className="error-message" role="alert">
                        생년월일을 입력해주세요.
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap keyword-wrap">
                  <label htmlFor="keyword">
                    <span className="require">*&nbsp;</span> 태그
                  </label>
                  <div className="keyword-box">
                    <p className="keyword-tip">
                      강의등록 시, 기본으로 사용될 태그를 미리 등록할 수
                      있습니다.
                      <br />
                      ex. 내과, 근골격 등
                    </p>
                    {tagField.map((tag, index) => (
                      <input
                        type="text"
                        id="input_tag"
                        onKeyDown={(e) =>
                          ((e.ctrlKey && e.keyCode === 86) ||
                            e.keyCode === 32) &&
                          e.preventDefault()
                        }
                        key={index}
                        className="input-keyword"
                        placeholder="태그 입력"
                        {...register(`tags.${index}.name`, {
                          onChange: (e) => {
                            const eng_check = /^[a-zA-z]+$/;
                            const kor_eng_check =
                              /^^(?=.*[A-Za-z])(?=.*[ㄱ-ㅎ|가-힣])[ㄱ-ㅎ|가-힣|a-z|A-Z|]*/;
                            if (eng_check.test(e.target.value)) {
                              if (e.target.value.length >= 40) {
                                e.target.value = e.target.value.substr(0, 40);
                              }
                            } else if (kor_eng_check.test(e.target.value)) {
                              if (e.target.value.length >= 30) {
                                e.target.value = e.target.value.substr(0, 30);
                              }
                            } else {
                              if (e.target.value.length >= 20) {
                                e.target.value = e.target.value.substr(0, 20);
                              }
                            }
                            validateTags();
                          },
                        })}
                      />
                    ))}
                    <input
                      type="hidden"
                      {...register('tagCheck', {
                        required: '태그를 입력해 주세요.',
                        pattern: {
                          value: /Y/,
                          message: '태그를 입력해 주세요.',
                        },
                      })}
                    />
                  </div>
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.tagCheck && (
                      <p className="error-message" role="alert">
                        태그를 입력해 주세요.
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap sub-email-wrap">
                  <label htmlFor="subEmail">대체 이메일</label>
                  <input
                    type="text"
                    id="subEmail"
                    className="input-sub-email"
                    placeholder="복구용 이메일 입력"
                    {...register('subEmail', {
                      required: false,
                      pattern: {
                        value:
                          /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                        message: '이메일을 바르게 입력해주세요.',
                      },
                    })}
                  />
                </div>
                <p className="input-tip">
                  비밀번호 복구 등에서 본인확인 용도로만 사용됩니다.
                </p>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.subEmail && (
                      <p className="error-message" role="alert">
                        {errors.subEmail.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="input-wrap phone-wrap">
                  <label htmlFor="phone">전화번호</label>
                  <input
                    type="text"
                    id="phone"
                    className="input-phone"
                    placeholder="숫자만 입력"
                    maxLength={13}
                    {...register('phone', {
                      required: false,
                      onChange: (e) => {
                        setValue(
                          'phone',
                          e.target.value.replace(/[^0-9.]/g, '')
                        );
                        setValue(
                          'phone',
                          e.target.value.replace(
                            /^(\d{2,3})(\d{3,4})(\d{4})$/,
                            `$1-$2-$3`
                          )
                        );
                      },
                    })}
                  />
                </div>
                <p className="input-tip">
                  비밀번호 복구 등에서 본인확인 용도로만 사용됩니다.
                </p>
              </article>
            </div>
          </div>
          <div className="button-wrap">
            <button className="btn-register">회원정보 수정</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditStudentPage;
