import 'views/mobile/main/style/m-main-taglist.sass';

import { getAllTag, getTag, putTag } from 'api/main/tag';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { mobileSelectStyles, selectStyles } from 'utils/select-option';

type TagOption = {
  value: string;
  label: string;
};

type Course = {
  id: number;
  title: string;
  username: string;
  createdAt: Array<number>;
};

type Tag = {
  tagName: string;
  courses: Array<Course>;
  seq: number;
};

const MMainTagList = () => {
  const [tagOptions, setTagOptions] = useState<Array<TagOption>>([]);
  const [tagList, setTagList] = useState<Array<Tag>>([]);
  const [newTag, setNewTag] = useState<string | undefined>('');
  const [detectChange, setDetectChange] = useState<boolean>(false);
  const [mode, setMode] = useState<{ id: number; mode: string }>({
    id: 0,
    mode: 'default',
  });

  const getList = () => {
    getTag()
      .then((res: any) => {
        const tmpArray: Array<Tag> = [
          { tagName: '', courses: [], seq: 1 },
          { tagName: '', courses: [], seq: 2 },
          { tagName: '', courses: [], seq: 3 },
          { tagName: '', courses: [], seq: 4 },
          { tagName: '', courses: [], seq: 5 },
        ];
        res.data.forEach((x: any) => {
          tmpArray.splice(x.seq - 1, 1, x);
        });
        setTagList(tmpArray);
      })
      .catch(() => {
        const tmpArray: Array<Tag> = [
          { tagName: '', courses: [], seq: 1 },
          { tagName: '', courses: [], seq: 2 },
          { tagName: '', courses: [], seq: 3 },
          { tagName: '', courses: [], seq: 4 },
          { tagName: '', courses: [], seq: 5 },
        ];
        setTagList(tmpArray);
      });
  };

  const getAllTagOptions = () => {
    getAllTag().then((res: any) => {
      const tOb: Array<TagOption> = [];

      res.data.forEach((x: any) => {
        tOb.push({
          value: x.name,
          label: x.name,
        });
      });
      setTagOptions(tOb);
    });
  };

  useEffect(() => {
    getList();
    getAllTagOptions();
  }, [detectChange]);

  const updateTag = (seq: number) => {
    const data = {
      name: newTag,
      seq: seq,
    };
    putTag(data).then((res: any) => {
      alert('적용되었습니다.');
      setMode({ id: 0, mode: 'default' });
      getList();
    });
  };

  return (
    <article className="main-tag-list">
      <div className="m-container">
        <div className="title-wrap">
          <h2>관심#태그 리스트</h2>
        </div>
        <div className="content-wrap">
          {tagList.length > 0 &&
            tagList.map((tag, index) =>
              tag.tagName === '' ? (
                <div className="tag-box-wrap" key={index}>
                  <div className="tag-title-wrap">
                    <div className="tag-title">
                      <span>#</span>
                      <CreatableSelect
                        styles={selectStyles}
                        options={tagOptions}
                        isClearable={true}
                        formatCreateLabel={(inputValue: string) => inputValue}
                        onChange={(choice) => setNewTag(choice?.value)}
                        className="input-tag-select"
                        classNamePrefix="input-select"
                        placeholder="관심 있는 태그를 입력해 주세요."
                      />
                      <button
                        type="button"
                        className="btn-add btn-apply"
                        onClick={() => updateTag(tag.seq)}
                      >
                        적용
                      </button>
                    </div>
                  </div>
                  <div className="tag-list-box lecture-none">
                    입력된 태그에 연관 된 강의가 제공됩니다.
                  </div>
                </div>
              ) : (
                <div className="tag-box-wrap" key={index}>
                  <div className="tag-title-wrap">
                    <div className="tag-title">
                      <span>#</span>
                      {mode.id === index && mode.mode === 'edit' ? (
                        <CreatableSelect
                          styles={mobileSelectStyles}
                          options={tagOptions}
                          isClearable={true}
                          formatCreateLabel={(inputValue: string) => inputValue}
                          onChange={(choice) => setNewTag(choice?.value)}
                          className="input-tag-select"
                          classNamePrefix="input-select"
                          placeholder="관심 있는 태그를 입력해 주세요."
                        />
                      ) : (
                        <span>{tag.tagName}</span>
                      )}
                      {mode.id === index && mode.mode === 'edit' ? (
                        <div className="btn-wrap">
                          <button
                            type="button"
                            className="btn-add"
                            onClick={() => updateTag(tag.seq)}
                          >
                            적용
                          </button>
                          <button
                            type="button"
                            className="btn-cancel"
                            onClick={() => setMode({ id: 0, mode: 'default' })}
                          >
                            취소
                          </button>
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn-edit"
                          onClick={() =>
                            setMode({
                              id: index,
                              mode: 'edit',
                            })
                          }
                        >
                          수정
                        </button>
                      )}
                    </div>
                    {tag.courses?.length >= 5 && mode.mode !== 'edit' && (
                      <Link
                        to={`/lecture/list?&keyword=${tag.tagName}&tagSearch=${true}`}
                        className="btn-more"
                      >
                        더보기 <b>+</b>
                      </Link>
                    )}
                  </div>
                  <div className="tag-list-box">
                    <ul>
                      {tag.courses?.length > 0 &&
                        tag.courses.map((course, index) => (
                          <li key={tag.tagName + index}>
                            <Link to={`/lecture/watch/${course.id}`}>
                              {index + 1}.{' '}
                              <span className="lecture-title">
                                &nbsp;{course.title}&nbsp;
                              </span>{' '}
                              / {course.username}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </article>
  );
};

export default MMainTagList;
