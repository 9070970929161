import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { noticeIsPublic, noticeList } from 'api/admin/notice';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type Notice = {
  id: number;
  title: string;
  public: boolean;
  createdAt: Array<number>;
};

const AdminDashboardNotice = () => {
  const [lists, setLists] = useState<Array<Notice>>();

  const getNoticeList = () => {
    const params = {
      keyword: '',
      page: 1,
      size: 5,
    };
    noticeList(params).then((res: any) => {
      const newItems = res.data.content;
      setLists(newItems);
    });
  };

  useEffect(() => {
    getNoticeList();
  }, []);

  const patchPublic = (id: number, isPublic: boolean) => {
    noticeIsPublic(id).then((res: any) => {
      if (isPublic) {
        alert('게시물이 비공개 되었습니다.');
      } else {
        alert('게시물이 공개 되었습니다.');
      }
      getNoticeList();
    });
  };

  return (
    <CCol lg="12">
      <CCard id="dashboard_notice">
        <CCardHeader className="d-flex justify-content-between">
          <h1 className="mb-0 pt-2 pb-2 fs-5">공지사항</h1>
          {lists && lists.length >= 5 && (
            <Link to="/admin/notice/list" className="btn">
              더보기
            </Link>
          )}
        </CCardHeader>

        <CCardBody>
          <CTable className="text-center" responsive="sm">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell style={{ width: '10%' }} scope="col">
                  번호
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '45%' }} scope="col">
                  제목
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '25%' }} scope="col">
                  작성일
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '20%' }} scope="col">
                  버튼
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {lists && lists.length ? (
                lists.map((item) => (
                  <CTableRow
                    key={item.id}
                    style={{ borderBottom: '1px solid #d8d8d8' }}
                  >
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{item.title}</CTableDataCell>
                    <CTableDataCell>
                      {item.createdAt[0]}.{item.createdAt[1]}.
                      {item.createdAt[2]}
                    </CTableDataCell>
                    <CTableDataCell>
                      <CButton
                        color={item.public ? 'success' : 'warning'}
                        className="text-white me-3"
                        onClick={() => patchPublic(item.id, item.public)}
                      >
                        {item.public ? '공개' : '비공개'}
                      </CButton>
                      <Link
                        to={`/admin/notice/${item.id}`}
                        className="btn btn-info text-white"
                      >
                        수정
                      </Link>
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <CTableDataCell colSpan={4}>
                    등록된 공지사항 목록이 없습니다.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default AdminDashboardNotice;
