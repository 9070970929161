// @ts-ignore
import Send from 'utils/send.ts';

export const findEmail = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/find/email',
    data: data,
  });
};

export const findPassword = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/find/password',
    data: data,
  });
};
