import 'views/desktop/common/playlist/playlist.scoped.sass';

import { delSubscribe, Subscribe } from 'api/playlist/playlist';
import {
  setPopupMode,
  showDetailPopupF,
} from 'module/lecture-add-detail-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { ChangeSub } from 'module/sub-playlist';
import { CommonPlaylistProps } from 'utils/type';

type PlaylistObjectProps = {
  data: CommonPlaylistProps;
};

const CommonPlaylistCard = ({ data }: PlaylistObjectProps) => {
  const dispatch = useAppDispatch();

  const subChange: boolean = useAppSelector(
    (state: RootState) => state.subPlaylist.subChange
  );

  const SubscribeF = (Id: any) => {
    if (window.confirm('해당 플레이리스트를 구독하시겠습니까?')) {
      Subscribe(Id)
        .then((res: any) => {
          dispatch(ChangeSub(!subChange));
          dispatch(
            showDetailPopupF({ showDetailPopup: false, playlistId: null })
          );
          dispatch(setPopupMode(''));
        })
        .catch((err: any) => {});
    }
  };

  const delSubscribeF = (Id: any) => {
    if (window.confirm('해당 플레이리스트를 구독 취소하시겠습니까?')) {
      delSubscribe(Id).then((res: any) => {
        dispatch(ChangeSub(!subChange));
        dispatch(
          showDetailPopupF({ showDetailPopup: false, playlistId: null })
        );
        dispatch(setPopupMode(''));
      });
    }
  };

  return (
    <div
      className="common-playlist-card"
      onClick={() =>
        dispatch(
          showDetailPopupF({ showDetailPopup: true, playlistId: data.id })
        )
      }
    >
      <div className="card-header">
        <h3 className="playlist-title">{data.title}</h3>
        <span className="playlist-lecture-count">
          총{' '}
          <b>
            {data.courses
              ? data.courses.length
              : data.courseCount
                ? data.courseCount
                : 0}
          </b>
          개 강의
        </span>
        <span className="playlist-writer">작성자 {data.name}</span>
        {data.mine === false &&
          (data.meSubscribed ? (
            <button className="cancel" onClick={() => delSubscribeF(data.id)}>
              구독취소
            </button>
          ) : (
            <button className="add" onClick={() => SubscribeF(data.id)}>
              구독하기
            </button>
          ))}
      </div>
      <div className="card-content">
        <p className="playlist-description">{data.description}</p>
        {data.userCount >= 1 && (
          <p className="playlist-share">
            공유된 수 : <b>{data.userCount}</b>명
          </p>
        )}
        {data.sharedCount >= 1 && (
          <p className="playlist-share">
            공유된 수 : <b>{data.sharedCount}</b>명
          </p>
        )}
      </div>
      {data.createdAt && (
        <span className="playlist-date">
          등록일자 {data.createdAt[0]}.
          {data.createdAt[1].toString().padStart(2, '0')}.
          {data.createdAt[2].toString().padStart(2, '0')}
        </span>
      )}
      {data.sharedAt && (
        <span className="playlist-date">
          등록일자 {data.sharedAt[0]}.
          {data.sharedAt[1].toString().padStart(2, '0')}.
          {data.sharedAt[2].toString().padStart(2, '0')}
        </span>
      )}
    </div>
  );
};
export default CommonPlaylistCard;
