// api
import {
  checkEmailVerification,
  emailVerification,
  professorRegister,
} from 'api/user/register';
// style
import Default_thumbnail from 'assets/images/global/profile_default.svg';
import { setTitle } from 'module/mobile/title';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
// component
import { showPopupF } from 'module/register-popup';
import { useEffect, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
// module
import {
  dayOptions,
  mobileSelectStyles,
  monthOptions,
  professorEmailOptions,
  yearOptions,
} from 'utils/select-option';
import MRegisterPopup from 'views/mobile/common/popup/MRegisterPopup';

interface Tags {
  name: string;
}

type FormData = {
  profileImage: Array<object>;
  name: string;
  emailId: string;
  emailAddress: string;
  email: string;
  verificationCode: number;
  checkVerification: string;
  password: string;
  passwordCheck: string;
  majorId: string;
  year: number;
  month: number;
  day: number;
  birthday: string;
  tags: Array<Tags>;
  tagCheck: string;
  subEmail: string;
  phone: number;
  agreeAll: boolean;
  agreePrivacy: boolean;
  agreeService: boolean;
};

type ClassCategory = {
  id: number;
  name: string;
  category: string;
};

const MRegisterProfessorPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.registerShowPopup.showPopup
  );
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [ref, setRef] = useState<any>();

  useEffect(() => {
    dispatch(setTitle('회원가입'));
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    setFocus,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      tags: [
        { name: '' },
        { name: '' },
        { name: '' },
        { name: '' },
        { name: '' },
      ],
      year: 2000,
    },
  });

  // 썸네일
  const [imgSrc, setImgSrc] = useState<string>(Default_thumbnail);

  const onChangeThumbnail = (evt: any) => {
    if (evt.target.files.length) {
      const imgTarget = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e: any) {
        setImgSrc(e.target.result);
      };
    } else {
      setImgSrc(Default_thumbnail);
    }
  };

  const deleteThumbnail = () => {
    setImgSrc(Default_thumbnail);
    setValue('profileImage', []);
  };

  // category
  const category: Array<ClassCategory> = useAppSelector(
    (state: RootState) => state.classCategory
  );

  const categoryObj = () => {
    const options: { value: number; label: string }[] = [];
    category.forEach((x) => {
      const newObj = {
        value: x.id,
        label: x.name,
      };
      options.push(newObj);
    });
    return options;
  };

  const categoryOptions = categoryObj();

  // 회원가입 post
  const postRegister: SubmitHandler<FormData> = () => {
    const tagsArray: Array<string> = [];
    watch().tags.forEach((tag) => {
      tagsArray.push(tag.name);
    });
    // @ts-ignore
    const formData = new FormData(
      document.getElementById('register_professor') as HTMLFormElement
    );
    formData.append('majorId', watch().majorId);
    for (const a of tagsArray) {
      formData.append('tags[]', a);
    }
    professorRegister(formData).then((res: any) => {
      alert(
        '가입 신청이 접수되었습니다.\n회원 확인이 완료되는대로 결과를 안내드리겠습니다.'
      );
      navigate('/');
    });
  };

  const EmailAddressChange = () => {
    setValue('email', `${watch().emailId}@${watch().emailAddress}`);
    setValue('checkVerification', 'N');
  };

  const postEmailVerification = async () => {
    const data = {
      name: watch().name,
      email: watch().email,
    };
    const result = await trigger(['emailId', 'emailAddress']);
    if (result) {
      emailVerification(data)
        .then((res: any) => {
          alert('인증번호를 발송했습니다.');
        })
        .catch((err: any) => {
          alert('이미 가입된 이메일입니다.');
        });
    }
  };

  const checkVerification = async () => {
    const params = {
      email: watch().email,
      code: watch().verificationCode,
    };
    const result = await trigger([
      'verificationCode',
      'emailId',
      'emailAddress',
    ]);
    if (result) {
      checkEmailVerification(params)
        .then((res: any) => {
          alert('인증번호를 확인했습니다.');
          setValue('checkVerification', 'Y');
          trigger('checkVerification');
        })
        .catch((err: any) => {
          setValue('checkVerification', 'N');
          trigger('checkVerification');
        });
    }
  };

  const { fields: tagField, append: tagAppend } = useFieldArray({
    control,
    name: 'tags',
  });

  const validateTags = () => {
    let flag: boolean = false;
    watch().tags.forEach((tag) => {
      if (tag.name !== '') flag = true;
    });

    if (flag) setValue('tagCheck', 'Y');
    else setValue('tagCheck', 'N');
    trigger('tagCheck');
  };

  const agreeAllEvent = (evt: any) => {
    if (evt.target.checked) {
      setValue('agreePrivacy', true);
      setValue('agreeService', true);
      trigger(['agreePrivacy', 'agreeService']);
    } else {
      setValue('agreePrivacy', false);
      setValue('agreeService', false);
      trigger(['agreePrivacy', 'agreeService']);
    }
  };

  const checkAgree = () => {
    if (getValues('agreePrivacy') && getValues('agreeService')) {
      setValue('agreeAll', true);
      trigger(['agreePrivacy', 'agreeService']);
    } else {
      setValue('agreeAll', false);
      trigger(['agreePrivacy', 'agreeService']);
    }
  };

  return (
    <div className="m-register-professor">
      <div className="m-container">
        <form id="register_professor" onSubmit={handleSubmit(postRegister)}>
          <div className="box-container">
            <div className="box-wrap">
              <div className="title-wrap">
                <h1>교수 회원가입</h1>
                <span>
                  <span className="require">*</span> 필수 입력 사항
                </span>
              </div>
              <div className="content-wrap">
                <article className="form-wrap">
                  <div className="input-wrap thumbnail-wrap">
                    <p className="label">
                      프로필 사진
                      <br />
                      size(80 x 80)
                    </p>
                    <div className="thumbnail-box">
                      <input
                        type="file"
                        accept=".jpg, .png"
                        id="profileImage"
                        {...register('profileImage', {
                          required: false,
                          onChange: onChangeThumbnail,
                        })}
                      />
                      <img
                        src={imgSrc}
                        className="thumbnail-image"
                        alt="프로필사진"
                      />
                    </div>
                    <div className="btn-wrap">
                      <label htmlFor="profileImage">사진 선택</label>
                      {watch().profileImage?.length > 0 && (
                        <button
                          type="button"
                          className="btn-delete-profile"
                          onClick={deleteThumbnail}
                        >
                          삭제하기
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="input-wrap name-wrap">
                    <label htmlFor="name">
                      <span className="require">*</span> 이름
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="input-name"
                      placeholder="이름"
                      aria-invalid={errors.name ? 'true' : 'false'}
                      {...register('name', {
                        required: '이름을 입력해 주세요.',
                        minLength: {
                          value: 2,
                          message: '2자 이상 입력해 주세요.',
                        },
                      })}
                    />
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.name && (
                        <p className="m-error-message" role="alert">
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap email-wrap">
                    <label htmlFor="email">
                      <span className="require">*</span> 이메일
                    </label>
                    <div className="email-box">
                      <input
                        type="text"
                        id="email"
                        className="input-email"
                        placeholder="이메일"
                        aria-invalid={errors.emailId ? 'true' : 'false'}
                        {...register('emailId', {
                          required: '이메일을 입력해주세요.',
                          onChange: (e) => {
                            setValue(
                              'email',
                              `${watch().emailId}@${watch().emailAddress}`
                            );
                            setValue(
                              'emailId',
                              e.target.value.replace(
                                /[^A-Za-z0-9`~!@#$%^&*()-_+=\[\]{}|;':"<>?,.\/]/gi,
                                ''
                              )
                            );
                          },
                        })}
                      />
                      <span className="email-connection">@</span>
                      <div className="address-box">
                        <input
                          type="text"
                          className="input-email-address"
                          placeholder="이메일 주소"
                          {...register('emailAddress', {
                            required: '이메일 주소를 선택해 주세요.',
                            onChange: (e) => {
                              e.target.value.replace(/[^A-Za-z.]/gi, '');
                              EmailAddressChange();
                            },
                          })}
                          onClick={() => setMenuIsOpen(!menuIsOpen)}
                          readOnly={readOnly}
                        />
                        <Select
                          options={professorEmailOptions}
                          styles={mobileSelectStyles}
                          placeholder="선택해 주세요."
                          inputId="emailAddress"
                          isClearable={true}
                          className="input-email-select"
                          classNamePrefix="input-select"
                          isSearchable={false}
                          onChange={(e: any) => {
                            setValue('emailAddress', e.value);
                            setMenuIsOpen(!menuIsOpen);
                            EmailAddressChange();
                            if (e.label === '직접입력') {
                              setReadOnly(false);
                            } else {
                              setReadOnly(true);
                            }
                            setFocus('emailAddress');
                          }}
                          menuIsOpen={menuIsOpen}
                        />
                      </div>
                      <input
                        type="hidden"
                        {...register('email', { required: true })}
                      />
                    </div>
                  </div>
                  <div className="button-email-wrap">
                    <button
                      type="button"
                      onClick={postEmailVerification}
                      disabled={watch().checkVerification === 'Y'}
                    >
                      인증번호 발송
                    </button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.emailId && (
                        <p className="m-error-message" role="alert">
                          {errors.emailId.message}
                        </p>
                      )}
                      {errors.emailAddress && (
                        <p className="m-error-message" role="alert">
                          {errors.emailAddress.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap email-check-wrap">
                    <label htmlFor="email_check">
                      <span className="require">*</span> 인증번호 확인
                    </label>
                    <div className="email-check-box">
                      <input
                        type="text"
                        id="email_check"
                        className="input-email-check"
                        placeholder="인증번호 확인"
                        aria-invalid={
                          errors.verificationCode ? 'true' : 'false'
                        }
                        {...register('verificationCode', {
                          required: '인증번호을 입력해 주세요.',
                        })}
                      />
                      <button
                        type="button"
                        onClick={checkVerification}
                        disabled={watch().checkVerification === 'Y'}
                      >
                        인증번호 확인
                      </button>
                      <input
                        type="hidden"
                        {...register('checkVerification', {
                          required: '인증번호를 확인해 주세요.',
                          pattern: {
                            value: /Y/,
                            message: '인증번호를 확인해 주세요.',
                          },
                        })}
                        defaultValue={'N'}
                      />
                    </div>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.verificationCode && (
                        <p className="m-error-message" role="alert">
                          {errors.verificationCode.message}
                        </p>
                      )}
                    </div>
                  )}
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.checkVerification && !errors.verificationCode && (
                        <p className="m-error-message" role="alert">
                          {errors.checkVerification.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap password-wrap">
                    <label htmlFor="password">
                      <span className="require">*</span> 비밀번호
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="input-password"
                      placeholder="비밀번호 (숫자, 소/대문자, 특수문자 조합 최소 8자)"
                      aria-invalid={errors.password ? 'true' : 'false'}
                      {...register('password', {
                        required: '비밀번호를 입력해 주세요.',
                        minLength: {
                          value: 8,
                          message: '8자 이상 입력해 주세요.',
                        },
                        maxLength: {
                          value: 20,
                          message: '20자 이하 입력해 주세요.',
                        },
                        pattern: {
                          value: /[A-Za-z`~!@#$%^&*()-_=+\\|{};:'",.<>?/]/,
                          message:
                            '영어 소문자/대문자, 특수문자 포함 8자 이상 입력해 주세요.',
                        },
                      })}
                    />
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.password && (
                        <p className="m-error-message" role="alert">
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap password-check-wrap">
                    <label htmlFor="password_check">
                      <span className="require">*</span> 비밀번호 확인
                    </label>
                    <input
                      type="password"
                      id="password_check"
                      className="input-password-check"
                      placeholder="비밀번호 다시입력"
                      aria-invalid={errors.passwordCheck ? 'true' : 'false'}
                      {...register('passwordCheck', {
                        required: '비밀번호를 한번 더 입력해 주세요.',
                        validate: (value) =>
                          value === getValues('password') ||
                          '일치하지 않습니다.',
                      })}
                    />
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.passwordCheck && (
                        <p className="m-error-message" role="alert">
                          {errors.passwordCheck.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="select-wrap major-wrap">
                    <label htmlFor="majorId">
                      <span className="require">*</span> 과/교실
                    </label>
                    <Controller
                      control={control}
                      name="majorId"
                      rules={{ required: '과/교실을 선택해 주세요.' }}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={categoryOptions}
                          styles={mobileSelectStyles}
                          aria-invalid={errors.majorId ? 'true' : 'false'}
                          placeholder="과/교실을 선택해 주세요."
                          inputId="majorId"
                          ref={ref}
                          className="input-major-select"
                          classNamePrefix="input-select"
                          onChange={(option: any) => {
                            onChange(option.value);
                          }}
                        />
                      )}
                    />
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.majorId && (
                        <p className="m-error-message" role="alert">
                          {errors.majorId.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap date-wrap">
                    <label htmlFor="birthday">
                      <span className="require">*</span> 생년월일
                    </label>
                    <div className="date-box">
                      <Controller
                        control={control}
                        name="year"
                        rules={{ required: true }}
                        render={({ field: { onChange, ref } }) => (
                          <Select
                            options={yearOptions}
                            styles={mobileSelectStyles}
                            placeholder="년도"
                            inputId="year"
                            aria-invalid={errors.year ? 'true' : 'false'}
                            defaultValue={yearOptions[50]}
                            ref={ref}
                            className="input-year-select"
                            classNamePrefix="input-select"
                            onChange={(option: any) => {
                              onChange(option.value);
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="month"
                        rules={{ required: true }}
                        render={({ field: { onChange, ref } }) => (
                          <Select
                            options={monthOptions}
                            styles={mobileSelectStyles}
                            aria-invalid={errors.month ? 'true' : 'false'}
                            placeholder="월"
                            inputId="month"
                            ref={ref}
                            className="input-month-select"
                            classNamePrefix="input-select"
                            onChange={(option: any) => {
                              onChange(option.value);
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="day"
                        rules={{ required: true }}
                        render={({ field: { onChange, ref } }) => (
                          <Select
                            options={dayOptions}
                            styles={mobileSelectStyles}
                            aria-invalid={errors.day ? 'true' : 'false'}
                            placeholder="일"
                            inputId="day"
                            ref={ref}
                            className="input-day-select"
                            classNamePrefix="input-select"
                            onChange={(option: any) => {
                              onChange(option.value);
                              setValue(
                                'birthday',
                                `${watch().year}-${watch().month}-${watch().day}`
                              );
                            }}
                          />
                        )}
                      />
                      <input
                        type="hidden"
                        {...register('birthday', {
                          required: true,
                        })}
                      />
                    </div>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {(errors.year || errors.month || errors.day) && (
                        <p className="m-error-message" role="alert">
                          생년월일을 입력해주세요.
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap keyword-wrap">
                    <label htmlFor="keyword">
                      <span className="require">* &nbsp;</span> 태그&nbsp;
                    </label>
                    <div className="keyword-box">
                      <p className="keyword-tip">
                        강의등록 시, 기본으로 사용될 태그를 미리 등록할 수
                        있습니다.
                        <br />
                        ex. 내과, 근골격 등
                      </p>
                      {tagField.map((tag, index) => (
                        <input
                          type="text"
                          id="input_tag"
                          onKeyDown={(e) =>
                            ((e.ctrlKey && e.keyCode === 86) ||
                              e.keyCode === 32) &&
                            e.preventDefault()
                          }
                          key={index}
                          className="input-keyword"
                          placeholder="태그 입력"
                          {...register(`tags.${index}.name`, {
                            onChange: (e) => {
                              const eng_check = /^[a-zA-z]+$/;
                              const kor_eng_check =
                                /^^(?=.*[A-Za-z])(?=.*[ㄱ-ㅎ|가-힣])[ㄱ-ㅎ|가-힣|a-z|A-Z|]*/;
                              if (eng_check.test(e.target.value)) {
                                if (e.target.value.length >= 40) {
                                  e.target.value = e.target.value.substr(0, 40);
                                }
                              } else if (kor_eng_check.test(e.target.value)) {
                                if (e.target.value.length >= 30) {
                                  e.target.value = e.target.value.substr(0, 30);
                                }
                              } else {
                                if (e.target.value.length >= 20) {
                                  e.target.value = e.target.value.substr(0, 20);
                                }
                              }
                              validateTags();
                            },
                          })}
                        />
                      ))}
                      {Object.keys(errors).length > 0 && (
                        <div className="keyword-error-wrap">
                          {errors.tagCheck && (
                            <p className="m-error-message" role="alert">
                              태그를 입력해 주세요.
                            </p>
                          )}
                        </div>
                      )}
                      {tagField.length <= 9 && (
                        <button
                          className="btn-add"
                          type="button"
                          onClick={() => tagAppend({ name: '' })}
                        >
                          추가
                        </button>
                      )}
                      <input
                        type="hidden"
                        {...register('tagCheck', {
                          required: '태그를 입력해 주세요.',
                          pattern: {
                            value: /Y/,
                            message: '태그를 입력해 주세요.',
                          },
                        })}
                      />
                    </div>
                  </div>
                  <div className="input-wrap sub-email-wrap">
                    <label htmlFor="subEmail">대체 이메일</label>
                    <input
                      type="text"
                      id="subEmail"
                      className="input-sub-email"
                      placeholder="복구용 이메일 입력"
                      {...register('subEmail', {
                        required: false,
                        pattern: {
                          value:
                            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                          message: '이메일을 바르게 입력해주세요.',
                        },
                      })}
                    />
                  </div>
                  <p className="input-tip">
                    비밀번호 복구 등에서 본인확인 용도로만 사용됩니다.
                  </p>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-wrap">
                      {errors.subEmail && (
                        <p className="m-error-message" role="alert">
                          {errors.subEmail.message}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="input-wrap phone-wrap">
                    <label htmlFor="phone">전화번호</label>
                    <input
                      type="text"
                      id="phone"
                      className="input-phone"
                      placeholder="숫자만 입력"
                      maxLength={13}
                      {...register('phone', {
                        required: false,
                        onChange: (e) => {
                          setValue(
                            'phone',
                            e.target.value.replace(/[^0-9.]/g, '')
                          );
                          setValue(
                            'phone',
                            e.target.value.replace(
                              /^(\d{2,3})(\d{3,4})(\d{4})$/,
                              `$1-$2-$3`
                            )
                          );
                        },
                      })}
                    />
                  </div>
                  <p className="input-tip">
                    비밀번호 복구 등에서 본인확인 용도로만 사용됩니다.
                  </p>
                </article>
                <article className="agree-wrap">
                  <p className="label">
                    약관동의
                    {(errors.agreePrivacy || errors.agreeService) && (
                      <span
                        className="m-error-message agree-error-message"
                        role="alert"
                      >
                        약관에 동의해 주세요.
                      </span>
                    )}
                  </p>
                  <div className="agree-box">
                    <div className="checkbox-container">
                      <div className="checkbox-wrap">
                        <input
                          type="checkbox"
                          id="agree_all"
                          className="input-agree-all"
                          {...register('agreeAll', {
                            onChange: (e) => agreeAllEvent(e),
                          })}
                        />
                        <label htmlFor="agree_all">약관 모두 동의하기</label>
                      </div>
                    </div>
                    <div className="checkbox-container">
                      <div className="checkbox-wrap">
                        <input
                          type="checkbox"
                          id="agree_service"
                          className="input-agree-service"
                          aria-invalid={errors.agreeService ? 'true' : 'false'}
                          {...register('agreeService', {
                            required: true,
                            onChange: () => checkAgree(),
                          })}
                        />
                        <label htmlFor="agree_service">
                          서비스 이용약관 동의(필수)
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn-more"
                        onClick={() =>
                          dispatch(
                            showPopupF({ showPopup: true, showType: 'service' })
                          )
                        }
                      >
                        자세히 보기
                      </button>
                    </div>
                    <div className="checkbox-container">
                      <div className="checkbox-wrap">
                        <input
                          type="checkbox"
                          id="agree_privacy"
                          className="input-agree-privacy"
                          aria-invalid={errors.agreePrivacy ? 'true' : 'false'}
                          {...register('agreePrivacy', {
                            required: true,
                            onChange: () => checkAgree(),
                          })}
                        />
                        <label htmlFor="agree_privacy">
                          개인정보 수집/이용 동의(필수)
                        </label>
                      </div>
                      <button
                        type="button"
                        className="btn-more"
                        onClick={() =>
                          dispatch(
                            showPopupF({ showPopup: true, showType: 'privacy' })
                          )
                        }
                      >
                        자세히 보기
                      </button>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="button-wrap">
              <button type="submit" className="btn-register">
                가입하기
              </button>
            </div>
          </div>
        </form>
      </div>
      {showPopup && <MRegisterPopup />}
    </div>
  );
};

export default MRegisterProfessorPage;
