// react
// style
import 'views/mobile/search/style/m-search.sass';

import { getLectureList } from 'api/lecture/lecture';
import { getData } from 'api/playlist/public-playlist';
import {
  setPopupMode,
  showDetailPopupF,
} from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';
import { getLecture, setLectureSearchKeyword } from 'module/lecturelist';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { getPublicPlaylist } from 'module/public-playlist';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CommonPlaylistProps } from 'utils/type';
// component
import MLectureList from 'views/mobile/common/lecture/MLectureList';
import MPlayListDetailPopup from 'views/mobile/common/popup/MPlayListDetailPopup';
import MPlayListPopup from 'views/mobile/common/popup/MPlayListPopup';

const MSearchPage = () => {
  const dispatch = useAppDispatch();

  const getParams = new URLSearchParams(window.location.search);
  const lectureChange: boolean = useAppSelector(
    (state: RootState) => state.getLectureList.lectureChange
  );
  const lectureLength: number = useAppSelector(
    (state: RootState) => state.getLectureList.lectureLength
  );
  const category: number = useAppSelector(
    (state: RootState) => state.setCategory.category
  );
  const publicPlaylists: Array<CommonPlaylistProps> = useAppSelector(
    (state: RootState) => state.publicPlaylist
  );
  const showDetailPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddDetailPopup.showDetailPopup
  );
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );

  const getLectureData = () => {
    const params = {
      categoryNameSearch: true,
      keyword: getParams.get('keyword'),
      tagSearch: false,
      page: 1,
      size: 5,
    };
    getLectureList(params).then((res) => {
      dispatch(getLecture(res.data.content));
    });
  };
  const getList = () => {
    const params = {
      keyword: getParams.get('keyword'),
      page: 1,
      size: 3,
    };
    getData(params).then((res: any) => {
      dispatch(getPublicPlaylist(res.data.content));
    });
  };

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(setLectureSearchKeyword(getParams.get('keyword')));
    getLectureData();
    getList();
  }, [lectureChange, category]);

  const highlightedText = (text: string, keyword: string | null) => {
    if (keyword != null) {
      if (keyword !== '' && text.includes(keyword)) {
        const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

        return (
          <>
            {parts.map((part, index) =>
              part.toLowerCase() === keyword.toLowerCase() ? (
                <span style={{ backgroundColor: '#fffeb3' }} key={index}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </>
        );
      }
    }

    return text;
  };

  return (
    <div className="m-search">
      <h1>
        <em>&apos;{getParams.get('keyword')}&apos;</em>에 대한 검색 결과입니다.
      </h1>
      <section className="search-lecture-wrap">
        <div
          className={`title-wrap ${lectureLength === 0 ? 'none-title' : ''}`}
        >
          <h2>강의</h2>
          {lectureLength >= 5 && (
            <Link
              to={`/lecture/list?keyword=${getParams.get('keyword')}`}
              className="btn-more"
            >
              더보기+
            </Link>
          )}
        </div>
        <div className="content-wrap">
          <MLectureList />
        </div>
      </section>
      <section className="search-playlist-wrap">
        <div className="title-wrap">
          <h2>공유 플레이리스트</h2>
          {publicPlaylists.length >= 5 && (
            <Link
              to={`/playlist/public?keyword=${getParams.get('keyword')}`}
              className="btn-more"
            >
              더보기
            </Link>
          )}
        </div>
        <div className="content-wrap">
          <ul className="playlist-list">
            {publicPlaylists.length > 0 &&
              publicPlaylists.map((x) => (
                <li
                  key={x.id}
                  className="playlist-card"
                  onClick={() => {
                    dispatch(
                      showDetailPopupF({
                        showDetailPopup: true,
                        playlistId: x.id,
                      })
                    );
                    dispatch(setPopupMode('public'));
                  }}
                >
                  <div className="playlist-text">
                    <p className="playlist-name">
                      <b>
                        {highlightedText(x.title, getParams.get('keyword'))}
                      </b>
                    </p>
                    <p className="playlist-status">
                      {highlightedText(x.name, getParams.get('keyword'))} /{' '}
                      {x.courseCount ? x.courseCount : 0}개{' '}
                      <span>(현재 공유된 수 : {x.sharedCount}명)</span>
                    </p>
                  </div>
                </li>
              ))}
            {publicPlaylists.length === 0 && (
              <li className="none-list">
                현재 공유되고 있는 플레이 리스트가 없습니다.
              </li>
            )}
          </ul>
        </div>
      </section>
      {showDetailPopup && <MPlayListDetailPopup />}
      {showPopup && <MPlayListPopup />}
    </div>
  );
};

export default MSearchPage;
