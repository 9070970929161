import 'views/desktop/main/style/main-watch-lecture.scoped.sass';

import { getWatchedList } from 'api/main/lecture';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type watchedLectureType = {
  id: number;
  major: string;
  name: string;
  title: string;
};

const MainWatchLecture = () => {
  const [lists, setLists] = useState<Array<watchedLectureType>>();

  const getList = () => {
    const params = {
      size: 15,
    };
    getWatchedList(params).then((res: any) => {
      setLists(res.data);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <article className="watch-lecture">
      <div className="title-wrap">
        <h2>최근 시청한 강의</h2>
      </div>
      <div className="content-wrap">
        {lists && lists.length > 0 && (
          <ul className="watch-lecture-card-list">
            {lists.map((list, index) => (
              <li key={list.id}>
                <Link to={`/lecture/watch/${list.id}`}>
                  <p className="lecture-text">
                    {index + 1}.
                    <span className="lecture-category">{list.major}</span>
                    &nbsp;/&nbsp;
                    <b className="lecture-name">{list.title}</b>&nbsp;/&nbsp;
                    <span className="lecture-writer">{list.name}</span>
                  </p>
                </Link>
              </li>
            ))}
          </ul>
        )}
        {lists?.length === 0 && (
          <p className="none-list">아직 시청한 강의가 없습니다.</p>
        )}
      </div>
    </article>
  );
};

export default MainWatchLecture;
