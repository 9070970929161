// style
import 'views/desktop/main/style/main.sass';

import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect } from 'react';
import PlayListDetailPopup from 'views/desktop/common/popup/PlayListDetailPopup';
import PlayListPopup from 'views/desktop/common/popup/PlayListPopup';
import MainEssentialLecture from 'views/desktop/main/components/MainEssentialLecture';
import MainLikeLecture from 'views/desktop/main/components/MainLikeLecture';
import MainMyList from 'views/desktop/main/components/MainMyList';
// component
import MainNewestLecture from 'views/desktop/main/components/MainNewestLecture';
import MainNotice from 'views/desktop/main/components/MainNotice';
import MainPublicPlaylist from 'views/desktop/main/components/MainPublicPlaylist';
import MainTagList from 'views/desktop/main/components/MainTagList';
import MainWatchLecture from 'views/desktop/main/components/MainWatchLecture';

const MainPage = () => {
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );
  const showDetailPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddDetailPopup.showDetailPopup
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(showDetailPopupF({ showDetailPopup: false, playlistId: null }));
  }, []);

  return (
    <div className="main">
      <div className="u-container">
        <section className="top-content-wrap">
          <MainEssentialLecture />
          <MainNewestLecture />
        </section>
        <section className="middle-content-wrap">
          <MainMyList />
          <MainTagList />
        </section>
        <section className="bottom-content-wrap">
          <MainLikeLecture />
          <MainWatchLecture />
        </section>
        <section className="sub-content-wrap">
          <MainPublicPlaylist />
        </section>
      </div>
      <article className="simple-notice">
        <MainNotice />
      </article>
      {showPopup && <PlayListPopup type={'add'} />}
      {showDetailPopup && <PlayListDetailPopup />}
    </div>
  );
};

export default MainPage;
