import 'views/desktop/user/style/edit.scoped.sass';

import { confirmUser } from 'api/user/edit';
import { setCheck } from 'module/auth';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { User } from 'utils/type';

import { removeCookie, setCookie } from '../../../../utils/cookie';

type FormData = {
  password: string;
};

const EditConfirmPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const navigate = useNavigate();
  const userData: User = useAppSelector(
    (state: RootState) => state.authentication.user
  );
  const dispatch = useAppDispatch();

  const [notMatch, setNotMatch] = useState<boolean>(false);

  const postConfirm = () => {
    const data = {
      password: watch().password,
    };
    confirmUser(data)
      .then((res: any) => {
        dispatch(setCheck(true));
        const date = new Date();
        date.setTime(date.getTime() + 10 * 60 * 1000);
        setCookie('login_check', 'Y', { path: '/', expires: date });
        switch (userData.role) {
          case 'ROLE_PROFESSOR':
            return navigate('/user/edit/professor');
          case 'ROLE_STUDENT':
            return navigate('/user/edit/student');
          case 'ROLE_INSIDER':
            return navigate('/user/edit/insider');
          default:
            return navigate('/user/edit/outsider');
        }
      })
      .catch((err: any) => {
        removeCookie('login_check');
        setNotMatch(true);
        dispatch(setCheck(false));
      });
  };

  return (
    <div className="edit-user">
      <section className="title-wrap">
        <h1>회원정보</h1>
      </section>
      <section className="content-wrap">
        <form onSubmit={handleSubmit(postConfirm)}>
          <h2>비밀번호 재입력</h2>
          <p>본인확인을 위해 로그인 비밀번호를 한 번 더 입력해 주세요.</p>
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요."
            aria-invalid={errors.password ? 'true' : 'false'}
            {...register('password', { required: '비밀번호를 입력해주세요.' })}
          />
          {(Object.keys(errors).length > 0 || notMatch) && (
            <section className="error-wrap">
              {errors.password && (
                <p className="error-message" role="alert">
                  {errors.password.message}
                </p>
              )}
              {!errors.password && notMatch && (
                <p className="error-message" role="alert">
                  비밀번호가 맞지 않습니다. 확인 후 입력해 주세요.
                </p>
              )}
            </section>
          )}
          <button type="submit" className="btn-confirm">
            확인
          </button>
        </form>
      </section>
    </div>
  );
};

export default EditConfirmPage;
