// @ts-ignore
import Send from 'utils/send.ts';

export const studentRegister = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/student',
    data: data,
  });
};

export const professorRegister = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/professor',
    data: data,
  });
};

export const insiderRegister = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/insider',
    data: data,
  });
};

export const dispatchRegister = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/dispatch-outsider',
    data: data,
  });
};

export const outsiderRegister = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/users/outsider',
    data: data,
  });
};

export const emailVerification = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/users/email-verification',
    data: data,
  });
};

export const checkEmailVerification = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/users/email-verification',
    params: params,
  });
};
