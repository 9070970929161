import { CContainer, CHeader, CHeaderToggler } from '@coreui/react';
import MenuIcon from 'assets/images/global/icon_menu.svg';
import { useState } from 'react';

const AdminHeader = ({ setShowParent }: any) => {
  const [show, setShow] = useState<boolean>(true);

  setShowParent(show);
  return (
    <CHeader className="mb-4">
      <CContainer fluid>
        <CHeaderToggler onClick={() => setShow(!show)}>
          <img src={MenuIcon} width="30" height="30" alt="메뉴" />
        </CHeaderToggler>
      </CContainer>
    </CHeader>
  );
};
export default AdminHeader;
