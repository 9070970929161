import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonPlaylistProps } from 'utils/type';

const initialState = {
  subChange: false,
  subPlaylist: [],
};

type subscribes = {
  subChange: boolean;
  subPlaylist: Array<CommonPlaylistProps>;
};

const subPlaylist = createSlice({
  name: 'subPlaylist',
  initialState: initialState,
  reducers: {
    getSubPlaylist(
      state: subscribes,
      action: PayloadAction<Array<CommonPlaylistProps>>
    ) {
      state.subPlaylist = action.payload;
    },
    ChangeSub(state: subscribes, action: PayloadAction<boolean>) {
      state.subChange = action.payload;
    },
  },
});

export const { getSubPlaylist, ChangeSub } = subPlaylist.actions;
export default subPlaylist.reducer;
