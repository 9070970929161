import 'views/desktop/user/style/register.sass';

import { Link } from 'react-router-dom';

const RegisterPage = () => {
  return (
    <div className="register">
      <div className="register-bg"></div>
      <section className="title-wrap">
        <h1>회원가입</h1>
      </section>
      <section className="content-wrap">
        <Link to="/register/professor" className="btn-professor">
          Professor 교수 가입
        </Link>
        <Link to="/register/student" className="btn-student">
          Student 학생 가입
        </Link>
        <Link to="/register/insider" className="btn-other">
          <span>Insider 내부인 가입</span>
          <p>
            강의를 듣는 연세대학교/연세의료원 내부인
            <br />
            (강의하지 않는 전문의/교수 포함)
          </p>
        </Link>
        <Link to="/register/dispatch" className="btn-other">
          <span>Dispatch Outsider 파견외부인 가입</span>
          <p>
            연세대학교/연세의료원으로 임시파견된 외부인
            <br />
            (파견 증명서 등으로 소속 증명)
          </p>
        </Link>
        <Link to="/register/outsider" className="btn-other">
          <span>Outsider 외부인 가입</span>
          <p>이외 해당하지 않는 외부인</p>
        </Link>
      </section>
    </div>
  );
};

export default RegisterPage;
