import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  change: false,
};

type lectureWatchType = {
  change: boolean;
};

const lectureWatch = createSlice({
  name: 'lectureWatch',
  initialState: initialState,
  reducers: {
    ChangeWatch(state: lectureWatchType, action: PayloadAction<boolean>) {
      state.change = action.payload;
    },
  },
});

export const { ChangeWatch } = lectureWatch.actions;
export default lectureWatch.reducer;
