// style
import 'views/desktop/lecture-management/style/lecture-create.sass';

// component
import CreateForm from 'views/desktop/lecture-management/components/CreateForm';

const LectureCreatePage = () => {
  return (
    <div className="lecture-create">
      <div className="title-wrap">
        <h1>강의 등록하기</h1>
        <p>
          <span className="require">*</span> 필수 입력 사항
        </p>
      </div>
      <div className="content-wrap">
        <CreateForm />
      </div>
    </div>
  );
};

export default LectureCreatePage;
