import 'views/mobile/main/style/m-main-public-playlist.scoped.sass';

import { getData } from 'api/playlist/public-playlist';
import {
  setPopupMode,
  showDetailPopupF,
} from 'module/lecture-add-detail-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { getPublicPlaylist } from 'module/public-playlist';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CommonPlaylistProps } from 'utils/type';

const MMainPublicPlaylist = () => {
  const dispatch = useAppDispatch();

  const myChange: boolean = useAppSelector(
    (state: RootState) => state.myPlaylist.myChange
  );
  const subChange: boolean = useAppSelector(
    (state: RootState) => state.subPlaylist.subChange
  );

  const getList = () => {
    const params = {
      page: 1,
      size: 5,
    };
    getData(params).then((res: any) => {
      dispatch(getPublicPlaylist(res.data.content));
    });
  };

  const publicPlaylists: Array<CommonPlaylistProps> = useAppSelector(
    (state: RootState) => state.publicPlaylist
  );

  useEffect(() => {
    getList();
  }, [subChange, myChange]);

  return (
    <article className="main-public-playlist">
      <div className="title-wrap">
        <div className="m-container">
          <h2>공유 플레이리스트</h2>
          {publicPlaylists.length >= 5 && (
            <Link to="/playlist/public" className="btn-more">
              더보기 <b>+</b>
            </Link>
          )}{' '}
        </div>
      </div>
      <div className="content-wrap">
        <div className="m-container">
          <ul className="playlist-list">
            {publicPlaylists.length > 0 &&
              publicPlaylists.map((x) => (
                <li
                  key={x.id}
                  onClick={() => {
                    dispatch(
                      showDetailPopupF({
                        showDetailPopup: true,
                        playlistId: x.id,
                      })
                    );
                    dispatch(setPopupMode('public'));
                  }}
                >
                  <h3>{x.title}</h3>
                  <p>
                    {x.name} / {x.courseCount ? x.courseCount : 0}개{' '}
                    <span>(현재 공유된 수 : {x.sharedCount}명)</span>
                  </p>
                </li>
              ))}
            {publicPlaylists.length === 0 && (
              <li className="none-list">
                현재 공유되고 있는 플레이 리스트가 없습니다.
              </li>
            )}
          </ul>
        </div>
      </div>
    </article>
  );
};

export default MMainPublicPlaylist;
