import 'views/mobile/main/style/m-main-my-playlist.scoped.sass';

import { getMyPlayListData } from 'api/playlist/playlist';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { getMyPlaylist } from 'module/my-playlist';
import { setWatchMode } from 'module/watch-mode';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlayList } from 'utils/type';

const MMainMyPlaylist = () => {
  const dispatch = useAppDispatch();

  const playlist: Array<PlayList> = useAppSelector(
    (state: RootState) => state.myPlaylist.myPlaylist
  );
  const myChange: boolean = useAppSelector(
    (state: RootState) => state.myPlaylist.myChange
  );

  useEffect(() => {
    const params = {
      keyword: null,
    };
    getMyPlayListData(params).then((res: any) => {
      dispatch(getMyPlaylist(res.data));
    });
  }, [myChange]);

  const noneComponent = () => {
    const playListNone = [];

    for (let i = 0; i < 5 - playlist.length; i++) {
      playListNone.push(
        <div className={`playlist-none`} key={`none_${i}`}>
          <button
            type="button"
            className="btn-add-playlist"
            onClick={() => dispatch(showPopupF(true))}
          >
            +
          </button>
          <p>
            자주 시청하는 강의를
            <br />
            플레이리스트로 만들어 보세요.
          </p>
        </div>
      );
    }

    return playListNone;
  };

  return (
    <article className="main-my-playlist">
      <div className="m-container">
        <div className="title-wrap">
          <h2>내 플레이리스트</h2>
          {playlist.length >= 5 && (
            <Link to="/playlist/my" className="btn-more">
              더보기 +
            </Link>
          )}
        </div>
        <div className="content-wrap">
          {playlist.map(
            (data, index) =>
              index < 5 && (
                <div className="playlist-box-wrap" key={index}>
                  <div className="playlist-title-wrap">
                    {data?.courses[0] ? (
                      <Link
                        className="playlist-title"
                        to={`/lecture/watch/${data?.courses[0].id}`}
                        onClick={() =>
                          dispatch(
                            setWatchMode({
                              playlistMode: true,
                              playlistId: data?.id,
                            })
                          )
                        }
                      >
                        {data.title}
                      </Link>
                    ) : (
                      <p className="playlist-title">{data.title}</p>
                    )}
                    <button
                      className="btn-edit"
                      onClick={() =>
                        dispatch(
                          showDetailPopupF({
                            showDetailPopup: true,
                            playlistId: data.id,
                          })
                        )
                      }
                    >
                      편집
                    </button>
                  </div>
                  <div className="playlist-box">
                    <ul>
                      {data.courses.length > 0 ? (
                        data.courses.map((element, index) => (
                          <li key={index}>
                            <Link to={`/lecture/watch/${element.id}`}>
                              {index + 1}.&nbsp;
                              <span className="lecture-title">
                                {element.title}
                              </span>{' '}
                              / {element.name}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>등록한 강의가 없습니다.</li>
                      )}
                    </ul>
                  </div>
                </div>
              )
          )}
          {noneComponent()}
        </div>
      </div>
    </article>
  );
};

export default MMainMyPlaylist;
