// @ts-ignore
import Send from 'utils/send.ts';

export const fileController = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/files',
    data: data,
    headers: {
      enctype: 'multipart/form-data',
      processData: false,
      'Content-Type': 'multipart/form-data',
    },
  });
};
