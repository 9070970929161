// @ts-ignore
import Send from 'utils/send.ts';

export const createLecture = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/courses',
    data: data,
  });
};

export const uploadVideo = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/courses/videos',
    data: data,
  });
};

export const getManagementList = () => {
  return Send({
    method: 'get',
    url: '/api/courses/manage',
  });
};

export const getStatusList = (id: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/courses/${id}/progress`,
    params: params,
  });
};

export const getEditData = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/courses/${id}/edit`,
  });
};

export const editLecture = (id: number, data: any) => {
  return Send({
    method: 'post',
    url: `/api/courses/${id}`,
    data: data,
  });
};

export const getCourseStatus = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/courses/${id}/permission`,
  });
};

export const postCourseStatus = (id: number) => {
  return Send({
    method: 'post',
    url: `/api/courses/${id}/permission`,
  });
};
