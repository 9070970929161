// @ts-ignore
import Send from 'utils/send.ts';

export const getUserData = () => {
  return Send({
    method: 'get',
    url: '/api/users/me',
  });
};

export const getUserTags = () => {
  return Send({
    method: 'get',
    url: '/api/tags/me',
  });
};

export const deleteUser = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/users/me',
    data: data,
  });
};
