// style
import 'views/mobile/lecture/style/m-lecture-watch.scoped.sass';

import { getLectureDetail } from 'api/lecture/lecture';
import { showPopupF } from 'module/lecture-add-popup';
import { setTitle } from 'module/mobile/title';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Tag } from 'utils/type';
import MPlayListPopup from 'views/mobile/common/popup/MPlayListPopup';
import MWatchComment from 'views/mobile/lecture/components/MWatchComment';
import MWatchInformation from 'views/mobile/lecture/components/MWatchInformation';
// component
import MWatchVideo from 'views/mobile/lecture/components/MWatchVideo';

type Video = {
  id: number;
  key: string;
  length: number;
  type: string;
  outlink: boolean;
  thumbnail: Thumbnail;
};

type files = {
  id: number;
  name: string;
  url: string;
};

type Thumbnail = {
  id: number;
  name: string;
  url: string;
};

type WatchVideoDetailType = {
  id: number;
  title: string;
  major: string;
  category: string;
  videoDto: Video;
  wishMe: boolean;
  wishCount: number;
  likeMe: boolean;
  likeCount: number;
  playlistMe: boolean;
  playlistCount: number;
  public: boolean;
  progressSecond: number;
  createdAt: Array<number>;
  courseTags: Array<Tag>;
  viewCount: number;
  user: string;
};

type WatchInformationType = {
  id: number;
  title: string;
  description: string;
  courseFiles: Array<files>;
};

const MLectureWatchPage = () => {
  const dispatch = useAppDispatch();
  const lectureId = Number(useParams().id);
  const lectureWatchChange: boolean = useAppSelector(
    (state: RootState) => state.lectureWatch.change
  );
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );
  const [videoDetail, setVideoDetail] = useState<WatchVideoDetailType | any>();
  const [informationDetail, setInformationDetail] =
    useState<WatchInformationType>();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(setTitle('강의 시청'));

    getLectureDetail(lectureId).then((res: any) => {
      setVideoDetail({
        id: res.data.id,
        title: res.data.title,
        major: res.data.major,
        category: res.data.category,
        videoDto: res.data.videoDto,
        wishMe: res.data.wishMe,
        wishCount: res.data.wishCount,
        likeMe: res.data.likeMe,
        likeCount: res.data.likeCount,
        playlistMe: res.data.playlistMe,
        playlistCount: res.data.playlistCount,
        public: res.data.public,
        progressSecond: res.data.progressSecond,
        createdAt: res.data.createdAt,
        courseTags: res.data.courseTags,
        viewCount: res.data.viewCount,
        user: res.data.user,
      });

      setInformationDetail({
        id: res.data.id,
        title: res.data.title,
        description: res.data.description,
        courseFiles: res.data.courseFiles,
      });
    });
  }, [lectureId, lectureWatchChange]);

  return (
    <div className="m-lecture-watch">
      <section className="watch-top">
        <MWatchVideo data={videoDetail} />
      </section>
      <section className="watch-middle">
        <MWatchInformation data={informationDetail} />
      </section>
      <section className="watch-bottom">
        <MWatchComment />
      </section>
      {showPopup && <MPlayListPopup />}
    </div>
  );
};

export default MLectureWatchPage;
