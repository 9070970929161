import Send from 'utils/send';

export const confirmUser = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/users/confirm',
    data: data,
  });
};

export const getUserData = () => {
  return Send({
    method: 'get',
    url: '/api/users/my-page',
  });
};

export const editUser = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/users/my-page',
    data: data,
  });
};
