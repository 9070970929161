import React from 'react';
import { Outlet } from 'react-router-dom';
import MFooter from 'views/mobile/common/layout/MFooter';
import MSimpleHeader from 'views/mobile/common/layout/MSimpleHeader';

const MHalfFreeLayout = () => (
  <>
    <MSimpleHeader />
    <main id="app_free_main">
      <Outlet />
    </main>
    <MFooter />
  </>
);

export default MHalfFreeLayout;
