import 'views/desktop/common/layout/header.scoped.sass';
import 'swiper/css';

import { deleteAlarm, deleteAllAlarm, getAlarm } from 'api/common/alarm';
import { logout } from 'api/user/login';
import logo from 'assets/images/global/logo_plus.png';
import { deleteAuth } from 'module/auth';
import { ChangeLecture, setLectureSearchKeyword } from 'module/lecturelist';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { isLogin } from 'utils/auth';
import { removeCookie } from 'utils/cookie';
import { User } from 'utils/type';

type AlarmType = {
  id: number;
  courseId: number;
  commentId: number;
  title: string;
  category: string;
  createdAt: Array<number>;
};

const Header = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userData: User = useAppSelector(
    (state: RootState) => state.authentication.user
  );
  const isLoginCheck: boolean = isLogin();
  const [keyword, setKeyword] = useState<string | null>('');
  const lectureChange: boolean = useAppSelector(
    (state: RootState) => state.getLectureList.lectureChange
  );

  const [showAlarm, setShowAlarm] = useState<boolean>(false);
  const [alarmList, setAlarmList] = useState<Array<AlarmType>>([]);

  const alarmRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  function handleClickOutside(e: MouseEvent): void {
    if (alarmRef.current && !alarmRef.current.contains(e.target as Node)) {
      setShowAlarm(false);
    }
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setShowDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onClickMenu = () => {
    setShowDropdown(false);
  };

  const clickLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      logout().then((res: any) => {
        alert('로그아웃 되었습니다.');
        dispatch(deleteAuth());
        removeCookie('login_token', { path: '/' });
        navigate('/');
      });
    }
  };

  const getUserRoleInKorea = () => {
    switch (userData.role) {
      case 'ROLE_ADMIN':
        return '관리자';
      case 'ROLE_PROFESSOR':
        return '교수';
      case 'ROLE_STUDENT':
        return '학생';
      case 'ROLE_INSIDER':
        return '내부인';
      case 'ROLE_DISPATCH_OUTSIDER':
        return '파견외부인';
      case 'ROLE_OUTSIDER':
        return '외부인';
    }
  };

  const clickSearch = () => {
    dispatch(setLectureSearchKeyword(keyword));
    dispatch(ChangeLecture(!lectureChange));
    navigate(`/search?keyword=${keyword}`);
  };
  const getParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setKeyword(getParams.get('keyword') ? '' : '');
    getAlarmList();
  }, []);

  /*header dropdown*/
  const dropdown = () => {
    return (
      <div className="dropdown-wrap" ref={dropdownRef}>
        <div className="btn-wrap">
          <button className="btn-close-dropdown ir_pm" onClick={onClickMenu}>
            닫기
          </button>
        </div>
        <div className="dropdown-item-wrap">
          <ul className="dropdown-item-box item-total">
            <li className="dropdown-item-content">
              <Link to="/lecture/list" onClick={onClickMenu}>
                <span>
                  <b>전체 강의</b>
                </span>
                <span>
                  <em>{userData.wholeCourseCount}</em>
                </span>
              </Link>
            </li>
            <li className="dropdown-item-content">
              <Link to="/playlist/public" onClick={onClickMenu}>
                <span>
                  <b>전체 공유 플레이리스트</b>
                </span>
                <span>
                  <em>{userData.wholePlaylistCount}</em>
                </span>
              </Link>
            </li>
          </ul>
          {userData.role === 'ROLE_PROFESSOR' && (
            <ul className="dropdown-item-box item-myLecture">
              <li className="dropdown-item-title">
                <b>내 강의관리</b>
              </li>
              <li className="dropdown-item-content">
                <Link to="/lecture/management/list" onClick={onClickMenu}>
                  <span>등록한 강의</span>
                  <span>{userData.myCourseCount}</span>
                </Link>
              </li>
            </ul>
          )}
          <ul className="dropdown-item-box item-playlist">
            <li className="dropdown-item-title">
              <b>내 플레이리스트</b>
            </li>
            <li className="dropdown-item-content">
              <Link to="/playlist/my" onClick={onClickMenu}>
                <span>내 플레이리스트</span>
                <span>{userData.myPlaylistCount}</span>
              </Link>
            </li>
            <li className="dropdown-item-content">
              <Link to="/playlist/subscribe" onClick={onClickMenu}>
                <span>구독 플레이리스트</span>
                <span>{userData.subPlaylistCount}</span>
              </Link>
            </li>
          </ul>
          <ul className="dropdown-item-box">
            <p className="dropdown-user">
              <b>
                {getUserRoleInKorea()} {userData.name}
              </b>{' '}
              님
            </p>
            <ul className="item-menu">
              <li>
                <Link to="/user/edit" onClick={onClickMenu}>
                  회원정보
                </Link>
              </li>
              <li>
                <Link to="/notice" onClick={onClickMenu}>
                  공지사항
                </Link>
              </li>
              <li>
                {isLoginCheck ? (
                  <button onClick={clickLogout}>로그아웃</button>
                ) : (
                  <Link to="/" onClick={onClickMenu}>
                    로그인
                  </Link>
                )}
              </li>
            </ul>
          </ul>
        </div>
      </div>
    );
  };

  const getAlarmList = () => {
    getAlarm().then((res: any) => {
      setAlarmList(res.data);
    });
  };

  const deleteAlarmItem = (id: number) => {
    deleteAlarm(id).then((res: any) => {
      getAlarmList();
    });
  };
  const deleteAllAlarmList = () => {
    deleteAllAlarm().then((res: any) => {
      getAlarmList();
    });
  };

  const AlarmModal = () => {
    return (
      <div className="alarm-box-wrap" ref={alarmRef}>
        <div className="alarm-box">
          <div className="alarm-header">
            {alarmList?.length > 0 && (
              <button
                type="button"
                className="alarm-read-all"
                onClick={deleteAllAlarmList}
              >
                모두읽음
              </button>
            )}
            <button
              type="button"
              className="btn-close-alarm ir_pm"
              onClick={() => setShowAlarm(false)}
            >
              닫기
            </button>
          </div>
          <div className="alarm-body">
            {alarmList && (
              <ul>
                {alarmList.map((list) => (
                  <li key={list.courseId}>
                    <Link
                      to={`/lecture/watch/${list.courseId}`}
                      target="_blank"
                      onClick={() => deleteAlarmItem(list.id)}
                    >
                      [{list.category}] {list.title} 강의에 댓글이
                      등록되었습니다.
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            {(!alarmList || alarmList.length === 0) && (
              <p className="alarm-none">새로운 알림이 없습니다.</p>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <header id="header">
      <div className="banner-container">
        <div className="u-container">
          <div className="logo-wrap">
            <Link to="/main">
              <img className="logo-univ" src={logo} alt="로고" />
            </Link>
          </div>
          <div className="handler-wrap">
            <div className="search-wrap">
              <input
                type="text"
                className="search"
                placeholder="검색어를 입력해 주세요."
                onChange={(e) => setKeyword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') clickSearch();
                }}
              />
              <button
                type="button"
                className="btn-search"
                onClick={clickSearch}
              ></button>
            </div>
            <div className="menu-wrap">
              <div className="user-wrap">
                {(userData.role === 'ROLE_PROFESSOR' ||
                  userData.role === 'ROLE_ADMIN') &&
                  alarmList?.length > 0 && (
                    <button
                      type="button"
                      className="btn-alarm"
                      onClick={() => setShowAlarm(!showAlarm)}
                    >
                      N
                    </button>
                  )}
                <p onClick={() => setShowDropdown(true)}>
                  <b>
                    {getUserRoleInKorea() === '관리자' ? (
                      <Link to="/admin/confirm">관리자</Link>
                    ) : (
                      getUserRoleInKorea()
                    )}{' '}
                    {userData.name}
                  </b>{' '}
                  님
                </p>
                {showAlarm && AlarmModal()}
              </div>
              <div className="btn-box">
                {(userData.role === 'ROLE_PROFESSOR' ||
                  userData.role === 'ROLE_ADMIN') && (
                  <div className="lecture-wrap">
                    <Link
                      to="/lecture/management/create"
                      className="btn-create"
                    >
                      강의 등록
                    </Link>
                    <Link to="/lecture/management/list" className="btn-lecture">
                      강의관리
                    </Link>
                  </div>
                )}
                <button
                  className="btn-dropdown"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  통합메뉴
                </button>
                {showDropdown && dropdown()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
