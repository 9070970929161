import 'views/desktop/lecture/style/watch-video.sass';

import {
  addLike,
  addWish,
  delLike,
  delWish,
  putLectureTime,
} from 'api/lecture/lecture';
import { getDetailData } from 'api/playlist/playlist';
import { setCourseId, showPopupF } from 'module/lecture-add-popup';
import { ChangeWatch } from 'module/lecture-watch';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { setWatchMode } from 'module/watch-mode';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Courses, Tag, Video } from 'utils/type';
import WatchList from 'views/desktop/lecture/components/WatchList';

type WatchVideoDetail = {
  id: number;
  title: string | undefined;
  videoDto: Video;
  major: string;
  category: string;
  wishMe: boolean;
  likeMe: boolean;
  playlistMe: boolean;
  playlistCount: number;
  wishCount: number;
  likeCount: number;
  mine: boolean;
  public: boolean;
  progressSecond: number | 0;
  createdAt: Array<number>;
  courseTags: Array<Tag>;
  viewCount: number;
  user: string;
};

type WatchListDetailType = {
  courses: Array<Courses>;
  title: string;
};

interface LectureProps {
  data: WatchVideoDetail;
}

const WatchVideo = ({ data }: LectureProps) => {
  const navigate = useNavigate();
  const isAdmin: boolean = useAppSelector(
    (state: RootState) => state.authentication.user.role === 'ROLE_ADMIN'
  );
  const dispatch = useAppDispatch();
  const lectureWatchChange: boolean = useAppSelector(
    (state: RootState) => state.lectureWatch.change
  );
  const param = useParams();
  const playlistMode: boolean = useAppSelector(
    (state: RootState) => state.watchMode.playlistMode
  );
  const playlistId: number = useAppSelector(
    (state: RootState) => state.watchMode.playlistId
  );
  const [listDetail, setListDetail] = useState<WatchListDetailType>();
  let checkTime = 0;

  // 찜
  const AddWish = (e: any, courseId: number | undefined) => {
    e.preventDefault();
    addWish(courseId).then((res: any) => {
      dispatch(ChangeWatch(!lectureWatchChange));
    });
  };

  const DelWish = (e: any, courseId: number) => {
    e.preventDefault();
    delWish(courseId).then((res: any) => {
      dispatch(ChangeWatch(!lectureWatchChange));
    });
  };

  // 좋아요
  const AddLike = (e: any, courseId: number | undefined) => {
    e.preventDefault();
    addLike(courseId).then((res: any) => {
      dispatch(ChangeWatch(!lectureWatchChange));
    });
  };

  const DelLike = (e: any, courseId: number) => {
    e.preventDefault();
    delLike(courseId).then((res: any) => {
      dispatch(ChangeWatch(!lectureWatchChange));
    });
  };

  const sendTime = (currentTime: number) => {
    if (currentTime === 0 || currentTime === null)
      currentTime = data?.progressSecond;
    if (currentTime != checkTime) {
      const data = {
        progress: currentTime,
      };
      putLectureTime(Number(param.id), data).then((res: any) => {});
      checkTime = currentTime;
    }
  };

  const nextCourse = (Id: number) => {
    listDetail?.courses.forEach((x, index) => {
      if (x.id === Id) {
        if (index + 1 >= listDetail?.courses.length) {
          alert('플레이리스트 시청이 끝났습니다.');
          if (window.confirm('한번 더 시청하시겠습니까?')) {
            if (!listDetail?.courses[0].isPublic) {
              alert(
                `${listDetail?.courses[0].title}은 비공개 된 강의입니다. 다음 강의를 재생합니다.`
              );
              return nextCourse(listDetail?.courses[0].id);
            } else {
              return navigate(`/lecture/watch/${listDetail?.courses[0].id}`);
            }
          } else {
            return navigate('/');
          }
        }

        if (listDetail?.courses[index + 1].isPublic) {
          navigate(`/lecture/watch/${listDetail?.courses[index + 1].id}`);
        } else {
          alert(
            `${listDetail?.courses[index + 1].title}은 비공개 된 강의입니다. 다음 강의를 재생합니다.`
          );
          if (index + 2 < listDetail?.courses.length) {
            navigate(`/lecture/watch/${listDetail?.courses[index + 2].id}`);
          } else {
            alert('플레이리스트 시청이 끝났습니다.');
            if (window.confirm('한번 더 시청하시겠습니까?')) {
              if (!listDetail?.courses[0].isPublic) {
                alert(
                  `${listDetail?.courses[0].title}은 비공개 된 강의입니다. 다음 강의를 재생합니다.`
                );
                return nextCourse(listDetail?.courses[0].id);
              } else {
                return navigate(`/lecture/watch/${listDetail?.courses[0].id}`);
              }
            } else {
              return navigate('/');
            }
          }
        }
      }
    });
  };

  useEffect(() => {
    if (playlistMode) {
      getDetailData(playlistId).then((res: any) => {
        setListDetail({
          courses: res.data.courses,
          title: res.data.title,
        });
      });
    }
    return () => {
      dispatch(setWatchMode({ playlistMode: false, playlistId: null }));
    };
  }, []);

  useEffect(() => {
    if (playlistMode) {
      if (data?.public === false) {
        alert(`${data?.title}은 비공개 된 강의입니다. 다음 강의를 재생합니다.`);
        return nextCourse(data?.id);
      }
      if (data?.videoDto.outlink === true) {
        window.open(
          `https://www.youtube.com/watch?v=${data?.videoDto.key}`,
          '_blank'
        );
        return alert(
          '외부에 있는 강의영상으로, 플레이리스트가 중지되었습니다.'
        );
      }
    } else {
      if (data?.public === false) {
        alert('비공개된 강의입니다.');
        return navigate(-1);
      }
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(
      () => sendTime(Number(ref.current?.getCurrentTime())),
      15000
    );
    return () => {
      clearInterval(interval);
    };
  });

  const ref = React.useRef<ReactPlayer>(null);

  return (
    <article className="video-wrap">
      <div className="video-title-wrap">
        <ul className="lecture-badge-wrap">
          <li>
            <span
              className="lecture-badge"
              style={{
                backgroundColor:
                  data?.category === '기초의학교실'
                    ? '#f8cbad'
                    : data?.category === '임상의학교실'
                      ? '#c6e0b4'
                      : data?.category === '인문사회의학교실'
                        ? '#d8c5ff'
                        : data?.category === '연세동곡의학교육원'
                          ? '#00B0F0'
                          : '',
              }}
            >
              {data?.major}
            </span>
          </li>
          <li>
            <span
              className="btn-add"
              onClick={(e) => [
                e.preventDefault(),
                dispatch(showPopupF(true)),
                dispatch(setCourseId(data?.id)),
              ]}
            >
              플레이리스트 추가 {data?.playlistCount}
            </span>
          </li>
          {(data?.mine || isAdmin) && (
            <li className="btn-edit">
              <Link to={`/lecture/management/edit/${data?.id}`}>수정</Link>
            </li>
          )}
        </ul>
        <div className="inline-wrap">
          <h1 className="lecture-title">{data?.title}</h1>
          <ul className="lecture-handler">
            <li>
              <button
                className={`btn-common btn-wish ir_pm${data?.wishMe ? ' active' : ''}`}
                onClick={(e) =>
                  data?.wishMe ? DelWish(e, data?.id) : AddWish(e, data?.id)
                }
              >
                찜
              </button>
              <span className="count">{data?.wishCount ?? 0}</span>
            </li>
            <li>
              <button
                className={`btn-common btn-like ir_pm${data?.likeMe ? ' active' : ''}`}
                onClick={(e) =>
                  data?.likeMe ? DelLike(e, data.id) : AddLike(e, data?.id)
                }
              >
                좋아요
              </button>
              <span className="count">{data?.likeCount}</span>
            </li>
            <li>
              <p className="lecture-date">
                <b>강의 등록일 </b>
                {data?.createdAt[0].toString()}.
                {data?.createdAt[1].toString().padStart(2, '0')}.
                {data?.createdAt[2].toString().padStart(2, '0')}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="content-wrap">
        {!data?.videoDto.outlink ? (
          <ReactPlayer
            url={
              data?.videoDto.type === 'VIMEO'
                ? `https://player.vimeo.com/video/${data?.videoDto.key}`
                : `https://www.youtube.com/watch?v=${data?.videoDto.key}`
            }
            muted={playlistMode ? true : false}
            playing={playlistMode ? true : false}
            ref={ref}
            key={data?.videoDto.key}
            width={playlistMode ? '640px' : '1024px'}
            height={playlistMode ? '360px' : '576px'}
            controls={true}
            className={'video'}
            onError={() => console.error()}
            onPlay={() => {
              sendTime(Number(ref.current?.getCurrentTime()));
            }}
            onReady={() => {
              data?.progressSecond && ref.current?.seekTo(data?.progressSecond);
              sendTime(Number(data?.progressSecond));
            }}
            onEnded={() => nextCourse(data?.id)}
          />
        ) : (
          <a
            href={`https://www.youtube.com/watch?v=${data?.videoDto.key}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              width={playlistMode ? '640px' : '1024px'}
              height={playlistMode ? '360px' : '576px'}
              src={
                data?.videoDto.thumbnail
                  ? data?.videoDto.thumbnail.url
                  : `https://img.youtube.com/vi/${data?.videoDto.key}/mqdefault.jpg`
              }
              alt="강의 썸네일"
            />
          </a>
        )}
        {playlistMode && <WatchList data={listDetail} />}
      </div>
      <div className="watch-information">
        <p>
          <b>교수</b>&ensp;{data?.user}&emsp;
          <Link
            to={`/lecture/list?keyword=${data?.user}&tagSearch=${false}`}
            className="btn-other"
          >
            다른 강의 보기
          </Link>
        </p>
        <ul className="count-wrap">
          <li>조회수 {data?.viewCount}</li>
        </ul>
        <ul className="tag-list">
          {data?.courseTags.map(
            (tag) =>
              tag.name !== '' && (
                <li className="tag" key={tag.id}>
                  <Link
                    to={`/lecture/list?keyword=${tag.name}&tagSearch=${true}`}
                  >
                    #{tag.name}
                  </Link>
                </li>
              )
          )}
        </ul>
      </div>
    </article>
  );
};

export default WatchVideo;
