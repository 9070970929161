import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import { getDashboardStatus } from 'api/admin/lecture';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type DashboardStatus = {
  id: number;
  category: string;
  title: string;
  email: string;
  name: string;
  appliedAt: Array<number>;
};

const AdminDashboardStatus = () => {
  const [lists, setLists] = useState<Array<DashboardStatus>>();

  const getList = () => {
    const params = {
      masking: true,
    };
    getDashboardStatus(params).then((res: any) => {
      console.log(res);
      setLists(res.data);
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <CCol lg="12 mb-5">
      <CCard id="dashboard_status">
        <CCardHeader className="d-flex justify-content-between">
          <h1 className="mb-0 pt-2 pb-2 fs-5">현황요청</h1>
          {lists && lists.length > 5 && (
            <Link to="/admin/lecture/list?status=REQUEST" className="btn">
              더보기
            </Link>
          )}
        </CCardHeader>

        <CCardBody>
          <CTable className="text-center" responsive="sm">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell style={{ width: '15%' }} scope="col">
                  과/교실
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '30%' }} scope="col">
                  강의명
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '15%' }} scope="col">
                  교수
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '20%' }} scope="col">
                  이메일
                </CTableHeaderCell>
                <CTableHeaderCell style={{ width: '20%' }} scope="col">
                  신청일
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {lists && lists.length ? (
                lists.map((item: any) => (
                  <CTableRow
                    key={item.id}
                    style={{ borderBottom: '1px solid #d8d8d8' }}
                  >
                    <CTableDataCell>{item.category}</CTableDataCell>
                    <CTableDataCell>
                      <Link
                        to={`/admin/lecture/status/${item.id}?title=${item.title}&category=${item.category}&permission=${item.status}`}
                      >
                        {item.title}
                      </Link>
                    </CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.email}</CTableDataCell>
                    <CTableDataCell>
                      {item.appliedAt[0]}.{item.appliedAt[1]}.
                      {item.appliedAt[2]} {item.appliedAt[3]}:
                      {item.appliedAt[4]}:{item.appliedAt[5]}
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <CTableDataCell colSpan={5}>
                    등록된 현황 요청 목록이 없습니다.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default AdminDashboardStatus;
