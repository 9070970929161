import 'views/mobile/common/layout/m-footer.scoped.sass';

import logoDg from 'assets/images/global/logo_dg.png';
import { Link } from 'react-router-dom';

const MFooter = () => {
  return (
    <footer id="m_footer">
      <div className="m-container">
        <div className="footer-wrap">
          <ul className="footer-menu">
            <li>
              <Link to="/terms/service">이용약관</Link>
            </li>
            <li>
              <Link to="/terms/privacy">
                <b>개인정보처리방침</b>
              </Link>
            </li>
            <li>
              <Link to="/terms/email">이메일무단수집거부</Link>
            </li>
          </ul>
          <p className="footer-address">
            03722 서울특별시 서대문구 연세로 50-1
          </p>
          <p className="footer-copyright">
            COPYRIGHT(C) YONSEI UNIVERSITY COLLEGE OF MEDICINE. ALL RIGHTS
            RESERVED.
          </p>
          <a
            className="logo-wrap"
            href="https://donggok.syn.co.kr/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="logo-dg" src={logoDg} alt="연세동국의학교육원" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default MFooter;
