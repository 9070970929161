import 'views/mobile/notice/style/m-notice-detail.sass';

import { noticeDetail } from 'api/notice/notice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { setTitle } from '../../../../module/mobile/title';
import { useAppDispatch } from '../../../../module/Module';

type fileData = {
  name: string;
  url: string;
};

type Data = {
  id: number;
  title: string;
  content: string;
  file: fileData | null;
  createdAt: Array<number>;
};

const MNoticeDetail = () => {
  const noticeId = Number(useParams().id);
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Data>({
    id: 0,
    title: '',
    content: '',
    file: null,
    createdAt: [],
  });

  useEffect(() => {
    dispatch(setTitle('공지사항 상세'));

    noticeDetail(noticeId).then((res: any) => {
      setData(res.data);
    });
  }, [noticeId]);

  return (
    <div className="m-notice-detail">
      <div className="content-wrap">
        <div className="content-title-wrap">
          <p className="notice-title">{data.title}</p>
          <p className="notice-date">
            {data.createdAt[0]}.{data.createdAt[1]?.toString().padStart(2, '0')}
            .{data.createdAt[2]?.toString().padStart(2, '0')}
          </p>
        </div>
        <div className="notice-text">
          <pre>{data.content}</pre>
          {data.file != null && (
            <a
              href={data.file.url}
              className="notice-file"
              download={`${data.file.name}`}
              target="_blank"
              rel="noreferrer"
            >
              {data.file.name}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default MNoticeDetail;
