import 'views/desktop/lecture/style/watch-comment.scoped.sass';

import {
  deleteComment,
  deleteLikeComment,
  getComment,
  postComment,
  postLikeComment,
  putComment,
} from 'api/lecture/lecture';
import icon_reComment from 'assets/images/global/icon_recomment.svg';
import profile_default from 'assets/images/global/profile_default.svg';
import { RootState, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

type subCommentType = {
  id: number;
  profileImage: string;
  userId: number;
  name: string;
  likeCount: number;
  likeMe: boolean;
  isProfessor: boolean;
  createdAt: Array<number>;
  content: string;
};

type CommentType = {
  id: number;
  profileImage: string;
  userId: number;
  name: string;
  likeCount: number;
  likeMe: boolean;
  isProfessor: boolean;
  createdAt: Array<number>;
  content: string;
  comments: Array<subCommentType>;
};

const WatchComment = () => {
  const lectureId = Number(useParams().id);
  const lectureWatchChange: boolean = useAppSelector(
    (state: RootState) => state.lectureWatch.change
  );
  const userId: number = useAppSelector(
    (state: RootState) => state.authentication.user.id
  );
  const [content, setContent] = useState<string>('');
  const [subContent, setSubContent] = useState<string>('');
  const [commentList, setCommentList] = useState<Array<CommentType>>([]);
  const [commentNum, setCommentNum] = useState<number>(0);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [addId, setAddId] = useState<number>(0);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [editContent, setEditContent] = useState<string>('');
  const [error, setError] = useState<{ id: number; check: boolean }>({
    id: 0,
    check: false,
  });
  const [editError, setEditError] = useState<{ id: number; check: boolean }>({
    id: 0,
    check: false,
  });
  const param = useParams();

  const getCommentList = () => {
    getComment(Number(param.id)).then((res: any) => {
      setCommentList(res.data);
      let count = 0;
      count = res.data.length;
      res.data.forEach((x: any) => {
        count += x.comments.length;
      });
      setCommentNum(count);
    });
  };

  useEffect(() => {
    getCommentList();
  }, [lectureId, lectureWatchChange]);

  const addComment = (parentId: number, content: string) => {
    if (content !== '') {
      const data = {
        parentId: parentId,
        content: content,
      };
      postComment(Number(param.id), data).then((res: any) => {
        alert('댓글이 작성되었습니다.');
        getCommentList();
        setContent('');
        setSubContent('');
        setError({ id: parentId, check: false });
      });
    } else {
      setError({ id: parentId, check: true });
    }
  };

  const addEvent = (id: number, mode: boolean) => {
    setAddId(id);
    setAddMode(mode);
  };

  const editEvent = (id: number, mode: boolean) => {
    setEditId(Number(id));
    setEditMode(mode);
  };

  const editComment = (commentId: number, content: string) => {
    if (content !== '') {
      const data = {
        content: content,
      };
      putComment(Number(param.id), commentId, data).then((res: any) => {
        alert('수정 되었습니다.');
        getCommentList();
        setEditContent('');
        setEditId(0);
        setEditMode(false);
        setEditError({ id: commentId, check: false });
      });
    } else {
      setEditError({ id: commentId, check: true });
    }
  };

  const deleteEvent = (commentId: number) => {
    if (window.confirm('댓글을 삭제하시겠습니까?')) {
      deleteComment(Number(param.id), commentId).then((res: any) => {
        alert('삭제 되었습니다.');
        getCommentList();
      });
    }
  };

  const likeCommentFunction = (commentId: number) => {
    postLikeComment(commentId).then((res: any) => {
      getCommentList();
    });
  };

  const deleteLikeCommentFunction = (commentId: number) => {
    deleteLikeComment(commentId).then((res: any) => {
      getCommentList();
    });
  };

  return (
    <article className="watch-comment-wrap">
      <div className="title-wrap">
        <h3>
          댓글 <span>({commentNum})</span>
        </h3>
      </div>
      <div className="content-wrap">
        <div className="commend-add">
          <div className="input-wrap">
            <textarea
              placeholder="댓글을 입력해주세요."
              maxLength={1000}
              onChange={(e) => {
                setContent(e.target.value);
              }}
              value={content}
            ></textarea>
            <button
              className="btn-add-comment"
              onClick={() => addComment(0, content)}
            >
              등록
            </button>
          </div>
          {error.id === 0 && error.check && (
            <p className="error">댓글을 입력해 주세요.</p>
          )}
        </div>
        {commentList && (
          <ul className="comment-list">
            {commentList.map((list) => (
              <li key={list.id}>
                <div className="comment-parent">
                  <div className="comment-box">
                    <div className="comment-profile">
                      <img
                        src={
                          list.profileImage
                            ? list.profileImage
                            : profile_default
                        }
                        alt="프로필 사진"
                      />
                    </div>
                    <div className="comment-text">
                      <div className="comment-information">
                        <p className="comment-writer">
                          {list.isProfessor ? '교수' : ''} {list.name}
                        </p>
                        <p className="comment-date">
                          {list.createdAt[0]}.
                          {list.createdAt[1]?.toString().padStart(2, '0')}.
                          {list.createdAt[2]?.toString().padStart(2, '0')}{' '}
                          {list.createdAt[3]?.toString().padStart(2, '0')}:
                          {list.createdAt[4]?.toString().padStart(2, '0')}:
                          {list.createdAt[5]?.toString().padStart(2, '0')}
                        </p>
                      </div>
                      {Number(list.id) === editId && editMode ? (
                        <div className=" edit-parent">
                          <div className="edit-input-wrap">
                            <textarea
                              className="comment-edit"
                              defaultValue={list.content}
                              maxLength={1000}
                              onChange={(e) => {
                                setEditContent(e.target.value);
                              }}
                            ></textarea>
                            <button
                              className="btn-cancel"
                              onClick={() => editEvent(0, false)}
                            >
                              취소
                            </button>
                            <button
                              className="btn-complete"
                              onClick={() => editComment(list.id, editContent)}
                            >
                              완료
                            </button>
                          </div>
                          {editError.id === list.id && editError.check && (
                            <p className="error">댓글을 입력해 주세요.</p>
                          )}
                        </div>
                      ) : (
                        <>
                          <pre className="comment-content">{list.content}</pre>
                          <div className="comment-helper">
                            <button
                              className={`btn-like-comment ir_pm ${list.likeMe ? 'active' : ''}`}
                              onClick={() => {
                                list.likeMe
                                  ? deleteLikeCommentFunction(list.id)
                                  : likeCommentFunction(list.id);
                              }}
                            >
                              댓글 좋아요
                            </button>
                            <span className="btn-like-count">
                              {list.likeCount}
                            </span>
                            <button
                              className="btn-recomment"
                              onClick={() => addEvent(list.id, !addMode)}
                            >
                              댓글쓰기
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {Number(list.id) !== editId &&
                    !editMode &&
                    list.userId === userId && (
                      <div className="comment-handler">
                        <button
                          className="btn-edit-comment"
                          onClick={() => {
                            editEvent(list.id, true);
                            setEditContent(list.content);
                          }}
                        >
                          수정하기
                        </button>
                        <button
                          className="btn-delete-comment"
                          onClick={() => deleteEvent(list.id)}
                        >
                          삭제하기
                        </button>
                      </div>
                    )}
                </div>
                <ul className="child-comment-list">
                  {list.comments &&
                    list.comments.map((childList) => (
                      <li key={childList.id}>
                        <div className="comment-child">
                          <div className="comment-box">
                            <img
                              src={icon_reComment}
                              className="icon-recomment"
                              alt="icon-sub-comment"
                            />
                            <div className="comment-profile">
                              <img
                                src={
                                  childList.profileImage
                                    ? childList.profileImage
                                    : profile_default
                                }
                                alt="프로필 사진"
                              />
                            </div>
                            <div className="comment-text">
                              <div className="comment-information">
                                <p className="comment-writer">
                                  {childList.isProfessor ? '교수' : ''}{' '}
                                  {childList.name}
                                </p>
                                <p className="comment-date">
                                  {childList.createdAt[0]}.
                                  {childList.createdAt[1]
                                    ?.toString()
                                    .padStart(2, '0')}
                                  .
                                  {childList.createdAt[2]
                                    ?.toString()
                                    .padStart(2, '0')}{' '}
                                  {childList.createdAt[3]
                                    ?.toString()
                                    .padStart(2, '0')}
                                  :
                                  {childList.createdAt[4]
                                    ?.toString()
                                    .padStart(2, '0')}
                                  :
                                  {childList.createdAt[5]
                                    ?.toString()
                                    .padStart(2, '0')}
                                </p>
                              </div>
                              {Number(childList.id) === editId && editMode ? (
                                <div className=" edit-child">
                                  <div className="edit-input-wrap">
                                    <textarea
                                      className="comment-edit"
                                      defaultValue={childList.content}
                                      maxLength={1000}
                                      onChange={(e) => {
                                        setEditContent(e.target.value);
                                      }}
                                    ></textarea>
                                    <button
                                      className="btn-cancel"
                                      onClick={() => editEvent(0, false)}
                                    >
                                      취소
                                    </button>
                                    <button
                                      className="btn-complete"
                                      onClick={() =>
                                        editComment(childList.id, editContent)
                                      }
                                    >
                                      완료
                                    </button>
                                  </div>
                                  {editError.id === childList.id &&
                                    editError.check && (
                                      <p className="error">
                                        댓글을 입력해 주세요.
                                      </p>
                                    )}
                                </div>
                              ) : (
                                <>
                                  <pre className="comment-content">
                                    {childList.content}
                                  </pre>
                                  <div className="comment-helper">
                                    <button
                                      className={`btn-like-comment ir_pm ${childList.likeMe ? 'active' : ''}`}
                                      onClick={() => {
                                        childList.likeMe
                                          ? deleteLikeCommentFunction(
                                              childList.id
                                            )
                                          : likeCommentFunction(childList.id);
                                      }}
                                    >
                                      댓글 좋아요
                                    </button>
                                    <span className="btn-like-count">
                                      {childList.likeCount}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          {Number(childList.id) !== editId &&
                            !editMode &&
                            childList.userId === userId && (
                              <div className="comment-handler">
                                <button
                                  className="btn-edit-comment"
                                  onClick={() => {
                                    editEvent(childList.id, true);
                                    setEditContent(childList.content);
                                  }}
                                >
                                  수정하기
                                </button>
                                <button
                                  className="btn-delete-comment"
                                  onClick={() => deleteEvent(childList.id)}
                                >
                                  삭제하기
                                </button>
                              </div>
                            )}
                        </div>
                      </li>
                    ))}
                  {addId === list.id && addMode && (
                    <li>
                      <div className="child-commend-add">
                        <div className="child-input-wrap">
                          <img
                            src={icon_reComment}
                            className="icon-recomment"
                            alt=""
                          />
                          <textarea
                            name=""
                            id=""
                            placeholder="댓글을 입력해주세요."
                            maxLength={1000}
                            onChange={(e) => {
                              setSubContent(e.target.value);
                            }}
                            value={subContent}
                          ></textarea>
                          <button
                            className="btn-add-comment"
                            onClick={() => addComment(list.id, subContent)}
                          >
                            등록
                          </button>
                        </div>
                        {error.id === list.id && error.check && (
                          <p className="child-error error">
                            댓글을 입력해 주세요.
                          </p>
                        )}
                      </div>
                    </li>
                  )}
                </ul>
              </li>
            ))}
          </ul>
        )}
        {commentList.length === 0 && (
          <p className="list-none">댓글이 없습니다.</p>
        )}
      </div>
    </article>
  );
};

export default WatchComment;
