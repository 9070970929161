import 'views/admin/admin.sass';

import { checkOtp, getOtp, otpStatus } from 'api/admin/otp';
import { setAdminCheck } from 'module/auth';
import { useAppDispatch } from 'module/Module';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminConfirmPage = () => {
  const [value, setValue] = useState<string>('');
  const [qr, setQr] = useState<string>('');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getQrCode = () => {
    getOtp()
      .then((res: any) => {
        setQr(res.data.url);
      })
      .catch(() => {
        setQr('');
        getOtpStatus();
      });
  };

  useEffect(() => {
    getQrCode();
  }, []);

  const getOtpStatus = () => {
    otpStatus()
      .then((res: any) => {
        if (res.data.success) {
          dispatch(setAdminCheck(true));
          navigate('/admin/dashboard');
        } else {
          dispatch(setAdminCheck(false));
        }
      })
      .catch(() => {
        dispatch(setAdminCheck(false));
      });
  };

  const confirm = () => {
    checkOtp({ code: value })
      .then((res: any) => {
        if (res.data.success) {
          alert('인증되었습니다.');
          dispatch(setAdminCheck(true));
          navigate('/admin/dashboard');
        } else {
          alert('인증에 실패하였습니다.');
          dispatch(setAdminCheck(false));
        }
      })
      .catch(() => {
        alert('인증에 실패하였습니다.');
        dispatch(setAdminCheck(false));
      });
  };

  return (
    <div className="admin-confirm">
      <div className={`confirm-container`}>
        <div className="confirm-box">
          <h1>관리자 OTP 인증</h1>
          {qr !== '' ? (
            <div className="qr-box">
              <p>1. 구글 OTP 앱을 설치하여 QR코드를 스캔해 주세요.</p>
              <QRCodeSVG value={qr} />
            </div>
          ) : null}
          <div className="code-box">
            <p>
              {qr !== '' ? '2.' : ''} 생성된 인증코드를 아래에 입력해 주세요.
            </p>
            <input
              type="text"
              onChange={(e) => setValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key == 'Enter') confirm();
              }}
              placeholder="인증코드를 입력해 주세요."
            />
          </div>
          <button type="button" onClick={() => confirm()}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminConfirmPage;
