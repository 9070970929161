// @ts-ignore
import Send from 'utils/send.ts';

export const checkOtp = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/validate',
    params: params,
  });
};

export const getOtp = () => {
  return Send({
    method: 'post',
    url: '/api/admin/validate',
  });
};

export const otpStatus = () => {
  return Send({
    method: 'get',
    url: '/api/admin/validate/status',
  });
};
