import React from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Routes } from 'react-router-dom';
import AdminRoute from 'routes/AdminRoute';
import CheckRoute from 'routes/CheckRoute';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import RoleRoute from 'routes/RoleRoute';
import AdminConfirmPage from 'views/admin/AdminConfirmPage';
/* Admin Page */
import AdminPage from 'views/admin/AdminPage';
import AdminDashboardPage from 'views/admin/dashboard/AdminDashboardPage';
import AdminInquiryDetailPage from 'views/admin/inquiry/AdminInquiryDetailPage';
import AdminInquiryListPage from 'views/admin/inquiry/AdminInquiryListPage';
import AdminLectureEssentialPage from 'views/admin/lecture/AdminLectureEssentialPage';
import AdminLectureListPage from 'views/admin/lecture/AdminLectureListPage';
import AdminLectureStatusPage from 'views/admin/lecture/AdminLectureStatusPage';
import AdminNoticeCreatePage from 'views/admin/notice/AdminNoticeCreatePage';
import AdminNoticeDetailPage from 'views/admin/notice/AdminNoticeDetailPage';
import AdminNoticeListPage from 'views/admin/notice/AdminNoticeListPage';
import AdminPlaylistPage from 'views/admin/playlist/AdminPlaylistPage';
import AdminPublicPlaylistPage from 'views/admin/playlist/AdminPublicPlaylistPage';
import AdminNewRegisterPage from 'views/admin/user/AdminNewRegisterPage';
import AdminDispatcherEditPage from 'views/admin/user/dispatcher/AdminDispatcherEditPage';
import AdminDispatcherPage from 'views/admin/user/dispatcher/AdminDispatcherPage';
import AdminDispatcherPlaylistPage from 'views/admin/user/dispatcher/AdminDispatcherPlaylistPage';
import AdminDispatcherSubscribePage from 'views/admin/user/dispatcher/AdminDispatcherSubscribePage';
import AdminInsiderEditPage from 'views/admin/user/insider/AdminInsiderEditPage';
import AdminInsiderPage from 'views/admin/user/insider/AdminInsiderPage';
import AdminInsiderPlaylistPage from 'views/admin/user/insider/AdminInsiderPlaylistPage';
import AdminInsiderSubscribePage from 'views/admin/user/insider/AdminInsiderSubscribePage';
import AdminOutsiderEditPage from 'views/admin/user/ousider/AdminOutsiderEditPage';
import AdminOutsiderPage from 'views/admin/user/ousider/AdminOutsiderPage';
import AdminOutsiderPlaylistPage from 'views/admin/user/ousider/AdminOutsiderPlaylistPage';
import AdminOutsiderSubscribePage from 'views/admin/user/ousider/AdminOutsiderSubscribePage';
import AdminProfessorEditPage from 'views/admin/user/professor/AdminProfessorEditPage';
import AdminProfessorLecturePage from 'views/admin/user/professor/AdminProfessorLecturePage';
import AdminProfessorPage from 'views/admin/user/professor/AdminProfessorPage';
import AdminProfessorPlaylistPage from 'views/admin/user/professor/AdminProfessorPlaylistPage';
import AdminStudentEditPage from 'views/admin/user/student/AdminStudentEditPage';
import AdminStudentPage from 'views/admin/user/student/AdminStudentPage';
import AdminStudentPlaylistPage from 'views/admin/user/student/AdminStudentPlaylistPage';
import AdminStudentSubscribePage from 'views/admin/user/student/AdminStudentSubscribePage';
/* Layout */
import AppFreeLayout from 'views/desktop/common/layout/FreeLayout';
import AppLayout from 'views/desktop/common/layout/Layout';
import LectureAllListPage from 'views/desktop/lecture/page/LectureAllListPage';
import LectureMyPlayListPage from 'views/desktop/lecture/page/LectureMyPlayListPage';
import LecturePublicPlayListPage from 'views/desktop/lecture/page/LecturePublicPlayListPage';
import LectureSubscribePlaylistPage from 'views/desktop/lecture/page/LectureSubscribePlaylistPage';
import LectureWatchPage from 'views/desktop/lecture/page/LectureWatchPage';
import LectureCreatePage from 'views/desktop/lecture-management/page/LectureCreatePage';
import LectureEditPage from 'views/desktop/lecture-management/page/LectureEditPage';
import LectureManagementPage from 'views/desktop/lecture-management/page/LectureManagementPage';
import LectureStatusPage from 'views/desktop/lecture-management/page/LectureStatusPage';
import MainPage from 'views/desktop/main/page/MainPage';
import InquiryPage from 'views/desktop/notice/page/InquiryPage';
import NoticeDetailPage from 'views/desktop/notice/page/NoticeDetailPage';
import NoticePage from 'views/desktop/notice/page/NoticePage';
import SearchPage from 'views/desktop/search/page/SearchPage';
import EmailTerms from 'views/desktop/terms/page/EmailTerms';
import PrivacyTerms from 'views/desktop/terms/page/PrivacyTerms';
import ServiceTerms from 'views/desktop/terms/page/ServiceTerms';
import EditConfirmPage from 'views/desktop/user/page/EditConfirmPage';
import EditInsiderPage from 'views/desktop/user/page/EditInsiderPage';
import EditOutsiderPage from 'views/desktop/user/page/EditOutsiderPage';
import EditProfessorPage from 'views/desktop/user/page/EditProfessorPage';
import EditStudentPage from 'views/desktop/user/page/EditStudentPage';
import FindPage from 'views/desktop/user/page/FindPage';
// desktop page
import LoginPage from 'views/desktop/user/page/LoginPage';
import RegisterDispatchPage from 'views/desktop/user/page/RegisterDispatchPage';
import RegisterInsiderPage from 'views/desktop/user/page/RegisterInsiderPage';
import RegisterOutsiderPage from 'views/desktop/user/page/RegisterOutsiderPage';
import RegisterPage from 'views/desktop/user/page/RegisterPage';
import RegisterProfessorPage from 'views/desktop/user/page/RegisterProfessorPage';
import RegisterStudentPage from 'views/desktop/user/page/RegisterStudentPage';
import SecessionPage from 'views/desktop/user/page/SecessionPage';
import MAlarm from 'views/mobile/common/layout/MAlarm';
import MAppFreeLayout from 'views/mobile/common/layout/MFreeLayout';
import MHalfFreeLayout from 'views/mobile/common/layout/MHalfFreeLayout';
import MAppLayout from 'views/mobile/common/layout/MLayout';
import MLectureAllListPage from 'views/mobile/lecture/page/MLectureAllListPage';
import MLectureMyPlayListPage from 'views/mobile/lecture/page/MLectureMyPlayListPage';
import MLecturePublicPlayListPage from 'views/mobile/lecture/page/MLecturePublicPlayListPage';
import MLectureSubscribePlayListPage from 'views/mobile/lecture/page/MLectureSubscribePlayListPage';
import MLectureWatchPage from 'views/mobile/lecture/page/MLectureWatchPage';
import MLectureManagementPage from 'views/mobile/lecture-management/page/MLectureManagementPage';
import MLectureStatusPage from 'views/mobile/lecture-management/page/MLectureStatusPage';
import MMainPage from 'views/mobile/main/page/MMainPage';
import MNoticeDetailPage from 'views/mobile/notice/page/MNoticeDetailPage';
import MNoticePage from 'views/mobile/notice/page/MNoticePage';
import MSearchPage from 'views/mobile/search/page/MSearchPage';
import MEmailTerms from 'views/mobile/terms/page/MEmailTerms';
import MPrivacyTerms from 'views/mobile/terms/page/MPrivacyTerms';
import MServiceTerms from 'views/mobile/terms/page/MServiceTerms';
import MEditConfirmPage from 'views/mobile/user/page/MEditConfirmPage';
import MEditInsiderPage from 'views/mobile/user/page/MEditInsiderPage';
import MEditOutsiderPage from 'views/mobile/user/page/MEditOutsiderPage';
import MEditProfessorPage from 'views/mobile/user/page/MEditProfessorPage';
import MEditStudentPage from 'views/mobile/user/page/MEditStudentPage';
import MFindPage from 'views/mobile/user/page/MFindPage';
/* Mobile page */
import MLoginPage from 'views/mobile/user/page/MLoginPage';
import MRegisterDispatchPage from 'views/mobile/user/page/MRegisterDispatchPage';
import MRegisterInsiderPage from 'views/mobile/user/page/MRegisterInsiderPage';
import MRegisterOutsiderPage from 'views/mobile/user/page/MRegisterOutsiderPage';
import MRegisterPage from 'views/mobile/user/page/MRegisterPage';
import MRegisterProfessorPage from 'views/mobile/user/page/MRegisterProfessorPage';
import MRegisterStudentPage from 'views/mobile/user/page/MRegisterStudentPage';
import MSecessionPage from 'views/mobile/user/page/MSecessionPage';

class AppRoutes extends React.Component {
  render() {
    return (
      <Routes>
        {isMobile ? (
          // mobile route
          <>
            <Route element={<PublicRoute />}>
              <Route element={<MHalfFreeLayout />}>
                <Route path="terms">
                  <Route path="service" element={<MServiceTerms />} />
                  <Route path="privacy" element={<MPrivacyTerms />} />
                  <Route path="email" element={<MEmailTerms />} />
                </Route>
              </Route>
            </Route>
            <Route element={<PublicRoute restrict />}>
              <Route index element={<MLoginPage />} />
              <Route path="/" element={<MAppFreeLayout />}>
                <Route path="register">
                  <Route index element={<MRegisterPage />} />
                  <Route
                    path="professor"
                    element={<MRegisterProfessorPage />}
                  />
                  <Route path="student" element={<MRegisterStudentPage />} />
                  <Route path="insider" element={<MRegisterInsiderPage />} />
                  <Route path="dispatch" element={<MRegisterDispatchPage />} />
                  <Route path="outsider" element={<MRegisterOutsiderPage />} />
                </Route>
                <Route path="find/:show" element={<MFindPage />} />
              </Route>
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<MAppLayout />}>
                <Route path="main" element={<MMainPage />} />
                <Route path="search" element={<MSearchPage />} />
                <Route path="playlist">
                  <Route
                    path="public"
                    element={<MLecturePublicPlayListPage />}
                  />
                  <Route path="my" element={<MLectureMyPlayListPage />} />
                  <Route
                    path="subscribe"
                    element={<MLectureSubscribePlayListPage />}
                  />
                </Route>
                <Route path="lecture">
                  <Route path="list" element={<MLectureAllListPage />} />
                  <Route path="management">
                    <Route
                      path="list"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <MLectureManagementPage />
                        </RoleRoute>
                      }
                    />
                    <Route
                      path="status/:id"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <MLectureStatusPage />
                        </RoleRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route element={<MHalfFreeLayout />}>
                <Route path="lecture">
                  <Route path="watch/:id" element={<MLectureWatchPage />} />
                </Route>
              </Route>
              <Route path="/" element={<MAppFreeLayout />}>
                <Route path="alarm" element={<MAlarm />} />
                <Route path="secession" element={<MSecessionPage />} />
                <Route path="user">
                  <Route path="edit">
                    <Route index element={<MEditConfirmPage />} />
                    <Route
                      path="professor"
                      element={
                        <CheckRoute check={true}>
                          <MEditProfessorPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="student"
                      element={
                        <CheckRoute check={true}>
                          <MEditStudentPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="insider"
                      element={
                        <CheckRoute check={true}>
                          <MEditInsiderPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="outsider"
                      element={
                        <CheckRoute check={true}>
                          <MEditOutsiderPage />
                        </CheckRoute>
                      }
                    />
                  </Route>
                </Route>
                <Route path="notice">
                  <Route index element={<MNoticePage />} />
                  <Route path=":id" element={<MNoticeDetailPage />} />
                </Route>
              </Route>
            </Route>
          </>
        ) : (
          // desktop route
          <>
            {/* free layout */}
            <Route path="/" element={<AppFreeLayout />}>
              {/* Public */}
              <Route element={<PublicRoute />}>
                <Route path="terms">
                  <Route path="service" element={<ServiceTerms />} />
                  <Route path="privacy" element={<PrivacyTerms />} />
                  <Route path="email" element={<EmailTerms />} />
                  <Route path="inquiry" element={<InquiryPage />} />
                </Route>
              </Route>
              <Route element={<PublicRoute restrict />}>
                <Route index element={<LoginPage />} />
                <Route path="register">
                  <Route index element={<RegisterPage />} />
                  <Route path="professor" element={<RegisterProfessorPage />} />
                  <Route path="student" element={<RegisterStudentPage />} />
                  <Route path="insider" element={<RegisterInsiderPage />} />
                  <Route path="dispatch" element={<RegisterDispatchPage />} />
                  <Route path="outsider" element={<RegisterOutsiderPage />} />
                </Route>
                <Route path="find/:show" element={<FindPage />} />
              </Route>
              {/* Private */}
              <Route element={<PrivateRoute />}>
                <Route path="user">
                  <Route path="edit">
                    <Route index element={<EditConfirmPage />} />
                    <Route
                      path="professor"
                      element={
                        <CheckRoute check={true}>
                          <EditProfessorPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="student"
                      element={
                        <CheckRoute check={true}>
                          <EditStudentPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="insider"
                      element={
                        <CheckRoute check={true}>
                          <EditInsiderPage />
                        </CheckRoute>
                      }
                    />
                    <Route
                      path="outsider"
                      element={
                        <CheckRoute check={true}>
                          <EditOutsiderPage />
                        </CheckRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            {/* basic layout */}
            <Route path="/" element={<AppLayout />}>
              {/* Public */}
              <Route element={<PublicRoute />}></Route>
              {/* Private */}
              <Route element={<PrivateRoute />}>
                <Route path="main" element={<MainPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="playlist">
                  <Route
                    path="public"
                    element={<LecturePublicPlayListPage />}
                  />
                  <Route path="my" element={<LectureMyPlayListPage />} />
                  <Route
                    path="subscribe"
                    element={<LectureSubscribePlaylistPage />}
                  />
                </Route>
                <Route path="notice">
                  <Route index element={<NoticePage />} />
                  <Route path=":id" element={<NoticeDetailPage />} />
                </Route>
                <Route path="secession" element={<SecessionPage />} />
                <Route path="lecture">
                  <Route path="watch/:id" element={<LectureWatchPage />} />
                  <Route path="list" element={<LectureAllListPage />} />
                  {/* Professor */}
                  <Route path="management">
                    <Route
                      path="list"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <LectureManagementPage />
                        </RoleRoute>
                      }
                    />
                    <Route
                      path="create"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <LectureCreatePage />
                        </RoleRoute>
                      }
                    />
                    <Route
                      path="status/:id"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <LectureStatusPage />
                        </RoleRoute>
                      }
                    />
                    <Route
                      path="edit/:id"
                      element={
                        <RoleRoute roles={['ROLE_ADMIN', 'ROLE_PROFESSOR']}>
                          <LectureEditPage />
                        </RoleRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </>
        )}
        {/* Admin */}
        <Route element={<AppFreeLayout />}>
          <Route
            path="admin/confirm"
            element={
              <RoleRoute roles={['ROLE_ADMIN']}>
                <AdminConfirmPage />
              </RoleRoute>
            }
          ></Route>
        </Route>
        <Route
          path="admin"
          element={
            <AdminRoute roles={['ROLE_ADMIN']} check={true}>
              <AdminPage />
            </AdminRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                <AdminDashboardPage />
              </AdminRoute>
            }
          />
          <Route path="lecture">
            <Route
              path="list"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminLectureListPage />
                </AdminRoute>
              }
            />
            <Route
              path="essential"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminLectureEssentialPage />
                </AdminRoute>
              }
            />
            <Route
              path="status/:id"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminLectureStatusPage />
                </AdminRoute>
              }
            />
          </Route>
          <Route path="playlist">
            <Route
              path="all"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminPlaylistPage />
                </AdminRoute>
              }
            />
            <Route
              path="public"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminPublicPlaylistPage />
                </AdminRoute>
              }
            />
          </Route>
          <Route path="user">
            <Route path="professor">
              <Route
                path="list"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminProfessorPage />
                  </AdminRoute>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminProfessorEditPage />
                  </AdminRoute>
                }
              />
              <Route
                path="lecture/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminProfessorLecturePage />
                  </AdminRoute>
                }
              />
              <Route
                path="playlist/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminProfessorPlaylistPage />
                  </AdminRoute>
                }
              />
            </Route>
            <Route path="student">
              <Route
                path="list"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminStudentPage />
                  </AdminRoute>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminStudentEditPage />
                  </AdminRoute>
                }
              />
              <Route
                path="playlist/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminStudentPlaylistPage />
                  </AdminRoute>
                }
              />
              <Route
                path="subscribe/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminStudentSubscribePage />
                  </AdminRoute>
                }
              />
            </Route>
            <Route path="insider">
              <Route
                path="list"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminInsiderPage />
                  </AdminRoute>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminInsiderEditPage />
                  </AdminRoute>
                }
              />
              <Route
                path="playlist/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminInsiderPlaylistPage />
                  </AdminRoute>
                }
              />
              <Route
                path="subscribe/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminInsiderSubscribePage />
                  </AdminRoute>
                }
              />
            </Route>
            <Route path="outsider">
              <Route
                path="list"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminOutsiderPage />
                  </AdminRoute>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminOutsiderEditPage />
                  </AdminRoute>
                }
              />
              <Route
                path="playlist/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminOutsiderPlaylistPage />
                  </AdminRoute>
                }
              />
              <Route
                path="subscribe/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminOutsiderSubscribePage />
                  </AdminRoute>
                }
              />
            </Route>
            <Route path="dispatcher">
              <Route
                path="list"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminDispatcherPage />
                  </AdminRoute>
                }
              />
              <Route
                path="edit/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminDispatcherEditPage />
                  </AdminRoute>
                }
              />
              <Route
                path="playlist/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminDispatcherPlaylistPage />
                  </AdminRoute>
                }
              />
              <Route
                path="subscribe/:id"
                element={
                  <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                    <AdminDispatcherSubscribePage />
                  </AdminRoute>
                }
              />
            </Route>
            <Route
              path="new"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminNewRegisterPage />
                </AdminRoute>
              }
            />
          </Route>
          <Route path="notice">
            <Route
              path="list"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminNoticeListPage />
                </AdminRoute>
              }
            />
            <Route
              path="create"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminNoticeCreatePage />
                </AdminRoute>
              }
            />
            <Route
              path=":id"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminNoticeDetailPage />
                </AdminRoute>
              }
            />
          </Route>
          <Route path="inquiry">
            <Route
              path="list"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminInquiryListPage />
                </AdminRoute>
              }
            />
            <Route
              path=":id"
              element={
                <AdminRoute roles={['ROLE_ADMIN']} check={true}>
                  <AdminInquiryDetailPage />
                </AdminRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    );
  }
}

export default AppRoutes;
