import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import {
  deleteRecommendCourse,
  getRecommendList,
  postRecommendCourse,
} from 'api/admin/lecture';
import { RootState, useAppSelector } from 'module/Module';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

type LectureEssential = {
  id: number;
  seq: number;
  category: string;
  title: string;
  name: string;
  createdAt: Array<number>;
};

type ClassCategory = {
  id: number;
  name: string;
  category: string;
};

const AdminLectureEssentialPage = () => {
  const [lists, setLists] = useState<Array<LectureEssential>>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [order, setOrder] = useState<number>(1);
  const [courseId, setCourseId] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [detectChange, setDetectChange] = useState<boolean>(true);

  const category: Array<ClassCategory> = useAppSelector(
    (state: RootState) => state.classCategory
  );

  // 강의 등록 MODAL 창
  const [visible, setVisible] = useState(false);
  const getList = (page: any) => {
    const params = {
      categoryId: categoryId,
      title: keyword,
      page: page.selected + 1,
      size: 20,
    };
    getRecommendList(0, params).then((res: any) => {
      setLists(res.data.content);
      setTotalPage(res.data.totalPages);
    });
  };

  useEffect(() => {
    getList({ selected: 0 });
  }, [detectChange, categoryId]);

  const setRecommend = () => {
    const data = {
      seq: order,
    };
    postRecommendCourse(courseId, data).then((res: any) => {
      alert('필독 강의 등록되었습니다.');
      setDetectChange(!detectChange);
      setVisible(false);
    });
  };

  const handleOnKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange);
    }
  };

  const updateRecommend = (id: number, seq: number) => {
    const data = {
      seq: seq,
    };
    postRecommendCourse(id, data).then((res: any) => {
      alert('필독 강의 업데이트 되었습니다.');
      setDetectChange(!detectChange);
      setVisible(false);
    });
  };

  const deleteRecommend = (courseId: number) => {
    const data = {
      seq: null,
    };
    deleteRecommendCourse(courseId, data).then(() => {
      alert('필독 강의를 삭제하였습니다.');
      setDetectChange(!detectChange);
    });
  };

  const createModal = () => {
    return (
      <CModal visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle className="fs-5">필독 강의 등록하기</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p className="text-center">강의번호를 입력하세요</p>
          <CInputGroup className="mt-3">
            <CInputGroupText id="basic-addon1">강의 번호</CInputGroupText>
            <CFormInput
              type="text"
              placeholder="강의 번호"
              onChange={(e) => setCourseId(Number(e.target.value))}
            />
          </CInputGroup>
          <CInputGroup className="mt-3 w-50">
            <CInputGroupText id="basic-addon1">순위</CInputGroupText>
            <CFormSelect
              placeholder="순위"
              onChange={(e) => setOrder(Number(e.target.value))}
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </CFormSelect>
          </CInputGroup>
        </CModalBody>
        <CModalFooter>
          <CButton
            type="button"
            color="secondary"
            onClick={() => setVisible(false)}
          >
            취소
          </CButton>
          <CButton type="button" color="primary" onClick={setRecommend}>
            확인
          </CButton>
        </CModalFooter>
      </CModal>
    );
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="lecture_essential">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">필독 강의 관리</h1>
            <CButton
              color="primary"
              className="text-white"
              onClick={() => setVisible(true)}
            >
              필독 강의 등록
            </CButton>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-end align-items-end">
                <CInputGroup className="w-25 me-3">
                  <CInputGroupText id="basic-addon1">과/교실</CInputGroupText>
                  <CFormSelect
                    placeholder="과/교실을 선택"
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">선택</option>
                    {category.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </CFormSelect>
                </CInputGroup>

                <CInputGroup className="w-25">
                  <CFormInput
                    type="text"
                    placeholder="제목 검색"
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={handleOnKeyPress}
                  />
                  <CButton
                    color="primary"
                    onClick={() => setDetectChange(!detectChange)}
                  >
                    검색
                  </CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{ width: '5%' }} scope="col">
                    번호
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '12%' }} scope="col">
                    순위
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '16%' }} scope="col">
                    과/교실
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '25%' }} scope="col">
                    강의제목
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '10%' }} scope="col">
                    교수
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '17%' }} scope="col">
                    강의 업로드 일자
                  </CTableHeaderCell>
                  <CTableHeaderCell style={{ width: '15%' }} scope="col">
                    관리
                  </CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? (
                  lists.map((item) => (
                    <CTableRow
                      key={item.id}
                      style={{ borderBottom: '1px solid #d8d8d8' }}
                    >
                      <CTableDataCell>{item.id}</CTableDataCell>
                      <CTableDataCell>
                        <CFormSelect
                          name="order"
                          id="order"
                          className="w-75 m-auto"
                          value={item.seq}
                          onChange={(e) =>
                            updateRecommend(item.id, Number(e.target.value))
                          }
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </CFormSelect>
                      </CTableDataCell>
                      <CTableDataCell>{item.category}</CTableDataCell>
                      <CTableDataCell>
                        <a
                          href={`/lecture/watch/${item.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      </CTableDataCell>
                      <CTableDataCell>{item.name}</CTableDataCell>
                      <CTableDataCell>
                        {item.createdAt[0]}.{item.createdAt[1]}.
                        {item.createdAt[2]}
                      </CTableDataCell>
                      <CTableDataCell>
                        <CButton
                          type="button"
                          color="danger"
                          className="text-white"
                          onClick={() => deleteRecommend(item.id)}
                        >
                          삭제
                        </CButton>
                      </CTableDataCell>
                    </CTableRow>
                  ))
                ) : (
                  <CTableRow style={{ borderBottom: '1px solid #d8d8d8' }}>
                    <CTableDataCell colSpan={7}>
                      필독 강의 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>

            <ReactPaginate
              breakLabel="..."
              containerClassName="user-paginate mt-4"
              nextLabel=">"
              onPageChange={getList}
              pageCount={totalPage}
              pageClassName="user-paginate-item"
              previousLabel="<"
            />
          </CCardBody>

          {createModal()}
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminLectureEssentialPage;
