import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  showDetailPopup: false,
  playlistId: null,
  mode: '',
};

type ShowPopup = {
  showDetailPopup: boolean;
  playlistId: number | null;
};

type popupMode = {
  mode: string;
};

const lectureAddDetailPopup = createSlice({
  name: 'lectureAddDetail',
  initialState,
  reducers: {
    showDetailPopupF(state: ShowPopup, action: PayloadAction<ShowPopup>) {
      state.showDetailPopup = action.payload.showDetailPopup;
      state.playlistId = action.payload.playlistId;
    },
    setPopupMode(state: popupMode, action: PayloadAction<string>) {
      state.mode = action.payload;
    },
  },
});

export const { showDetailPopupF, setPopupMode } = lectureAddDetailPopup.actions;
export default lectureAddDetailPopup.reducer;
