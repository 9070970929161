import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'views/desktop/common/layout/Footer';
import Header from 'views/desktop/common/layout/Header';

const AppLayout = () => (
  <>
    <Header />
    <main id="app_main">
      <Outlet />
    </main>
    <Footer />
  </>
);

export default AppLayout;
