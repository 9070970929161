import 'views/mobile/lecture/style/m-watch-list.scoped.sass';

import { Link } from 'react-router-dom';
import { Courses } from 'utils/type';

type WatchListDetail = {
  courses: Array<Courses>;
  title: string;
};

interface ListProps {
  data: WatchListDetail | undefined;
}

const MWatchList = ({ data }: ListProps) => {
  return (
    <article className="m-watch-list-wrap">
      <div className="watch-list-box">
        <h2>{data?.title}</h2>
        <ul className="watch-list">
          {data?.courses.map((course) => (
            <li key={course.video.key}>
              {course.video.type === 'VIMEO' ? (
                <Link to={`/lecture/watch/${course.id}`}>
                  <img src={course.video.thumbnail.url} alt="강의 썸네일" />
                  <div className="lecture-info">
                    <p className="title">{course?.title}</p>
                    <p className="user">교수 {course?.user}</p>
                  </div>
                </Link>
              ) : (
                <Link to={`/lecture/watch/${course.id}`}>
                  <img
                    src={
                      course.video.thumbnail
                        ? course.video.thumbnail.url
                        : `https://img.youtube.com/vi/${course.video.key}/mqdefault.jpg`
                    }
                    alt="강의 썸네일"
                  />
                  <div className="lecture-info">
                    <p className="title">{course?.title}</p>
                    <p className="user">교수 {course?.user}</p>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};

export default MWatchList;
