import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayList } from 'utils/type';

const initialState = {
  myChange: false,
  myPlaylist: [],
  delPlaylist: [],
  delList: [],
};

type my = {
  myChange: boolean;
  myPlaylist: Array<PlayList>;
  delPlaylist: Array<PlayList>;
  delList: Array<number>;
};

const myPlaylist = createSlice({
  name: 'myPlaylist',
  initialState: initialState,
  reducers: {
    getMyPlaylist(state: my, action: PayloadAction<Array<PlayList>>) {
      state.myPlaylist = action.payload;
    },
    getDelPlaylist(state: my, action: PayloadAction<PlayList>) {
      state.delPlaylist.push(action.payload);
    },
    getDelList(state: my, action: PayloadAction<number>) {
      state.delList.push(action.payload);
    },
    ChangePlaylist(state: my, action: PayloadAction<boolean>) {
      state.myChange = action.payload;
    },
    resetDelList(state: my) {
      state.delList = [];
      state.delPlaylist = [];
    },
  },
});

export const {
  getMyPlaylist,
  getDelPlaylist,
  getDelList,
  ChangePlaylist,
  resetDelList,
} = myPlaylist.actions;
export default myPlaylist.reducer;
