import 'views/mobile/user/style/m-register.sass';

import logo from 'assets/images/global/logo.svg';
import { setTitle } from 'module/mobile/title';
import { useAppDispatch } from 'module/Module';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MRegisterPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('회원가입'));
  });

  return (
    <div className="m-register">
      <div className="m-container">
        <section className="content-wrap">
          <div className="logo">
            <img src={logo} alt="연세대학교 의과대학" />
          </div>
          <Link to="/register/professor" className="btn-professor">
            Professor 교수 가입
          </Link>
          <Link to="/register/student" className="btn-student">
            Student 학생 가입
          </Link>
          <Link to="/register/insider" className="btn-other">
            Insider 내부인 가입
          </Link>
          <Link to="/register/dispatch" className="btn-other">
            Dispatch Outsider 파견외부인 가입
          </Link>
          <Link to="/register/outsider" className="btn-other">
            Outsider 외부인 가입
          </Link>
        </section>
      </div>
    </div>
  );
};

export default MRegisterPage;
