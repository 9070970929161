import 'views/desktop/user/style/secession.scoped.sass';

import { deleteUser } from 'api/user/user';
import { deleteAuth } from 'module/auth';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'utils/cookie';

type FormData = {
  content: string;
  reason: string;
  password: string;
  agree: boolean;
};

const SecessionPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userEmail = useAppSelector(
    (state: RootState) => state.authentication.user.email
  );

  const [notMatch, setNotMatch] = useState<boolean>(false);

  const {
    register,
    watch,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<FormData>();

  const secession: SubmitHandler<FormData> = () => {
    const data = {
      reason: watch().reason !== '기타' ? watch().reason : watch().content,
      password: watch().password,
    };

    deleteUser(data)
      .then((res: any) => {
        alert('회원탈퇴가 완료되었습니다.');
        dispatch(deleteAuth());
        removeCookie('login_token', { path: '/' });
        window.location.href = '/';
      })
      .catch((err: any) => {
        setNotMatch(true);
      });
    console.log(data);
  };

  return (
    <div className="secession">
      <h1>회원탈퇴</h1>
      <form id="secession" onSubmit={handleSubmit(secession)}>
        <div className="input-container reason-wrap">
          <p className="label">탈퇴사유</p>
          <div className="radio-container">
            <div className="radio-wrap">
              <input
                type="radio"
                id="secession_reason_01"
                {...register('reason', {
                  onChange: () => {
                    trigger('content');
                  },
                })}
                value={'이직, 사직, 졸업 등'}
                defaultChecked
              />
              <label htmlFor="secession_reason_01">이직, 사직, 졸업 등</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                id="secession_reason_02"
                {...register('reason')}
                value={'사용빈도가 낮고, 개인정보 유출이 우려되어서'}
              />
              <label htmlFor="secession_reason_02">
                사용빈도가 낮고, 개인정보 유출이 우려되어서
              </label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                id="secession_reason_03"
                {...register('reason')}
                value={'강좌에 대한 불만이 있어서'}
              />
              <label htmlFor="secession_reason_03">
                강좌에 대한 불만이 있어서
              </label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                id="secession_reason_04"
                {...register('reason')}
                value={'기타'}
              />
              <label htmlFor="secession_reason_04">기타</label>
            </div>
            <div className="input-wrap">
              <input
                type="text"
                id="secssion_resaon_content"
                className="input-secession-reason"
                placeholder="사유를 입력해 주세요."
                {...register('content', {
                  required: {
                    value: watch().reason === '기타',
                    message: '사유를 입력해 주세요.',
                  },
                })}
                disabled={watch().reason !== '기타'}
              />
            </div>
            {(Object.keys(errors).length > 0 || notMatch) && (
              <section className="error-wrap">
                {errors.content && (
                  <p className="error-message" role="alert">
                    {errors.content.message}
                  </p>
                )}
              </section>
            )}
          </div>
        </div>
        <div className="input-container email-wrap">
          <p className="label">탈퇴할 이메일 아이디</p>
          <p className="secession-id">{userEmail}</p>
        </div>
        <div className="input-container password-wrap">
          <label htmlFor="password" className="label">
            비밀번호
          </label>
          <input
            type="password"
            id="password"
            className="input-password"
            placeholder="비밀번호 (숫자, 소/대문자, 특수문자 조합 최소 8자)"
            {...register('password', { required: '비밀번호를 입력해주세요.' })}
          />
          {(Object.keys(errors).length > 0 || notMatch) && (
            <section className="error-wrap">
              {errors.password && (
                <p className="error-message" role="alert">
                  {errors.password.message}
                </p>
              )}
              {!errors.password && notMatch && (
                <p className="error-message" role="alert">
                  비밀번호가 맞지 않습니다. 확인 후 입력해 주세요.
                </p>
              )}
            </section>
          )}
        </div>
        <div className="secession-tip-wrap">
          <div className="secession-tip-box">
            <h2>회원탈퇴 전 아래 내용을 확인해 주세요.</h2>
            <p className="secession-tip">
              회원탈퇴 시 회원님의 아이디는 즉시 탈퇴처리 되며, 회원정보는 모두
              삭제되며 복구되지 않습니다. 재가입 시, 같은 이메일 사용할 수
              있습니다.
            </p>
          </div>
        </div>
        <div className="agree-wrap">
          <div className="checkbox-wrap">
            <input
              type="checkbox"
              id="agree_secession"
              {...register('agree', { required: true })}
            />
            <label htmlFor="agree_secession">
              위의 내용을 모두 확인하였습니다.
            </label>
          </div>
        </div>
        {errors.agree && (
          <span className="error-message agree-error-message" role="alert">
            약관에 동의해 주세요.
          </span>
        )}
        <div className="button-wrap">
          <button
            type="reset"
            className="btn-cancel"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
          <button className="btn-secession">회원탈퇴</button>
        </div>
      </form>
    </div>
  );
};

export default SecessionPage;
