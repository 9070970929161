import 'views/desktop/notice/style/inquiry.sass';

import { fileController } from 'api/common/file';
import { createInquiry } from 'api/notice/inquiry';
import { RootState, useAppSelector } from 'module/Module';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { User } from 'utils/type';

const NEXT_PUBLIC_RECAPTCHA_SITE_KEY =
  '6Lf7zcgiAAAAAFiL2eZ4EJS4hQfW8DKsudb4b8iv';

type FormData = {
  title: string | null;
  email: string | null;
  content: string | null;
  agreedAt: boolean | null;
  fileId: number | null;
  fileName: string | null;
};

const InquiryPage = () => {
  const userData: User = useAppSelector(
    (state: RootState) => state.authentication.user
  );
  const recaptchaRef: any = useRef<ReCAPTCHA | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    resetField,
    formState,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<FormData>({
    defaultValues: {
      title: null,
      email: userData.email ?? null,
      content: null,
      agreedAt: null,
      fileId: null,
      fileName: null,
    },
  });

  const postInquiry: SubmitHandler<FormData> = () => {
    if (
      (!userData.email && recaptchaRef.current.getValue()) ||
      userData.email
    ) {
      const data = {
        email: watch().email,
        title: watch().title,
        content: watch().content,
        agreedAt: watch().agreedAt,
        fileId: watch().fileId,
      };
      createInquiry(data).then((res: any) => {
        alert('문의 접수가 완료되었습니다.');
        setValue('email', userData.email);
        resetField('title');
        resetField('content');
        resetField('agreedAt');
        resetField('fileId');
        setFileCheck(false);
        navigate('/');
      });
    }
  };

  const [fileCheck, setFileCheck] = useState<boolean>(false);

  const fileUpload = (eventObject: any) => {
    const formData = new FormData();
    const file = eventObject.target.files[0];
    formData.append('file', file);

    fileController(formData)
      .then((res: any) => {
        setValue('fileId', res.data.id);
        setValue('fileName', res.data.name);
        setFileCheck(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const deleteFile = () => {
    setValue('fileId', null);
    setValue('fileName', null);
    setFileCheck(false);
  };

  return (
    <div className="inquiry">
      <h1>문의하기</h1>
      <form onSubmit={handleSubmit(postInquiry)}>
        <table>
          <tbody>
            <tr>
              <th>
                <span className="require">*</span> 제목
              </th>
              <td>
                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="제목 입력"
                    maxLength={60}
                    aria-invalid={errors.title ? 'true' : 'false'}
                    {...register('title', {
                      required: '제목을 입력해 주세요.',
                    })}
                  />
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.title && (
                      <p className="error-message" role="alert">
                        {errors.title.message}
                      </p>
                    )}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th>
                <span className="require">*</span> 이메일
              </th>
              <td>
                <div className="input-wrap">
                  {userData.email ? (
                    <p>{watch().email}</p>
                  ) : (
                    <input
                      type="text"
                      placeholder="이메일 입력"
                      maxLength={100}
                      aria-invalid={errors.email ? 'true' : 'false'}
                      {...register('email', {
                        required: '이메일을 입력해 주세요.',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: '이메일을 확인해 주세요.',
                        },
                      })}
                    />
                  )}
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.email && (
                      <p className="error-message" role="alert">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th className="textarea-th">
                <span className="require">*</span> 내용
              </th>
              <td>
                <div className="input-wrap">
                  <textarea
                    placeholder="내용 입력"
                    aria-invalid={errors.content ? 'true' : 'false'}
                    maxLength={1000}
                    {...register('content', {
                      required: '내용을 입력해 주세요.',
                      minLength: {
                        value: 10,
                        message: '10자 이상 입력해주세요',
                      },
                    })}
                  ></textarea>
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.content && (
                      <p className="error-message" role="alert">
                        {errors.content.message}
                      </p>
                    )}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th>파일첨부</th>
              <td>
                <div className="file-wrap">
                  <input
                    type="file"
                    id="file"
                    className="input-file"
                    accept=".jpg, .png, .pdf"
                    {...register('fileId', {
                      required: false,
                      onChange: fileUpload,
                    })}
                  />
                  <label htmlFor="file">첨부하기</label>
                  <p className="file-tip">
                    최대 2MB 이하, JPG(JPEG), PNG, PDF 형식지원
                  </p>
                </div>
                {fileCheck && (
                  <div className="selected-file-wrap">
                    <p className="file-name">{watch().fileName}</p>
                    <button
                      className="btn-delete-file"
                      onClick={deleteFile}
                    ></button>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="agree-wrap">
          <div className="checkbox-wrap">
            <input
              type="checkbox"
              id="agree_email"
              aria-invalid={errors.agreedAt ? 'true' : 'false'}
              {...register('agreedAt', {
                required: '이메일 정보 제공에 동의해주세요.',
              })}
            />
            <label htmlFor="agree_email">이메일 정보 제공 동의</label>
          </div>
          <p className="tip">
            보내주신 질문에 답변드리기 위해 이메일 정보 제공에 동의해 주시기
            바랍니다.
          </p>
          {Object.keys(errors).length > 0 && (
            <div className="error-wrap">
              {errors.agreedAt && (
                <p className="error-message" role="alert">
                  {errors.agreedAt.message}
                </p>
              )}
            </div>
          )}
        </div>
        {/* {!userData.email && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            className="recaptcha"
          />
        )} */}
        <div className="button-wrap">
          <button className="btn-inquiry">문의하기</button>
        </div>
      </form>
    </div>
  );
};

export default InquiryPage;
