import { getUserData } from 'api/user/user';
import { RootState, useAppSelector } from 'module/Module';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

const RoleRoute = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: Array<string>;
}) => {
  const role: string = useAppSelector(
    (state: RootState) => state.authentication.user.role
  );
  const loading: boolean = useAppSelector(
    (state: RootState) => state.authentication.loading
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  getUserData()
    .then(() => {})
    .catch(() => {
      navigate('/');
    });

  if (loading) {
    return <p>Checking authentication..</p>;
  }

  const userHasRequiredRole = !!(role && roles.includes(role));

  if (!userHasRequiredRole) {
    alert('접근 권한이 없습니다.');
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

export default RoleRoute;
