import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import { noticeDelete, noticeDetail, noticeEdit } from 'api/admin/notice';
import DeleteIcon from 'assets/images/global/icon_delete.svg';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

type FormData = {
  title: string;
  content: string;
  file: any | null;
  fileId: number | null;
};

type FileInformation = {
  name: string;
  url: string;
};

const AdminNoticeCreatePage = () => {
  const navigate = useNavigate();
  const noticeId = Number(useParams().id);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const [fileInformation, setFileInformation] =
    useState<FileInformation | null>(null);

  const onChangeFile = (evt: any) => {
    if (evt.target.files.length) {
      const fileTarget = evt.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(fileTarget);

      const fileName = fileTarget.name;
      fileReader.onload = function (e: any) {
        setFileInformation({
          name: fileName,
          url: e.target.result,
        });
      };
    }
  };

  const deleteFile = () => {
    setFileInformation(null);
    setValue('file', null);
    setValue('fileId', null);
  };

  useEffect(() => {
    noticeDetail(noticeId).then((res: any) => {
      setValue('title', res.data.title);
      setValue('content', res.data.content);
      setValue('file', res.data.file);
      setFileInformation(res.data.file);
    });
  }, []);

  const updateNotice = () => {
    // @ts-ignore
    const formData = new FormData(
      document.getElementById('notice_create_form') as HTMLFormElement
    );
    noticeEdit(noticeId, formData).then((res: any) => {
      alert('공지사항이 수정되었습니다.');
      navigate(-1);
    });
  };

  const deleteNotice = () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      noticeDelete(noticeId)
        .then((res: any) => {
          alert('삭제 되었습니다.');
          navigate(-1);
        })
        .catch(() => {
          alert('오류가 발생하였습니다.');
        });
    }
  };

  const exitNotice = () => {
    if (window.confirm('해당 페이지에서 나가시겠습니까??')) {
      navigate(-1);
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="notice_detail">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">공지사항 수정</h1>
          </CCardHeader>
          <form id="notice_create_form" onSubmit={handleSubmit(updateNotice)}>
            <CCardBody className="card-body">
              <div className="mb-3">
                <CFormLabel htmlFor="title">제목</CFormLabel>
                <CInputGroup className="mb-3">
                  <CInputGroupText id="basic-addon1">제목</CInputGroupText>
                  <CFormInput
                    type="text"
                    id="title"
                    placeholder="제목 입력"
                    {...register('title', {
                      required: '제목을 입력해 주세요.',
                      minLength: {
                        value: 2,
                        message: '2자 이상 입력해 주세요.',
                      },
                      maxLength: {
                        value: 60,
                        message: '60자까지 입력해 주세요.',
                      },
                    })}
                  />
                </CInputGroup>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.title && (
                      <p className="error-message" role="alert">
                        {errors.title.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <CFormLabel htmlFor="content">내용입력</CFormLabel>
                <CInputGroup className="mb-3" size="lg">
                  <CFormTextarea
                    id="content"
                    rows={11}
                    style={{ resize: 'none' }}
                    {...register('content', {
                      required: '내용을 입력해 주세요.',
                      minLength: {
                        value: 2,
                        message: '2자 이상 입력해 주세요.',
                      },
                    })}
                  ></CFormTextarea>
                </CInputGroup>
                {Object.keys(errors).length > 0 && (
                  <div className="error-wrap">
                    {errors.content && (
                      <p className="error-message" role="alert">
                        {errors.content.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <p className="form-label">파일 선택</p>
                <CFormLabel htmlFor="file" className="btn btn-secondary">
                  파일선택
                </CFormLabel>
                <input
                  type="file"
                  id="file"
                  {...register('file', {
                    onChange: onChangeFile,
                  })}
                  className="d-none"
                />
                {fileInformation != null && (
                  <>
                    <a
                      href={fileInformation.url}
                      target="_blank"
                      className="ms-3"
                      rel="noreferrer"
                    >
                      {fileInformation.name}
                    </a>
                    <button
                      className="btn ms-2"
                      style={{
                        background: `url(${DeleteIcon}) no-repeat center center`,
                        width: 20,
                        height: 20,
                      }}
                      type="button"
                      onClick={deleteFile}
                    ></button>
                  </>
                )}
              </div>
              <CFormInput
                type="hidden"
                name="public"
                value={'true'}
              ></CFormInput>
            </CCardBody>

            <CCardFooter className="d-flex justify-content-between">
              <div>
                <CButton
                  type="button"
                  color="danger text-white"
                  onClick={() => deleteNotice()}
                >
                  삭제
                </CButton>
              </div>
              <div>
                <CButton type="submit" color="primary" className="me-3">
                  등록
                </CButton>
                <CButton
                  type="button"
                  color="dark"
                  variant="outline"
                  onClick={() => exitNotice()}
                >
                  목록
                </CButton>
              </div>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default AdminNoticeCreatePage;
